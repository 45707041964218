module.exports = [
  {
    type: "string",
    attribute: "Name",
    label: "Namn",
  },
  {
    type: "informationClassificationStatus",
    attribute: "Status",
    label: "Klassning",
  },
  {
    type: "list",
    attribute: "Categories",
    label: "Kategorier",
  },
  {
    type: "string",
    attribute: "ItunesId",
    label: "ItunesId",
  },
  {
    type: "string",
    attribute: "Platform",
    label: "Plattform",
  },
  {
    type: "html",
    attribute: "ExternalNote",
    label: "Extern kommentar",
  },
  {
    type: "string",
    attribute: "Updated",
    label: "Ändrad",
  },
  {
    type: "string",
    attribute: "Created",
    label: "Skapad",
  },
];

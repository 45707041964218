export default [
  {
    name: "Auth",
    path: "/auth",
  },
  {
    name: "Auth_return",
    path: "/auth_return",
  },
  {
    name: "Logout",
    path: "/logout",
  },
];

<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Klassningar" :isSubMenu="true" @linkEvent="linkEvent" />
    <div class="content content-sub-sidebar-present">
      <div class="col-12">
        <table-paginated
          v-if="mode == 'showInformationClassification'"
          tableId="informationClassificationsTableV2"
          backendPath="/api/ustart/informationclassifications"
          :columnConfig="informationClassificationsColumnConfig"
          @event="readTableEvent"
        />

        <div v-if="mode == 'upsertInformationClassification'" class="col-12">
          <div class="mb-5">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href v-on:click="mode = 'showInformationClassification'">Klassningar</a>
                </li>
                <li class="breadcrumb-item active">{{ upsertInformationClassificationMode == "insert" ? "Skapa klassning" : "Ändra klassning" }}</li>
              </ol>
            </nav>
          </div>
          <upsert-information-classification
            :mode="upsertInformationClassificationMode"
            :updateObject="updateInformationClassificationModel"
            :insertObject="insertInformationClassificationModel"
            @informationClassificationDeletedEvent="informationClassificationDeletedEvent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import Loading from "../../components/loading/Loading.vue";
import Notification from "../../components/notification/Notification.vue";
import Modal from "../../components/modal/Modal.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";
import UpsertInformationClassification from "./components/UpsertInformationClassification.vue";

const subMenuEvents = {
  ADD_INFORMATION_CLASSIFICATION: "ADD_INFORMATION_CLASSIFICATION",
};

export default {
  name: "InformationClassifications",
  components: { TablePaginated, Notification, Loading, Modal, Sidemenu, UpsertInformationClassification },
  data() {
    return {
      mode: "showInformationClassification", //showInformationClassification or upsertInformationClassification
      upsertInformationClassificationMode: "insert", //insert or update
      insertInformationClassificationModel: null, //used when navigating to this view from another view with pre-populated data
      updateInformationClassificationModel: null,
      sideMenuItems: [
        { type: "header", label: "Klassningar" },
        { type: "link", iconClass: "fas fa-info-circle", label: "Skapa klassning", event: subMenuEvents.ADD_INFORMATION_CLASSIFICATION, badge: null },
      ],
    };
  },
  methods: {
    linkEvent(event) {
      if (event == subMenuEvents.ADD_INFORMATION_CLASSIFICATION) {
        this.mode = "upsertInformationClassification";
        this.upsertInformationClassificationMode = "insert";
        this.insertInformationClassificationModel = null;
      }
    },
    readTableEvent(event) {
      if (event.eventName == "updateInformationClassification") {
        this.mode = "upsertInformationClassification";
        this.upsertInformationClassificationMode = "update";
        this.updateInformationClassificationModel = event.row;
      }
    },
    informationClassificationDeletedEvent(event) {
      this.mode = "showInformationClassification";
    },
  },
  computed: {
    informationClassificationsColumnConfig() {
      return columnConfigHandler.getConfig("informationClassifications", []);
    },
  },
  mounted() {
    if (this.$route.query.name && this.$route.query.itunesId) {
      this.mode = "upsertInformationClassification";
      this.upsertInformationClassificationMode = "insert";
      this.insertInformationClassificationModel = { name: this.$route.query.name, itunesId: this.$route.query.itunesId };
    }
  },
};
</script>

<template>
  <div class="content">
    <div class="col-12">
      <table-paginated
        tableId="publicInformationClassificationsTableV3"
        backendPath="/api/ustart/informationclassifications/public"
        :columnConfig="publicInformationClassificationsConfig"
      />
    </div>
  </div>
</template>

<script>
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";

export default {
  name: "InformationClassificationsPublic",
  components: { TablePaginated },
  data() {
    return {};
  },
  methods: {},
  computed: {
    publicInformationClassificationsConfig() {
      return columnConfigHandler.getConfig("publicInformationClassifications", []);
    },
  },
  mounted() {},
};
</script>

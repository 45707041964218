<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Appdetaljer" :isSubMenu="true" />
    <div class="content content-sub-sidebar-present">
      <loading v-if="loadingDeleteApp" />
      <div v-show="!loadingDeleteApp">
        <div class="mb-5">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href v-on:click="goToApplications()">Appar</a>
              </li>
              <li class="breadcrumb-item active">Appdetaljer</li>
            </ol>
          </nav>
        </div>
        <div class="row">
          <div class="col-md-7 col-sm-12 col-xs-12">
            <tab-bar :items="tabItems" v-model="activeTabeItem" />
            <br />
            <table-key-value v-show="activeTabeItem == 'Detaljer'" :backendPath="`/api/ustart/application/${this.$route.query.param}/details/keyValue`" />
            <div v-show="activeTabeItem == 'Tilldelade enheter'">
              <div class="alert alert-warning" role="alert"><strong>OBS!</strong> Nedan visas endast manuellt tilldelade enheter.</div>
              <table-paginated
                tableId="assignedDevicesV6"
                :backendPath="`/api/ustart/devices/assignedToApplication/${this.$route.query.param}`"
                :columnConfig="devicesColumnConfig"
              />
            </div>
          </div>
          <div class="col-md-1 col-sm-12 col-xs-12" />
          <div class="col-md-4 col-sm-12 col-xs-12">
            <div class="card">
              <div class="card-header">
                Tilldela appen automatiskt till följande enhetsgrupper
              </div>

              <ul class="list-group list-group-flush">
                <template v-if="!loadingAutomaticAssignments">
                  <template v-for="(automaticAssignment, index) in automaticAssignments">
                    <li class="list-group-item" v-bind:key="'automaticAssignment' + index">
                      <input class="form-check-input me-1" type="checkbox" v-model="automaticAssignment.assignAutomatically" /> {{ automaticAssignment.name }}
                    </li>
                  </template>
                  <li class="list-group-item">
                    <a class="btn btn-primary" v-on:click="updateAutomaticAssignments()">Spara</a>
                  </li>
                </template>
                <template v-else>
                  <li class="list-group-item">
                    <loading v-if="loadingAutomaticAssignments" />
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <modal modalId="deleteAppModal" header="Ta bort app">
          <div class="alert alert-warning" role="alert">
            Appen kommer att tas bort. Appen kommer att avinstalleras på enheter som har appen installerad. Glöm inte att flytta tillbaka licenserna till den
            gamla platsen i ASM.
          </div>
          <a class="btn btn-danger" v-on:click="deleteApp()" data-bs-dismiss="modal" aria-label="Close">Ta bort app</a>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import * as routerHandler from "../../utils/router/routerHandler.js";
import TabBar from "../../components/tabBar/TabBar.vue";
import ExpandableHeader from "../../components/expandableHeader/ExpandableHeader.vue";
import TableKeyValue from "../../components/tables/tableKeyValue/TableKeyValue.vue";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import Loading from "../../components/loading/Loading.vue";
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";
import Modal from "../../components/modal/Modal.vue";

export default {
  name: "ApplicationDetails",
  components: {
    TabBar,
    ExpandableHeader,
    TableKeyValue,
    Loading,
    TablePaginated,
    Sidemenu,
    Modal,
  },
  data() {
    return {
      tabItems: ["Detaljer", "Tilldelade enheter"],
      activeTabeItem: "Detaljer",
      loadingAutomaticAssignments: true,
      loadingDeleteApp: false,
      automaticAssignments: [],
    };
  },
  methods: {
    goToApplications() {
      routerHandler.goToPath(this, "/Applications");
    },
    updateAutomaticAssignments() {
      this.loadingAutomaticAssignments = true;
      this.$http.put(`/api/ustart/application/${this.$route.query.param}/automaticAssignments`, this.automaticAssignments).then((result) => {
        this.automaticAssignments = result.data;
        this.loadingAutomaticAssignments = false;
      });
    },
    deleteApp() {
      var thisVueInstance = this;
      this.loadingDeleteApp = true;
      this.$http.delete(`/api/ustart/application/${this.$route.query.param}`).then((result) => {
        setTimeout(function() {
          routerHandler.goToPath(thisVueInstance, "/Applications");
        }, 1000);
      });
    },
  },
  computed: {
    devicesColumnConfig() {
      return columnConfigHandler.getConfig("applicationAssignedDevices", []);
    },
    sideMenuItems() {
      return [
        { type: "header", label: "Appdetaljer" },
        {
          type: "link",
          iconClass: "fas fa-bomb",
          label: "Ta bort app",
          modal: "deleteAppModal",
          condition: this.loadingDeleteApp == false,
          badge: null,
        },
      ];
    },
  },
  mounted() {
    this.$http.get(`/api/ustart/application/${this.$route.query.param}/automaticAssignments`).then((result) => {
      this.automaticAssignments = result.data;
      this.loadingAutomaticAssignments = false;
    });
  },
};
</script>

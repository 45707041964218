module.exports = [
  {
    type: "string",
    attribute: "email",
    label: "Användare",
  },
  {
    type: "string",
    attribute: "displayName",
    label: "Enhetsnamn",
  },
  {
    type: "string",
    attribute: "serialNumber",
    label: "Serienummer",
  },
  {
    type: "string",
    attribute: "model",
    label: "Modell",
    visible: false,
  },
  {
    type: "string",
    attribute: "platform",
    label: "Plattform",
    visible: false,
  },
  {
    type: "string",
    attribute: "osVersion",
    label: "Operativsystem",
  },
  {
    type: "string",
    attribute: "isDep",
    label: "DEP",
    visible: false,
  },
  {
    type: "string",
    attribute: "diskSizeMb",
    label: "Utrymme",
    visible: false,
  },
  {
    type: "string",
    attribute: "diskAvailableMb",
    label: "Ledigt utrymme",
    visible: false,
  },
  {
    type: "string",
    attribute: "sthlmVerksamhetsId",
    label: "VedaId",
  },
  {
    type: "string",
    attribute: "adDepartment",
    label: "Verksamhet",
  },
  {
    type: "string",
    attribute: "comment",
    label: "Kommentar",
    visible: false,
  },
  {
    type: "string",
    attribute: "alias",
    label: "Alias",
    visible: false,
  },
  {
    type: "string",
    attribute: "lastCheckin",
    label: "Senast ansluten",
  },
  {
    type: "string",
    attribute: "enrolled",
    label: "Registrerad",
  },
  // {
  //   type: "list",
  //   attribute: "missmatchedLocationGroups",
  //   label: "Grupper med fel veda",
  // },
];

module.exports = [
  {
    type: "string",
    attribute: "platformCombinedName",
    label: "Namn",
  },
  {
    type: "informationClassificationStatus",
    attribute: "informationClassificationStatus",
    label: "Klassning",
  },
  {
    type: "list",
    attribute: "informationClassificationCategories",
    label: "Klassning kategorier",
    visible: false,
  },
  {
    type: "html",
    attribute: "informationClassificationExternalNote",
    label: "Klassning kommentar",
    visible: false,
  },
  {
    type: "string",
    attribute: "informationClassificationUpdated",
    label: "Klassning ändrad",
    visible: false,
  },
  {
    type: "string",
    attribute: "informationClassificationCreated",
    label: "Klassning skapad",
    visible: false,
  },
  {
    type: "string",
    attribute: "platform",
    label: "Plattform",
    visible: false,
  },
  {
    type: "string",
    attribute: "totalLicenses",
    label: "Licenser",
  },
  {
    type: "string",
    attribute: "usedLicenses",
    label: "Använda licenser",
    visible: false,
  },
  {
    type: "string",
    attribute: "freeLicenses",
    label: "Lediga licenser",
  },
  {
    type: "list",
    attribute: "automaticAssignments",
    label: "Tilldelas automatiskt",
  },
];

<template>
  <div class="content">
    <div v-show="searchMode">
      <h5>{{ header }}</h5>
      <div class="mb-3 mt-4 col-12 col-md-3">
        <label class="form-label">{{ inputLabel }}</label>
        <input type="text" class="form-control" :placeholder="inputPlaceholder" v-model="searchValue" />
      </div>
      <button type="button" class="btn btn-primary" v-on:click="search()">Sök</button>
    </div>
    <div v-if="resultMode">
      <div class="mb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" v-on:click="goBackToSearch()">{{ header }}</a>
            </li>
            <li class="breadcrumb-item active">Resultat</li>
          </ol>
        </nav>
      </div>

      <table-paginated :tableId="tableId" :backendPath="searchUrlComputed" :columnConfig="columnConfig" />
    </div>
  </div>
</template>

<script>
const MODES = {
  SEARCH_MODE: "SEARCH_MODE",
  RESULT_MODE: "RESULT_MODE",
};

const RESULT_MODES = {
  LIST: "LIST",
  KEY_VALUE: "KEY_VALUE",
};

import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";

export default {
  name: "SimpleSearch",
  props: {
    tableId: { type: String },
    header: { type: String, default: "" },
    inputLabel: { type: String, default: "" },
    inputPlaceholder: { type: String, default: "" },
    backendUrl: { type: String, default: "" },
    columnConfig: { type: Array, default: () => [] },
  },
  components: { TablePaginated },
  data() {
    return {
      mode: MODES.SEARCH_MODE,
      searchValue: "",
      result: {
        data: null,
        mode: null,
      },
    };
  },
  methods: {
    search() {
      this.mode = MODES.RESULT_MODE;
    },
    goBackToSearch() {
      this.mode = MODES.SEARCH_MODE;
      this.searchValue = "";
    },
  },
  computed: {
    searchMode() {
      return this.mode == MODES.SEARCH_MODE;
    },
    resultMode() {
      return this.mode == MODES.RESULT_MODE;
    },
    searchUrlComputed() {
      return `/api/ustart${this.backendUrl.replace("{input}", this.searchValue)}`;
    },
  },
};
</script>

<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Enhetsdetaljer" :isSubMenu="true" @linkEvent="linkEvent" />
    <div class="content content-sub-sidebar-present">
      <loading v-if="loading.enableDisableLostMode" />
      <div class="col-12" v-if="!loading.enableDisableLostMode">
        <div class="mb-5">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href v-on:click="goToMyUser()">Mitt konto</a>
              </li>
              <li class="breadcrumb-item active">Enhetsdetaljer</li>
            </ol>
          </nav>
        </div>
        <notification message="Enheten synkroniserades" v-model="notifications.completedCheckinDevice" />
        <notification message="Lösenkoden togs bort från enheten" v-model="notifications.completedRemovePasscode" />
        <notification message="Enheten fabriksåterställdes" v-model="notifications.completedWipeDevice" />

        <tab-bar :items="tabItems" v-model="activeTabeItem" />
        <br />
        <table-key-value
          ref="deviceDetailsTableKeyValue"
          v-if="showDetailsTable"
          v-show="activeTabeItem == 'Detaljer'"
          :backendPath="`/api/ustart/device/myDevice/${this.$route.query.param}/details/keyValue`"
        />

        <table-paginated
          v-if="isMobileDevice"
          v-show="activeTabeItem == 'Kö'"
          tableId="deviceHistoryManagementCommandsPendingv2"
          :backendPath="`/api/ustart/device/myDevice/${this.$route.query.param}/history/managementCommands/pending`"
          :columnConfig="deviceHistoryManagementCommandsPendingColumnConfig"
        />

        <table-paginated
          v-show="activeTabeItem == 'Installerade appar'"
          tableId="deviceInstalledApplications"
          :backendPath="`/api/ustart/device/myDevice/${this.$route.query.param}/installedApplications`"
          :columnConfig="deviceInstalledApplicationsColumnConfig"
        />

        <modal modalId="enableLostModeModal" header="Aktivera lost mode">
          <p>
            Enheten kommer att låsas och går inte att använda. Ett telefonnummer och meddelande kommer att visas om någon försöker använda enheten. Lost mode
            går att ta bort.
          </p>
          <div class="mb-3">
            <label class="form-label">Telefonnummer</label>
            <input type="text" class="form-control" v-model="enableLostModeObject.phone" />
          </div>
          <div class="mb-3">
            <label class="form-label">Meddelande</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="enableLostModeObject.message"></textarea>
          </div>
          <a class="btn btn-danger" v-on:click="enableLostMode()" data-bs-dismiss="modal" aria-label="Close">Aktivera lost mode</a>
        </modal>

        <modal modalId="disableLostModeModal" header="Ta bort lost mode">
          <p>Lost mode kommer att tas bort och enheten kan användas igen.</p>
          <a class="btn btn-danger" v-on:click="disableLostMode()" data-bs-dismiss="modal" aria-label="Close">Ta bort lost mode</a>
        </modal>

        <modal modalId="getPukModal" header="Hämta PUK-kod">
          <loading v-if="loading.puk" />
          <div v-if="puk != null" class="alert alert-success mb-3" role="alert"><b>PUK-kod: </b>{{ puk.puk }}</div>
          <a class="btn btn-success" v-on:click="getPuk()" aria-label="Close">Hämta</a>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import * as routerHandler from "../../utils/router/routerHandler.js";
import TabBar from "../../components/tabBar/TabBar.vue";
import TableKeyValue from "../../components/tables/tableKeyValue/TableKeyValue.vue";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import Notification from "../../components/notification/Notification.vue";
import Modal from "../../components/modal/Modal.vue";
import Loading from "../../components/loading/Loading.vue";
import SaveableTextarea from "../../components/saveableTextarea/SaveableTextarea.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";

const subMenuEvents = {
  CHECK_IN: "CHECK_IN",
  REMOVE_PIN: "REMOVE_PIN",
};

export default {
  name: "MyDeviceDetails",
  components: {
    TabBar,
    TableKeyValue,
    TablePaginated,
    Notification,
    Modal,
    Loading,
    SaveableTextarea,
    Sidemenu,
  },
  data() {
    return {
      mounted: false,
      deviceDetails: null,
      //By default only show tab items for macOS, push additional tabs in mounted() if device is mobileDevice
      tabItems: ["Detaljer", "Installerade appar"],
      activeTabeItem: "Detaljer",
      showDetailsTable: true,
      loading: {
        puk: false,
        enableDisableLostMode: false,
        checkinDevice: false,
        removePasscode: false,
      },
      notifications: {
        completedCheckinDevice: false,
        completedRemovePasscode: false,
      },
      enableLostModeObject: {
        phone: "",
        message: "",
      },
      lostModeCoordinates: null,
      puk: null,
    };
  },
  methods: {
    goToMyUser() {
      routerHandler.goToPath(this, "/MyUser");
    },
    checkInDevice() {
      this.loading.checkinDevice = true;
      this.$http.post(`/api/ustart/device/myDevice/${this.$route.query.param}/sendBlankPush`).then((result) => {
        this.notifications.completedCheckinDevice = true;
        this.loading.checkinDevice = false;
      });
    },
    removePasscodeFromDevice() {
      this.loading.removePasscode = true;
      this.$http.post(`/api/ustart/device/myDevice/${this.$route.query.param}/sendRemovePasscode`).then((result) => {
        this.notifications.completedRemovePasscode = true;
        this.loading.removePasscode = false;
      });
    },

    linkEvent(data) {
      if (data == subMenuEvents.CHECK_IN) {
        this.checkInDevice();
      } else if (data == subMenuEvents.REMOVE_PIN) {
        this.removePasscodeFromDevice();
      }
    },
    getLostModeCoordinates() {
      this.$http.get(`/api/ustart/device/myDevice/${this.$route.query.param}/lostModeCoordinates`).then((result) => {
        this.lostModeCoordinates = result.data[0];
      });
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    enableLostMode() {
      this.loading.enableDisableLostMode = true;
      this.$http.put(`/api/ustart/device/myDevice/${this.$route.query.param}/enableLostMode`, this.enableLostModeObject).then((result) => {
        this.sleep(5000).then(() => {
          this.$router.go(this.$router.currentRoute);
        });
      });
    },
    disableLostMode() {
      this.loading.enableDisableLostMode = true;
      this.$http.put(`/api/ustart/device/myDevice/${this.$route.query.param}/disableLostMode`).then((result) => {
        this.sleep(5000).then(() => {
          this.$router.go(this.$router.currentRoute);
        });
      });
    },
    getPuk() {
      this.loading.puk = true;
      this.$http.get(`/api/ustart/device/myDevice/${this.$route.query.param}/puk`).then((result) => {
        this.loading.puk = false;
        this.puk = result.data;
      });
    },
  },
  computed: {
    isMobileDevice() {
      return this.$route.query.param.includes("iOS");
    },
    deviceHistoryManagementCommandsCompletedColumnConfig() {
      return columnConfigHandler.getConfig("deviceHistoryManagementCommandsCompleted", []);
    },
    deviceHistoryManagementCommandsPendingColumnConfig() {
      return columnConfigHandler.getConfig("deviceHistoryManagementCommandsPending", []);
    },
    nameOnlyColumnConfig() {
      return [
        {
          type: "string",
          attribute: "name",
          label: "Namn",
        },
      ];
    },
    deviceInstalledApplicationsColumnConfig() {
      return columnConfigHandler.getConfig("deviceInstalledApplications", []);
    },
    lostModeEnabled() {
      if (this.mounted) {
        try {
          return this.$refs.deviceDetailsTableKeyValue.rows.filter((item) => item.key == "Lost mode")[0].value == "Sant";
        } catch (err) {
          return false;
        }
      } else {
        return false;
      }
    },
    deviceIsDep() {
      if (this.mounted) {
        try {
          var val = this.$refs.deviceDetailsTableKeyValue.rows.filter((item) => item.key == "DEP")[0].value;
          return val == "Sant";
        } catch (err) {
          return false;
        }
      } else {
        return false;
      }
    },
    lostModeGoogleMapsUrl() {
      if (this.lostModeEnabled && this.lostModeCoordinates != null) {
        return `http://www.google.se/maps/place/${this.lostModeCoordinates.lost_location_latitude},${this.lostModeCoordinates.lost_location_longitude}/@${this.lostModeCoordinates.lost_location_latitude},${this.lostModeCoordinates.lost_location_longitude},15z`;
      } else {
        return "";
      }
    },
    sideMenuItems() {
      return [
        { type: "header", label: "Hantera enhet" },
        { type: "link", iconClass: "fa fa-tachometer-alt", label: "Synkronisera", event: subMenuEvents.CHECK_IN, badge: null },
        { type: "link", iconClass: "fas fa-info-circle", label: "Hämta PUK-kod", modal: "getPukModal", badge: null },
        { type: "link", iconClass: "fas fa-bomb", label: "Ta bort lösenkod", event: subMenuEvents.REMOVE_PIN, badge: null, condition: this.isMobileDevice },
        {
          type: "link",
          iconClass: "fab fa-avianex",
          label: "Aktivera lost mode",
          modal: "enableLostModeModal",
          badge: null,
          condition: !this.lostModeEnabled && this.isMobileDevice && this.deviceIsDep,
        },
        {
          type: "link",
          iconClass: "fab fa-avianex",
          label: "Ta bort lost mode",
          modal: "disableLostModeModal",
          badge: null,
          condition: this.lostModeEnabled && this.isMobileDevice,
        },
        {
          type: "link",
          iconClass: "fas fa-map-marked",
          label: "Lokalisera enhet",
          externalUrl: this.lostModeGoogleMapsUrl,
          badge: null,
          condition: this.lostModeEnabled && this.isMobileDevice,
        },
      ];
    },
  },
  mounted() {
    this.mounted = true;
    if (this.isMobileDevice) {
      this.tabItems.push("Kö");
    }
    if (this.isMobileDevice) {
      this.getLostModeCoordinates();
    }
  },
};
</script>

import DaisyUserManagement from "../../views/daisy/DaisyUserManagement.vue";
import DaisyUserReport from "../../views/daisy/DaisyUserReport.vue";
import DaisyAssetReport from "../../views/daisy/DaisyAssetReport.vue";
import SimpleSearch from "../../abstractions/simpleSearch/SimpleSearch.vue";

export default [
  {
    path: "/DaisyUserManagement",
    name: "DaisyUserManagement",
    component: DaisyUserManagement,
  },
  {
    path: "/DaisyUserReport",
    name: "DaisyUserReport",
    component: DaisyUserReport,
  },
  {
    path: "/DaisyAssetReport",
    name: "DaisyAssetReport",
    component: DaisyAssetReport,
  },
  {
    path: "/DaisyUserRoles",
    name: "DaisyUserRoles",
    component: SimpleSearch,
    props: {
      header: "Roller för konto",
      inputLabel: "Användarnamn",
      backendUrl: "/daisy/user/{input}/roles",
      tableId: "DaisyUserRolesV1",
      columnConfig: [
        {
          type: "string",
          attribute: "organization",
          label: "Verksamhet",
        },
        {
          type: "list",
          attribute: "roles",
          label: "Roller",
        },
      ],
    },
  },
];

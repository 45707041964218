const usersConfig = require("./configs/users.js");
const devicesConfig = require("./configs/devices.js");
const deviceHistoryManagementCommandsCompletedConfig = require("./configs/deviceHistoryManagementCommandsCompleted.js");
const deviceHistoryManagementCommandsPendingConfig = require("./configs/deviceHistoryManagementCommandsPending.js");
const deviceInstalledApplicationsConfig = require("./configs/deviceInstalledApplications.js");
const adminApplicationsConfig = require("./configs/adminApplications.js");
const applicationsConfig = require("./configs/applications.js");
const ustartAdminsConfig = require("./configs/ustartAdmins.js");
const adminLogsConfig = require("./configs/adminLogs.js");
const groupsConfig = require("./configs/groups.js");
const centralApplicationsConfig = require("./configs/centralApplications.js");
const adminDevicesConfig = require("./configs/adminDevices.js");
const myDevicesConfig = require("./configs/myDevices.js");
const informationClassificationsConfig = require("./configs/informationClassifications.js");
const informationClassificationPurchasedApplicationsConfig = require("./configs/informationClassificationPurchasedApplications.js");
const publicInformationClassificationsConfig = require("./configs/publicInformationClassifications.js");
const informationClassificationAuditLogConfig = require("./configs/informationClassificationAuditLog.js");
const applicationsAssignConfig = require("./configs/applicationsAssign.js");
const applicationAssignedDevicesConfig = require("./configs/applicationAssignedDevices.js");
const classesConfig = require("./configs/classes.js");
const daisyUserReportConfig = require("./configs/daisyUserReport.js");
const daisyAssetReportConfig = require("./configs/daisyAssetReport.js");
const _ = require("lodash");

const configs = {
  users: usersConfig,
  devices: devicesConfig,
  deviceHistoryManagementCommandsCompleted: deviceHistoryManagementCommandsCompletedConfig,
  deviceHistoryManagementCommandsPending: deviceHistoryManagementCommandsPendingConfig,
  deviceInstalledApplications: deviceInstalledApplicationsConfig,
  adminApplications: adminApplicationsConfig,
  applications: applicationsConfig,
  ustartAdmins: ustartAdminsConfig,
  adminLogs: adminLogsConfig,
  groups: groupsConfig,
  centralApplications: centralApplicationsConfig,
  adminDevices: adminDevicesConfig,
  myDevices: myDevicesConfig,
  informationClassifications: informationClassificationsConfig,
  informationClassificationPurchasedApplications: informationClassificationPurchasedApplicationsConfig,
  publicInformationClassifications: publicInformationClassificationsConfig,
  informationClassificationAuditLog: informationClassificationAuditLogConfig,
  applicationsAssign: applicationsAssignConfig,
  applicationAssignedDevices: applicationAssignedDevicesConfig,
  classes: classesConfig,
  daisyUserReport: daisyUserReportConfig,
  daisyAssetReport: daisyAssetReportConfig,
};

exports.getConfig = function(name, excludeColumns = []) {
  if (!_.has(configs, name)) {
    throw `Found no columnConfig for name ${name}`;
  }

  if (excludeColumns.length > 0) {
    return configs[name].filter((config) => !excludeColumns.includes(config.displayName));
  } else {
    return configs[name];
  }
};

<template>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="accordion m-b-0">
        <div class="panel panel-default">
          <div class="panel-heading">
            <a
              id="expandableHeaderHeaderLink"
              class="btn-block collapsed"
              data-toggle="collapse"
              :href="'#expandableHeaderBody' + this.instanceId"
              style="font-size: 15px !important"
              >{{ header }}</a
            >
          </div>
          <div :id="'expandableHeaderBody' + this.instanceId" class="panel-collapse collapse" style="height: 0px">
            <div id="expandableHeaderBodyContent" class="panel-body">
              {{ text }}
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as utilsHandler from "../../utils/utilsHandler.js";

export default {
  name: "ExpandableHeader",
  props: {
    header: String,
    text: { type: String, default: "" },
  },
  data() {
    return {
      instanceId: "",
    };
  },
  watch: {},
  beforeMount() {
    this.instanceId = utilsHandler.generateUuidv4();
  },
};
</script>

<template>
  <div>
    <loading v-if="loading" />
    <div v-if="!loading">
      <!-- Start table settings modal -->
      <modal :modalId="'tableSettingsModal' + instanceId" header="Tabellinställningar">
        <!-- Start hide/show columns -->
        <h6>Kolumner</h6>
        <p>Du kan ändra ordningen på kolumner genom att dra och släppa kolumnnamnen nedan.</p>
        <draggable tag="div" :list="columnConfigInternal" handle=".handle">
          <div class="form-check" v-for="columnConfig in columnConfigInternal" v-bind:key="tableId + 'displayColumnLi' + columnConfig.label">
            <input class="form-check-input" type="checkbox" :id="tableId + 'colvis_' + columnConfig.label" v-model="columnConfig.visible" />
            <label class="form-check-label handle" :for="tableId + 'colvis_' + columnConfig.label">
              {{ columnConfig.label }}
              <span v-show="filters[columnConfig.attribute] != undefined && filters[columnConfig.attribute] != ''">(<b>Aktivt filter</b>)</span>
            </label>
          </div>
        </draggable>
        <div class="mt-3">
          <button type="button" class="btn btn-primary me-2" @click="showAllColumns">Markera alla</button>
          <button type="button" class="btn btn-primary me-2" @click="hideAllColumns">Avmarkera alla</button>
          <button type="button" class="btn btn-primary" @click="resetColumns">Återställ</button>
        </div>
        <!-- End hide/show columns -->
        <hr />
        <!-- Start rows per page -->
        <h6>Rader per sida</h6>
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <select class="form-select" v-model="paging.rowsPerPage">
              <option
                v-for="(rowsPerPageSettingItem, index) in paging.rowsPerPageSettingsItem"
                v-bind:key="'rowsPerPageSettingItem' + index"
                :value="rowsPerPageSettingItem"
              >
                {{ rowsPerPageSettingItem == 10000000 ? "Alla" : rowsPerPageSettingItem }}
              </option>
            </select>
          </div>
        </div>
        <!-- End rows per page -->
        <hr />
        <!-- Start font size -->
        <h6>Textstorlek</h6>
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <select class="form-select" v-model="tableFontSize">
              <option v-for="(tableFontSizeItem, index) in tableFontSizeItems" v-bind:key="'tableFontSizeItem' + index" :value="tableFontSizeItem">
                {{ tableFontSizeItem }}
              </option>
            </select>
          </div>
        </div>
        <!-- End font size -->
      </modal>
      <!-- End table settings modal -->

      <!-- Start table export modal -->
      <modal :modalId="'tableExportModal' + instanceId" header="Exportera">
        <div class="mb-3">
          <label class="form-label">Data</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="allDataRadio" id="allDataRadio" value="all" v-model="exportDataOption" />
            <label class="form-check-label" for="allDataRadio">
              Allt data
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="filteredDataRadio" id="filteredDataRadio" value="selected" v-model="exportDataOption" />
            <label class="form-check-label" for="filteredDataRadio">
              Filtrerat data
            </label>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Kolumner</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="allColumnsRadio" id="allColumnsRadio" value="all" v-model="exportColumnOption" />
            <label class="form-check-label" for="allColumnsRadio">
              Alla kolumner
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="selectedColumnsRadio" id="selectedColumnsRadio" value="selected" v-model="exportColumnOption" />
            <label class="form-check-label" for="selectedColumnsRadio">
              Valda kolumner
            </label>
          </div>
        </div>
        <div class="mb-3">
          <label for="csvExportFileNameInput" class="form-label">Filnamn</label>
          <input type="text" class="form-control" id="csvExportFileNameInput" v-model="exportFilename" />
        </div>
        <export-csv
          style=""
          :data="exportDataOption == 'all' ? tableDataSortedComputed : tableDataFilteredComputed"
          :name="exportFilename + '.csv'"
          :columns="exportColumnOption == 'all' ? allJsoncsvColumns : jsoncsvColumns"
        >
          <button type="button" class="btn btn-primary">Exportera till CSV</button>
        </export-csv>
      </modal>
      <!-- End table export modal -->

      <!-- Start main table -->
      <div>
        <div class="alert alert-warning" role="alert" v-show="hiddenColumnHasFilter">
          <strong>OBS!</strong> Du har ett eller flera aktiva filter för dolda kolumner.
        </div>
        <table class="table" style="table-layout: auto !important" v-bind:style="fontSizeStyle">
          <!-- Start table rows -->
          <tbody>
            <!-- Start table icon row -->
            <tr v-if="showSettingsRow">
              <td :colspan="enableSelect ? columnConfigVisibleItems.length + 1 : columnConfigVisibleItems.length" style="padding-top: 0px;">
                <a role="button" data-bs-toggle="modal" :data-bs-target="'#tableSettingsModal' + instanceId"><i class="fa fa-cog me-3"></i></a>
                <a role="button" data-bs-toggle="modal" :data-bs-target="'#tableExportModal' + instanceId"><i class="fa fa-download"></i></a>
              </td>
            </tr>
            <!-- End table icon row -->
            <!-- Start table column header row -->
            <tr v-if="showColumnHeaders">
              <td v-if="enableSelect">
                <input class="form-check-input" type="checkbox" v-model="allRowsSelected" />
              </td>
              <td
                v-for="(columnConfigItem, index) in columnConfigVisibleItems"
                v-bind:key="columnConfigItem.label"
                :class="[index > 1 ? 'd-none d-lg-table-cell' : '']"
              >
                <span
                  v-on:click="sortTableData(columnConfigItem.attribute)"
                  class="pointer"
                  :class="[sortCssClass(columnConfigItem.attribute)]"
                  style="white-space: nowrap"
                  ><b>{{ columnConfigItem.label }}</b></span
                >
              </td>
            </tr>
            <!-- End table column header row -->
            <!-- Start table filter row -->
            <tr v-if="showFilters">
              <td v-if="enableSelect"></td>
              <td
                v-for="(columnConfigItem, index) in columnConfigVisibleItems"
                v-bind:key="'search' + columnConfigItem.label"
                :class="[index > 1 ? 'd-none d-lg-table-cell' : '']"
              >
                <div class="input-group input-group-sm">
                  <input
                    class="form-control"
                    type="text"
                    v-bind:style="fontSizeStyle"
                    v-model="filters[columnConfigItem.attribute]"
                    :disabled="overrideInternalFilters != undefined && overrideInternalFilters[columnConfigItem.attribute] != undefined"
                    :class="overrideInternalFilters != undefined && [overrideInternalFilters[columnConfigItem.attribute] != undefined ? 'disabled' : '']"
                  />
                </div>
              </td>
            </tr>
            <!-- End table filter row -->
            <template v-for="(row, index) in rows">
              <tr v-bind:key="index">
                <td v-if="enableSelect">
                  <input class="form-check-input" type="checkbox" v-bind:value="row" v-model="selectedRows" />
                </td>
                <td
                  v-for="(columnConfigItem, index2) in columnConfigVisibleItems"
                  v-bind:key="'td' + index2"
                  :class="[index2 > 1 ? 'd-none d-lg-table-cell' : '']"
                >
                  <TablePaginatedRowCellContent :columnConfigItem="columnConfigItem" :row="row" @event="emitEvent" :tableFontSize="tableFontSize" />
                </td>
              </tr>
            </template>
          </tbody>
          <!-- End table rows -->
        </table>
      </div>
      <!-- End main table -->

      <!-- Start pagination -->
      <div v-if="showPaging" style="display: flex; align-items: center">
        <nav aria-label="Page navigation example">
          <ul class="pagination mb-0">
            <li class="page-item" role="button">
              <a class="page-link" v-on:click="paging.currentPage > 1 ? changePage(paging.currentPage - 1) : null">‹</a>
            </li>
            <li
              v-for="i in getPages(this.paging.currentPage, numberOfPages())"
              class="page-item"
              v-bind:class="[i == paging.currentPage ? 'active' : '']"
              v-bind:key="'page-item' + i"
              role="button"
            >
              <a v-if="i > 0" class="page-link" v-on:click="changePage(i)" v-bind:key="i">{{ i }}</a>
            </li>
            <li class="page-item" role="button">
              <a class="page-link" v-on:click="paging.currentPage < numberOfPages() ? changePage(paging.currentPage + 1) : null">›</a>
            </li>
          </ul>
        </nav>
        <span class="pull-right" style="margin-left: auto">{{ pagingDisplayText }}</span>
      </div>
      <!-- End pagination -->
    </div>
  </div>
</template>

<script>
import TablePaginatedRowCellContent from "./TablePaginatedRowCellContent.vue";
import Modal from "../../modal/Modal.vue";
import ExportCsv from "../../export/csv/ExportCsv.vue";
import draggable from "vuedraggable";
import Loading from "../../loading/Loading.vue";
import * as _ from "lodash";
import * as localStorageHandler from "../../../utils/localStorage/localStorageHandler.js";
import * as utilsHandler from "../../../utils/utilsHandler.js";
import Notification from "../../notification/Notification.vue";
import * as dayjsHandler from "../../../utils/dayjs/dayjsHandler.js";

export default {
  name: "TablePaginated",
  components: {
    Loading,
    Modal,
    ExportCsv,
    draggable,
    TablePaginatedRowCellContent,
    Notification,
  },
  props: {
    tableId: {
      type: String,
      required: true,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    backendPath: {
      type: String,
      default: "",
    },
    columnConfig: {
      type: Array,
      default: () => [],
    },
    parentFilters: {
      type: Object,
      default: () => {},
    },
    overrideInternalFilters: {
      type: Object,
      default: () => {},
    },
    showColumnHeaders: {
      type: Boolean,
      default: true,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    showSettingsRow: {
      type: Boolean,
      default: true,
    },
    showPaging: {
      type: Boolean,
      default: true,
    },
    exportFilename: {
      type: String,
      default: `Data ` + dayjsHandler.formatNow(),
    },
    enableSelect: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      instanceId: "",
      loading: true,
      tableDataInternal: [],
      selectedRows: [],
      allRowsSelected: false,
      paging: {
        rowsPerPage: 10,
        rowsPerPageSettingsItem: [10, 20, 50, 100, 500, 10000000],
        currentPage: 1,
      },
      filters: {},
      sort: {
        column: "",
        attribute: "",
        order: "asc",
      },
      columnConfigInternal: [],
      showSettings: false,
      showExport: false,
      exportFilenameInternal: this.exportFilename,
      tableFontSize: 14,
      tableFontSizeItems: [8, 9, 10, 11, 12, 13, 14, 15, 16],
      exportColumnOption: "all",
      exportDataOption: "all",
    };
  },
  methods: {
    //Set an internal version of columnConfig to allow for changing the visible attribute
    //Add visible attribute to config items without the attribute
    //Use saved columnConfig from localStorage if it exist
    setInternalColumnConfig() {
      var savedColumnConfig = localStorageHandler.getColumnConfig(this.tableId);
      if (!_.isNull(savedColumnConfig)) {
        this.columnConfigInternal = savedColumnConfig;
      } else {
        this.columnConfigInternal = [];
        this.columnConfig.forEach((item) => {
          if (!_.has(item, "visible")) {
            this.columnConfigInternal.push({ ...item, visible: true });
          } else {
            this.columnConfigInternal.push(item);
          }
        });
      }
    },
    seTableDataInternal(tableData) {
      this.tableDataInternal = [];
      tableData.forEach((row) =>
        this.tableDataInternal.push({
          rowIdentifier: utilsHandler.generateUuidv4(),
          ...row,
        }),
      );
    },
    selectAllRowsOnCurrentPage() {
      this.selectedRows = this.rows;
    },
    deselectAllRows() {
      this.selectedRows = [];
      this.allRowsSelected = false;
    },
    sortTableData(sortOnAttribute) {
      if (this.sort.attribute == sortOnAttribute) {
        if (this.sort.order == "asc") {
          this.sort.order = "desc";
        } else {
          this.sort.order = "asc";
        }
      } else {
        this.sort.attribute = sortOnAttribute;
      }
    },
    emitEvent(data) {
      this.$emit("event", data);
    },
    toggleshowSettings() {
      this.showSettings = !this.showSettings;
    },
    toggleshowExport() {
      this.showExport = !this.showExport;
    },
    sortCssClass(attribute) {
      if (this.sort.attribute == attribute) {
        if (this.sort.order == "asc") {
          return "sorted-asc";
        } else {
          return "sorted-desc";
        }
      } else {
        return "";
      }
    },
    changePage(page) {
      this.paging.currentPage = page;
    },
    numberOfPages() {
      var numPages = Math.ceil(this.tableDataFilteredComputed.length / this.paging.rowsPerPage);
      if (this.paging.currentPage != 1 && this.paging.currentPage > numPages) {
        if (numPages == 0) {
          this.paging.currentPage = 1;
        } else {
          this.paging.currentPage = numPages;
        }
      }
      return numPages;
    },
    getPages(current, n) {
      if (n <= 6) {
        return n;
      }
      if (current < 5) {
        return [1, 2, 3, 4, 5, 0, n];
      }
      if (current > n - 4) {
        return [1, 0, n - 4, n - 3, n - 2, n - 1, n];
      }
      return [1, 0, current - 1, current, current + 1, 0, n];
    },
    showAllColumns() {
      for (let columnConfig of this.columnConfigInternal) {
        columnConfig.visible = true;
      }
    },
    hideAllColumns() {
      for (let columnConfig of this.columnConfigInternal) {
        columnConfig.visible = false;
      }
    },
    resetColumns() {
      this.columnConfigInternal = [];
      this.columnConfig.forEach((item) => {
        if (!_.has(item, "visible")) {
          this.columnConfigInternal.push({ ...item, visible: true });
        } else {
          this.columnConfigInternal.push(item);
        }
      });
    },
  },
  computed: {
    rows() {
      var start = (this.paging.currentPage - 1) * this.paging.rowsPerPage;
      var end = start + parseInt(this.paging.rowsPerPage);
      return this.tableDataFilteredComputed.slice(start, end);
    },
    tableDataSortedComputed() {
      return _.orderBy(this.tableDataInternal, [this.sort.attribute], [this.sort.order]);
    },
    tableDataFilteredComputed() {
      var rows = this.tableDataSortedComputed;
      //Parent filters
      for (var attributeName in this.parentFilters) {
        if (this.parentFilters[attributeName].selectedItems.length != 0) {
          rows = rows.filter((row) => {
            if (_.isUndefined(row[attributeName]) || _.isNull(row[attributeName])) {
              return false;
            }
            if (_.isArray(row[attributeName])) {
              return row[attributeName].some((item) => this.parentFilters[attributeName].selectedItems.some((item2) => item == item2));
            }
            return this.parentFilters[attributeName].selectedItems.includes(row[attributeName].toString());
          });
        }
      }
      //Internal filters
      for (var attributeName in this.filters) {
        if (this.filters[attributeName] != "") {
          rows = rows.filter((row) => {
            if (_.isNull(row[attributeName])) {
              return false;
            }
            var equalExpression = new RegExp(`^equal[(].{1,}[)]$`).test(this.filters[attributeName].toLowerCase());
            if (equalExpression) {
              return (
                row[attributeName].toString().toLowerCase() ==
                this.filters[attributeName]
                  .toLowerCase()
                  .replace("equal(", "")
                  .slice(0, -1)
              );
            } else {
              return row[attributeName]
                .toString()
                .toLowerCase()
                .includes(this.filters[attributeName].toLowerCase());
            }
          });
        }
      }
      return rows;
    },
    columnConfigVisibleItems() {
      return this.columnConfigInternal.filter((item) => item.visible);
    },
    pagingDisplayText() {
      var startIndex = ((this.paging.currentPage - 1) * this.paging.rowsPerPage + 1).toString();
      var endIndex = ((this.paging.currentPage - 1) * this.paging.rowsPerPage + this.rows.length).toString();
      var total = this.tableDataFilteredComputed.length.toString();

      return `Visar ${startIndex} till ${endIndex} av totalt ${total} rader`;
    },
    jsoncsvColumns() {
      return _.map(this.columnConfigVisibleItems, ({ attribute, label }) => ({
        property: attribute,
        displayName: label,
      }));
    },
    allJsoncsvColumns() {
      return _.map(this.columnConfigInternal, ({ attribute, label }) => ({
        property: attribute,
        displayName: label,
      }));
    },
    fontSizeStyle() {
      return {
        fontSize: this.tableFontSize + "px",
      };
    },
    hiddenColumnHasFilter() {
      for (var attributeName in this.filters) {
        if (this.filters[attributeName] != "" && this.columnConfigInternal.filter((item) => item.attribute == attributeName)[0].visible == false) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    selectedRows() {
      this.$emit("input", this.selectedRows);
    },
    allRowsSelected: function(checked) {
      if (checked) {
        this.selectAllRowsOnCurrentPage();
      } else {
        this.deselectAllRows();
      }
    },
    parentFilters: {
      deep: true,
      handler() {
        this.deselectAllRows();
      },
    },
    filters: {
      deep: true,
      handler() {
        this.deselectAllRows();
      },
    },
    overrideInternalFilters: {
      deep: true,
      handler() {
        for (var attributeName in this.overrideInternalFilters) {
          if (this.columnConfigInternal.some((item) => item.attribute == attributeName)) {
            this.filters[attributeName] = this.overrideInternalFilters[attributeName];
          }
        }
      },
    },
    paging: {
      deep: true,
      handler() {
        this.deselectAllRows();
      },
    },
    "paging.rowsPerPage": function(newVal, oldVal) {
      localStorageHandler.setRowsPerPage(this.tableId, newVal);
    },
    tableFontSize: function(newVal, oldVal) {
      localStorageHandler.setTableFontSize(this.tableId, newVal);
    },
    columnConfigInternal: {
      deep: true,
      handler() {
        localStorageHandler.setColumnConfig(this.tableId, this.columnConfigInternal);
      },
    },
    tableData() {
      this.seTableDataInternal(this.tableData);
    },
  },
  mounted() {
    this.instanceId = utilsHandler.generateUuidv4();

    if (!this.showPaging) {
      this.paging.rowsPerPage = 10000;
    }

    var savedRowsPerPage = localStorageHandler.getRowsPerPage(this.tableId);
    if (!_.isNull(savedRowsPerPage)) {
      this.paging.rowsPerPage = savedRowsPerPage;
    }

    var savedTableFontSize = localStorageHandler.getTableFontSize(this.tableId);
    if (!_.isNull(savedTableFontSize)) {
      this.tableFontSize = savedTableFontSize;
    }

    if (this.backendPath) {
      this.$http.get(this.backendPath).then((result) => {
        this.seTableDataInternal(result.data);
        this.loading = false;
      });
    } else {
      this.seTableDataInternal(this.tableData);
      this.loading = false;
    }

    this.setInternalColumnConfig();
  },
};
</script>

<style scoped>
.sorted-asc::after {
  content: " ▲";
  font-size: 0.8em !important;
}
.sorted-desc::after {
  content: " ▼";
  font-size: 0.8em !important;
}
.paging-link-active {
  text-decoration: underline;
}

.disabled {
  cursor: not-allowed !important;
}
</style>

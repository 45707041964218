<template>
  <div>
    <loading v-if="loading" />
    <div v-show="!loading">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="page-header">
          <h1>Åsikter</h1>
        </div>
        <div class="row">
          <div class="col-md-7 col-sm-12 col-xs-12">
            <table class="table table--collapse table--b-t-0">
              <tbody v-for="(feedback, feedbackIndex) in feedbacks" v-bind:key="feedbackIndex">
                <tr>
                  <td>
                    <h3 style="margin-bottom: 0px">{{ feedback.Header }}</h3>
                    <p>
                      <i
                        ><small>
                          Skapades
                          {{ feedback.Created }}
                          av
                          {{ feedback.CreatedByUpn }}</small
                        ></i
                      >
                    </p>
                    <p>
                      {{ feedback.Text }}
                    </p>
                    <p>
                      <a v-on:click="feedback.showComments = !feedback.showComments"
                        >{{ feedback.showComments ? "Dölj" : "Visa" }} {{ feedback.comments.length }} svar & svarsformulär</a
                      >
                    </p>
                    <div v-show="feedback.showComments">
                      <div
                        style="background-color: #f5f3ee; padding: 10px; border-radius: 10px; margin-bottom: 20px"
                        v-for="(comment, commentIndex) in feedback.comments"
                        v-bind:key="feedbackIndex + ' ' + commentIndex"
                      >
                        <small
                          ><b>{{ comment.CreatedByUpn }} svarade {{ comment.Created }}</b></small
                        ><br />

                        {{ comment.Text }}
                      </div>
                      <loading v-if="feedback.loadingNewComment" />
                      <div v-show="!feedback.loadingNewComment">
                        <div class="form-group">
                          <textarea
                            name="textarea"
                            class="form-control"
                            placeholder="Skriv ett svar"
                            rows="2"
                            v-model="newFeedbackComment['text' + feedback.Id]"
                          ></textarea>
                        </div>
                        <a class="btn btn-default" v-on:click="createFeedbackComment(feedback.Id)">Svara</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-md-4 col-sm-12 col-xs-12 pull-right">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">Ny åsikt</h3>
              </div>
              <div class="panel-body">
                <loading v-if="loadingNewFeedback" />
                <div v-show="!loadingNewFeedback">
                  <div class="form-group">
                    <label>Rubrik</label>
                    <input type="text" class="form-control" placeholder="Rubrik" v-model="newFeedback.header" />
                  </div>
                  <div class="form-group">
                    <label>Text</label>
                    <textarea name="textarea" class="form-control" id="" placeholder="Text" rows="6" v-model="newFeedback.text"></textarea>
                  </div>
                  <a class="btn btn-default" v-on:click="createFeedback()">Skapa</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading/Loading.vue";

export default {
  name: "Feedback",
  components: { Loading },
  data() {
    return {
      loading: true,
      loadingNewFeedback: false,
      newFeedbackComment: {},
      newFeedback: {
        header: "",
        text: "",
      },
      feedbacks: [],
    };
  },
  methods: {
    createFeedback() {
      this.loadingNewFeedback = true;
      this.$http.post("/api/ustart/feedback", this.newFeedback).then((result) => {
        this.$http.get("/api/ustart/feedback").then((res) => {
          this.feedbacks = res.data;
          this.loadingNewFeedback = false;
          this.newFeedback.header = "";
          this.newFeedback.text = "";
        });
      });
    },
    createFeedbackComment(feedbackId) {
      var feedback = this.feedbacks.filter((item) => item.Id == feedbackId)[0];
      feedback.loadingNewComment = true;
      this.$http
        .post(`/api/ustart/feedback/${feedbackId}/comment`, {
          text: this.newFeedbackComment["text" + feedbackId],
        })
        .then((result) => {
          this.$http.get(`/api/ustart/feedback/${feedbackId}/comments`).then((res) => {
            feedback.comments = res.data;
            this.newFeedbackComment = {};
            feedback.loadingNewComment = false;
          });
        });
    },
  },
  computed: {},
  mounted() {
    this.$http.get("/api/ustart/feedback").then((result) => {
      this.feedbacks = result.data;
      //   this.feedbacks.forEach(item => {
      //     item.showComments = false;
      //   });
      this.loading = false;
    });
  },
};
</script>

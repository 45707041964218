module.exports = [
  {
    type: "event",
    eventName: "updateInformationClassification",
    linkLabelAttribute: "Name",
    attribute: "Name",
    label: "Namn",
  },
  {
    type: "string",
    attribute: "ItunesId",
    label: "ItunesId",
  },
  {
    type: "string",
    attribute: "Platform",
    label: "Plattform",
    visible: false,
  },
  {
    type: "informationClassificationStatus",
    attribute: "Status",
    label: "Klassning",
  },
  {
    type: "list",
    attribute: "Categories",
    label: "Kategorier",
  },
  {
    type: "html",
    attribute: "InternalNote",
    label: "Intern kommentar",
  },
  {
    type: "html",
    attribute: "ExternalNote",
    label: "Extern kommentar",
  },
  {
    type: "string",
    attribute: "Updated",
    label: "Ändrad",
    visible: false,
  },
  {
    type: "string",
    attribute: "UpdatedBy",
    label: "Ändrad av",
    visible: false,
  },
  {
    type: "string",
    attribute: "Created",
    label: "Skapad",
    visible: false,
  },
  {
    type: "string",
    attribute: "CreatedBy",
    label: "Skapad av",
    visible: false,
  },
];

<template>
  <div :class="theme">
    <div class="sidebar" :class="[isSubMenu ? 'sub-sidebar' : '']">
      <div class="sidebar-brand" v-if="header != ''">
        <a v-on:click="goToPath(headerPath)" :role="[headerPath == '' ? '' : 'button']">{{ header }}</a>
        <a class="float-right" href="#" v-show="isMobile" v-on:click="responsiveMenuExpanded = !responsiveMenuExpanded">
          <i class="float-right fas fa-bars"></i>
        </a>
      </div>
      <div v-show="responsiveMenuExpanded">
        <div class="sidebar-search" v-if="!hideFilter">
          <div>
            <div class="input-group">
              <input type="text" class="form-control search-menu" placeholder="" v-model="filter" />

              <span class="input-group-text">
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="sidebar-menu">
          <ul>
            <template v-for="(menuItem, index) in menuItemsFiltered">
              <template v-if="menuItem.condition === undefined || (menuItem.condition !== undefined && menuItem.condition == true)">
                <!-- Start menu item type Header -->
                <template v-if="menuItem.type == 'header'">
                  <li class="header-menu" v-bind:key="index">
                    <span style="padding-right: 7px;">{{ menuItem.label }}</span>
                    <i
                      v-if="menuItem.helpModal"
                      style="position: relative; top: 1px;"
                      class="fas fa-info-circle"
                      data-bs-toggle="modal"
                      :data-bs-target="'#' + menuItem.helpModal"
                      role="button"
                    ></i>
                  </li>
                </template>
                <!-- End menu item type Header -->

                <!-- Start menu item type link with path -->
                <template v-else-if="menuItem.type == 'link' && menuItem.path">
                  <li v-bind:key="index">
                    <a v-on:click="goToPath(menuItem.path)" role="button">
                      <i v-bind:class="menuItem.iconClass"></i>
                      <span>{{ menuItem.label }}</span>
                      <span v-if="menuItem.badge != null" v-bind:class="menuItem.badge.class">{{ menuItem.badge.label }}</span>
                    </a>
                  </li>
                </template>
                <!-- End menu item type link with path -->

                <!-- Start menu item type link with event -->
                <template v-else-if="menuItem.type == 'link' && menuItem.event">
                  <li v-bind:key="index">
                    <!-- Disabled -->
                    <template v-if="menuItem.disabled">
                      <a role="button" class="linkDisabled">
                        <i v-bind:class="menuItem.iconClass"></i>
                        <span>{{ menuItem.label }}</span>
                        <span v-if="menuItem.badge != null" v-bind:class="menuItem.badge.class">{{ menuItem.badge.label }}</span>
                      </a>
                    </template>
                    <!-- Enabled -->
                    <template v-else>
                      <a v-on:click="emitEvent(menuItem.event)" role="button">
                        <i v-bind:class="menuItem.iconClass"></i>
                        <span>{{ menuItem.label }}</span>
                        <span v-if="menuItem.badge != null" v-bind:class="menuItem.badge.class">{{ menuItem.badge.label }}</span>
                      </a>
                    </template>
                  </li>
                </template>
                <!-- End menu item type link with event -->

                <!-- Start menu item type link with modal -->
                <template v-else-if="menuItem.type == 'link' && menuItem.modal">
                  <li v-bind:key="index">
                    <!-- Disabled -->
                    <template v-if="menuItem.disabled">
                      <a role="button" class="linkDisabled">
                        <i v-bind:class="menuItem.iconClass"></i>
                        <span>{{ menuItem.label }}</span>
                        <span v-if="menuItem.badge != null" v-bind:class="menuItem.badge.class">{{ menuItem.badge.label }}</span>
                      </a>
                    </template>
                    <!-- Enabled -->
                    <template v-else>
                      <a data-bs-toggle="modal" :data-bs-target="'#' + menuItem.modal" role="button">
                        <i v-bind:class="menuItem.iconClass"></i>
                        <span>{{ menuItem.label }}</span>
                        <span v-if="menuItem.badge != null" v-bind:class="menuItem.badge.class">{{ menuItem.badge.label }}</span>
                      </a>
                    </template>
                  </li>
                </template>
                <!-- End menu item type link with modal -->

                <!-- Start menu item type link with external url -->
                <template v-else-if="menuItem.type == 'link' && menuItem.externalUrl">
                  <li v-bind:key="index">
                    <!-- Disabled -->
                    <template v-if="menuItem.disabled">
                      <a class="linkDisabled">
                        <i v-bind:class="menuItem.iconClass"></i>
                        <span>{{ menuItem.label }}</span>
                        <span v-if="menuItem.badge != null" v-bind:class="menuItem.badge.class">{{ menuItem.badge.label }}</span>
                      </a>
                    </template>
                    <!-- Enabled -->
                    <template v-else>
                      <a :href="menuItem.externalUrl" target="_blank">
                        <i v-bind:class="menuItem.iconClass"></i>
                        <span>{{ menuItem.label }}</span>
                        <span v-if="menuItem.badge != null" v-bind:class="menuItem.badge.class">{{ menuItem.badge.label }}</span>
                      </a>
                    </template>
                  </li>
                </template>
                <!-- End menu item type link with external url -->

                <!-- Start menu item type dropdown -->
                <template v-else-if="menuItem.type == 'dropdown'">
                  <li class="sidebar-dropdown" v-bind:key="index" v-bind:class="[menuItem.expanded ? 'active' : '']">
                    <a v-on:click="expandDropdown(menuItem)" role="button">
                      <i v-bind:class="menuItem.iconClass"></i>
                      <span>{{ menuItem.label }}</span>
                      <span v-if="menuItem.badge != null" v-bind:class="menuItem.badge.class">{{ menuItem.badge.label }}</span>
                    </a>
                    <div class="sidebar-submenu" v-show="menuItem.expanded">
                      <ul>
                        <li v-for="(menuItemLink, index2) in menuItem.links" v-bind:key="index2">
                          <a v-on:click="goToPath(menuItemLink.path)" role="button"
                            >{{ menuItemLink.label }}
                            <span v-if="menuItemLink.badge != null" v-bind:class="menuItemLink.badge.class">{{ menuItemLink.badge.label }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </template>
                <!-- End menu item type dropdown -->
              </template>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import * as routerHandler from "../../utils/router/routerHandler.js";

export default {
  name: "Sidemenu",
  props: {
    menuItems: {
      type: Array,
      required: true,
      default: () => [],
    },
    header: {
      type: String,
      default: "",
    },
    headerPath: {
      type: String,
      default: "",
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "dark-theme",
    },
    isSubMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentScreenWidth: 0,
      responsiveMenuExpanded: false,
      filter: "",
      menuItemsInternal: [],
    };
  },
  methods: {
    goToPath(path) {
      if (path != "") {
        var result = routerHandler.goToPath(this, path);
        //navigating to the same route
        if (!result) {
          this.$router.replace({ query: null });
          this.$emit("shouldReloadCurrentView", null);
        }
      }
    },
    emitEvent(event) {
      this.$emit("linkEvent", event);
    },
    expandDropdown(menuItem) {
      this.menuItemsInternal.filter((item) => item.label == menuItem.label)[0].expanded = !menuItem.expanded;
    },
    getDimensions() {
      var newScreenWidth = window.innerWidth;

      if (this.isMobile) {
        if (newScreenWidth > 768) {
          this.responsiveMenuExpanded = true;
        }
      } else {
        if (newScreenWidth <= 768) {
          this.responsiveMenuExpanded = false;
        }
      }
      this.currentScreenWidth = newScreenWidth;
    },
  },
  computed: {
    menuItemsFiltered() {
      if (this.filter == "") {
        return this.menuItems;
      }

      var thisVueInstance = this;
      var menuItemsClone = _.cloneDeep(this.menuItemsInternal);

      _.remove(menuItemsClone, function(menuItem) {
        if (menuItem.type === "link") {
          if (!menuItem.label.toUpperCase().includes(thisVueInstance.filter.toUpperCase())) {
            return true;
          } else {
            return false;
          }
        } else if (menuItem.type === "dropdown") {
          _.remove(menuItem.links, function(dropdownLink) {
            if (!dropdownLink.label.toUpperCase().includes(thisVueInstance.filter.toUpperCase())) {
              return true;
            } else {
              return false;
            }
          });

          return menuItem.links.length == 0;
        } else {
          return false;
        }
      });

      menuItemsClone
        .filter((menuItem) => menuItem.type === "dropdown")
        .forEach((menuItem) => {
          menuItem.expanded = true;
        });

      return menuItemsClone;
    },
    isMobile() {
      return this.currentScreenWidth < 768;
    },
  },
  watch: {
    menuItems() {
      this.menuItemsInternal = this.menuItems;
    },
  },
  mounted() {
    this.currentScreenWidth = document.documentElement.clientWidth;
    if (this.currentScreenWidth < 768) {
      this.responsiveMenuExpanded = false;
    } else {
      this.responsiveMenuExpanded = true;
    }
    window.addEventListener("resize", this.getDimensions);
    this.menuItemsInternal = this.menuItems;
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
};
</script>

<style scoped>
.linkDisabled {
  cursor: not-allowed !important;
  opacity: 0.3;
}
</style>

<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Alla verksamheter" :isSubMenu="true" />
    <div class="content content-sub-sidebar-present">
      <div class="row">
        <div class="col-12">
          <table-paginated
            v-if="displayVedaDepartmentsTable"
            tableId="adminVedaDepartmentsTablev7"
            backendPath="/api/ustart/admin/vedaDepartments"
            :columnConfig="adminVedaDepartmentsColumnConfig"
          />
        </div>
        <modal modalId="newDepartmentModal" header="Skapa verksamhet">
          <loading v-if="loadingNewDepartment" />
          <div class="col-6" v-show="!loadingNewDepartment">
            <div class="mb-3">
              <label class="form-label">Namn</label>
              <input type="text" class="form-control" placeholder="Namn" v-model="newDepartment.name" />
            </div>
            <div class="mb-3">
              <label class="form-label">VedaId</label>
              <input type="text" class="form-control" placeholder="VedaId" v-model="newDepartment.vedaId" />
            </div>
            <a class="btn btn-primary" v-on:click="createDepartment()">Skapa</a>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import Loading from "../../components/loading/Loading.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";
import Modal from "../../components/modal/Modal.vue";

export default {
  name: "AdminVedaDepartments",
  components: { TablePaginated, Loading, Sidemenu, Modal },
  data() {
    return {
      displayVedaDepartmentsTable: true,
      loadingNewDepartment: false,
      newDepartment: {
        name: "",
        vedaId: "",
      },
      sideMenuItems: [
        { type: "header", label: "Hantera enhet" },
        { type: "link", iconClass: "fas fa-building", label: "Skapa verksamhet", modal: "newDepartmentModal", badge: null },
      ],
    };
  },
  methods: {
    createDepartment() {
      this.loadingNewDepartment = true;
      this.$http.post("/api/ustart/admin/vedaDepartment", this.newDepartment).then((result) => {
        this.loadingNewDepartment = false;
        this.displayVedaDepartmentsTable = false;
        this.newDepartment.name = "";
        this.newDepartment.vedaId = "";
        //re-render table
        this.$nextTick(() => {
          this.displayVedaDepartmentsTable = true;
        });
      });
    },
  },
  computed: {
    adminVedaDepartmentsColumnConfig() {
      return [
        {
          type: "string",
          attribute: "Name",
          label: "Namn",
        },
        {
          type: "string",
          attribute: "VedaId",
          label: "VedaId",
        },
        {
          type: "string",
          attribute: "Enabled",
          label: "Aktiv",
          visible: false,
        },
        {
          type: "string",
          attribute: "JamfTeacherEnabled",
          label: "Jamf Teacher aktivierat",
        },
        {
          type: "list",
          attribute: "mobileDeviceGroups",
          label: "iOS-grupper",
          visible: false,
        },
        {
          type: "list",
          attribute: "computerGroups",
          label: "macOS-grupper",
          visible: false,
        },
        { type: "string", attribute: "nonpersonalAccount", label: "Opersonligt konto", visible: false },
        { type: "string", attribute: "vppAccount", label: "VPP-konto", visible: false },
        { type: "string", attribute: "category", label: "Kategori", visible: false },
        { type: "string", attribute: "namesDoesMatch", label: "Matchande namn" },
      ];
      //   return columnConfigHandler.getConfig("adminLogs", []);
    },
  },
  mounted() {},
};
</script>

<style></style>

import MyUser from "../../views/user/MyUser.vue";
import MyDevices from "../../views/user/MyDevices.vue";
import MyDeviceDetails from "../../views/user/MyDeviceDetails.vue";

export default [
  {
    path: "/MyUser",
    name: "MyUser",
    component: MyUser,
  },
  {
    path: "/MyDevices",
    name: "MyDevices",
    component: MyDevices,
  },
  {
    path: "/MyDeviceDetails",
    name: "MyDeviceDetails",
    component: MyDeviceDetails,
  },
];

<template>
  <div style="" v-if="shouldDisplay">
    <!-- Start router-link -->
    <!-- Router link triggers a vue route change  -->
    <template v-if="cellType == 'router-link'">
      <router-link
        v-bind:style="fontSizeStyle"
        :to="{
          name: columnConfigItem.route,
          query: { param: row[columnConfigItem.param] },
        }"
        class="link"
        >{{ cellValue }}</router-link
      >
    </template>
    <!-- End router-link -->

    <!-- Start ext-link -->
    <template v-else-if="cellType == 'ext-link'">
      <a :href="columnConfigItem.baseUrl + cellValue" class="link" target="_blank">{{ cellValue }}</a>
    </template>
    <!-- End ext-link -->

    <!-- Start image -->
    <template v-else-if="cellType == 'image'">
      <img :src="value" :style="columnConfigItem.style" />
    </template>
    <!-- End image -->

    <!-- Start string -->
    <template v-else-if="cellType == 'string'">{{ cellValue }}</template>
    <!-- End string -->

    <!-- Start event -->
    <template v-else-if="cellType == 'event'">
      <template v-if="columnConfigItem.linkLabelAttribute">
        <a class="link" v-on:click="emitEvent(columnConfigItem.eventName, cellValue)" role="button">{{ row[columnConfigItem.linkLabelAttribute] }}</a>
      </template>
      <template v-else-if="columnConfigItem.linkLabel">
        <a class="link" v-on:click="emitEvent(columnConfigItem.eventName, cellValue)" role="button">{{ columnConfigItem.linkLabel }}</a>
      </template>
    </template>
    <!-- End event -->

    <!-- Start event and modal -->
    <template v-else-if="cellType == 'eventAndModal'">
      <a
        class="link"
        v-on:click="emitEvent(columnConfigItem.eventName, cellValue)"
        data-bs-toggle="modal"
        :data-bs-target="'#' + columnConfigItem.modal"
        role="button"
        >{{ columnConfigItem.linkLabel }}</a
      >
    </template>
    <!-- End event and modal -->

    <!-- Start list -->
    <template v-else-if="cellType == 'list'">
      <ul class="ul-no-style" style="margin-bottom: 0px">
        <li v-for="(listObj, index) in cellValue" v-bind:key="index">
          <template v-if="columnConfigItem.listObjectAttribute">
            {{ listObj[columnConfigItem.listObjectAttribute] }}
          </template>
          <template v-else>
            {{ listObj }}
          </template>
        </li>
      </ul>
    </template>
    <!-- End list -->

    <!-- Start expandableList -->
    <template v-else-if="cellType == 'expandableList'">
      <div v-show="expandableListExpanded">
        <ul class="ul-no-style" style="margin-bottom: 0px">
          <li v-for="(listObj, index) in cellValue" v-bind:key="index">
            {{ listObj }}
          </li>
        </ul>
        <br />
      </div>
      <a class="link" v-show="!expandableListExpanded" v-on:click="expandableListExpanded = !expandableListExpanded" role="button"
        >Visa ({{ cellValue.length }})</a
      >
      <a class="link" v-show="expandableListExpanded" v-on:click="expandableListExpanded = !expandableListExpanded" role="button"
        >Dölj ({{ cellValue.length }})</a
      >
    </template>
    <!-- End expandableList -->

    <!-- Start label -->
    <template v-else-if="cellType == 'label'">
      <span class="label" :class="row.labelClass">{{ cellValue }}</span>
    </template>
    <!-- End label -->

    <!-- Start json -->
    <template v-else-if="cellType == 'json'">
      <json-viewer :value="JSON.parse(cellValue)" :expand-depth="5" theme="json-viewer-style" boxed copyable></json-viewer>
    </template>
    <!-- End json -->

    <!-- Start html -->
    <template v-else-if="cellType == 'html'">
      <div v-html="cellValue"></div>
    </template>
    <!-- End html -->

    <!-- Start informationClassificationStatus -->
    <template v-else-if="cellType == 'informationClassificationStatus'">
      <template v-if="cellValue == 'Godkänd'">
        <span class="badge bg-success">{{ cellValue }}</span>
      </template>
      <template v-else-if="cellValue == 'Godkänd med förbehåll'">
        <span class="badge bg-warning">{{ cellValue }}</span>
      </template>
      <template v-else-if="cellValue == 'Klassning pågår'">
        <span class="badge bg-classificationOngoing">{{ cellValue }}</span>
      </template>
      <template v-else-if="cellValue == 'Klassning krävs'">
        <span class="badge bg-primary">{{ cellValue }}</span>
      </template>
      <template v-else-if="cellValue == 'Ej godkänd'">
        <span class="badge bg-danger">{{ cellValue }}</span>
      </template>
      <template v-else>
        <span class="badge bg-secondary">{{ cellValue }}</span>
      </template>
    </template>
    <!-- End informationClassificationStatus -->
  </div>
</template>

<script>
import JsonViewer from "vue-json-viewer";

export default {
  name: "TablePaginatedRowCellContent",
  components: { JsonViewer },
  props: { columnConfigItem: Object, row: Object, tableFontSize: Number },
  data() {
    return {
      expandableListExpanded: false,
    };
  },
  methods: {
    emitEvent(eventName, data) {
      this.$emit("event", { eventName: eventName, data: data, row: this.row });
    },
  },
  computed: {
    cellType() {
      return this.columnConfigItem.type;
    },
    cellValue() {
      return this.row[this.columnConfigItem.attribute];
    },
    shouldDisplay() {
      if (!this.columnConfigItem.displayCondition) {
        return true;
      }
      return this.row[this.columnConfigItem.displayCondition];
    },
    fontSizeStyle() {
      return {
        fontSize: this.tableFontSize + "px",
      };
    },
  },
};
</script>

<style>
.json-viewer-style {
  background: transparent;
  white-space: nowrap;
  z-index: 0;
}
.json-viewer-style .jv-ellipsis {
  color: #ccc;
  background-color: #eee;
  display: inline-block;
  line-height: 0.9;
  font-size: 0.9em;
  padding: 0px 4px 2px 4px;
  border-radius: 3px;
  vertical-align: 2px;
  cursor: pointer;
  user-select: none;
}
.json-viewer-style .jv-button {
  color: #5d237d;
}
.json-viewer-style .jv-key {
  color: #111;
}
.json-viewer-style .jv-item.jv-array {
  color: #111;
}
.json-viewer-style .jv-item.jv-boolean {
  color: #fc1e70;
}
.json-viewer-style .jv-item.jv-function {
  color: #067bca;
}
.json-viewer-style .jv-item.jv-number {
  color: #fc1e70;
}
.json-viewer-style .jv-item.jv-number-float {
  color: #fc1e70;
}
.json-viewer-style .jv-item.jv-number-integer {
  color: #fc1e70;
}
.json-viewer-style .jv-item.jv-object {
  color: #111;
}
.json-viewer-style .jv-item.jv-undefined {
  color: #e08331;
}
.json-viewer-style .jv-item.jv-string {
  color: #42b983;
  word-break: break-word;
  white-space: normal;
}
</style>

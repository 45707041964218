import Axios from "axios";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as oidcHandler from "./utils/oidc/oidcHandler.js";

Vue.config.productionTip = false;

Axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    var errorCode = error && error.response && error.response.status;
    if (401 === errorCode) {
      store.dispatch("removeToken");
      oidcHandler.redirectToAuthorize();
    }
    if (403 === errorCode) {
      router.push({ path: "/Unauthorized", query: { requestId: error.response.data.requestId } });
    } else if (500 === errorCode && store.getters.authenticated) {
      router.push({ path: "/Error", query: { requestId: error.response.data.requestId } });
    } else {
      return Promise.reject(error);
    }
  },
);

// On any request:
// - Add the authorization token if it is available
// - Add the default baseURL if no baseURL is already set
Axios.interceptors.request.use(
  function(config) {
    var authToken = store.getters.token;
    var authHeader = authToken ? { Authorization: authToken } : {};
    return {
      baseURL: process.env.VUE_APP_BACKEND,
      ...config,
      headers: {
        ...config.headers,
        ...authHeader,
      },
    };
  },
  function(error) {
    return Promise.reject(error);
  },
);

Vue.prototype.$http = Axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
dayjs.extend(utc);

/**
 * Format now
 * @param {String} [format='YYYY-MM-DD HH:mm'] datetime format, "YYYY-MM-DD HH:mm default"
 */
export const formatNow = (format = "YYYY-MM-DD HH:mm") => {
  return dayjs().format(format);
};

/**
 * Format a string
 * @param {String} string
 * @param {String} [format='YYYY-MM-DD HH:mm'] datetime format, "YYYY-MM-DD HH:mm default"
 */
export const formatString = (string, format = "YYYY-MM-DD HH:mm") => {
  return dayjs(string).format(format);
};

/**
 * Format date properties for an object/array
 * This method will mutate the passed object/array
 * @param {object|Array} object data object/array
 * @param {Array} properties properties to be formatted
 * @param {String} [format='YYYY-MM-DD HH:mm'] datetime format, "YYYY-MM-DD HH:mm default"
 */
export const formatProperties = (object, properties, format = "YYYY-MM-DD HH:mm") => {
  const formatPropertiesOnObject = (object, properties, format) => {
    properties.forEach((property) => {
      object[property] = dayjs(object[property]).format(format);
    });
  };

  if (Array.isArray(object)) {
    object.forEach((objectItem) => formatPropertiesOnObject(objectItem, properties, format));
  } else {
    formatPropertiesOnObject(object, properties, format);
  }
};

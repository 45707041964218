import Changelog from "../../views/information/Changelog.vue";
import KnownIssues from "../../views/information/KnownIssues.vue";
import UnderDevelopment from "../../views/information/UnderDevelopment.vue";
import Help from "../../views/information/Help.vue";

export default [
  {
    path: "/Changelog",
    name: "Changelog",
    component: Changelog,
  },
  {
    path: "/KnownIssues",
    name: "KnownIssues",
    component: KnownIssues,
  },
  {
    path: "/UnderDevelopment",
    name: "UnderDevelopment",
    component: UnderDevelopment,
  },
  {
    path: "/Help",
    name: "Help",
    component: Help,
  },
];

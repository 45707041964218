module.exports = [
  {
    type: "string",
    attribute: "asset_tag",
    label: "COS-nummer",
  },
  {
    type: "string",
    attribute: "serial_number",
    label: "Serienummer",
  },
  {
    type: "string",
    attribute: "category",
    label: "Kategori",
  },
  {
    type: "string",
    attribute: "subcategory",
    label: "Subkategori",
  },
  {
    type: "string",
    attribute: "dv_model_id",
    label: "Modell",
  },
  {
    type: "string",
    attribute: "dv_manufacturer",
    label: "Tillverkare",
    visible: false,
  },
  {
    type: "string",
    attribute: "install_date",
    label: "Installationsdatum",
  },
  {
    type: "string",
    attribute: "install_status",
    label: "Installationsstatus",
    visible: false,
  },
  {
    type: "string",
    attribute: "dv_managed_by",
    label: "Manageras av",
    visible: false,
  },
  {
    type: "string",
    attribute: "dv_owned_by",
    label: "Ägs av",
  },
  {
    type: "string",
    attribute: "po_number",
    label: "PO-nummer",
    visible: false,
  },
];

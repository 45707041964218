<template>
  <div class="content">
    <div class="col-12">
      <loading v-if="loading" />
      <div v-show="!loading">
        <div class="row">
          <div class="col-md-7 col-sm-12 col-xs-12">
            <div v-for="(changelogItem, index) in changelogItems" v-bind:key="index">
              <h4 style="margin-bottom: 0px">{{ changelogItem.version }}</h4>
              {{ changelogItem.date }}
              <br /><br />
              <ul>
                <li v-for="(changelogItemItem, index) in changelogItem.items" v-bind:key="'changelogItemItem' + index">
                  {{ changelogItemItem.text }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading/Loading.vue";

export default {
  name: "Changelog",
  components: { Loading },
  data() {
    return {
      loading: true,
      changelogItems: [],
    };
  },
  methods: {},
  mounted() {
    this.$http.get("/api/ustart/information/changelog").then((result) => {
      this.changelogItems = result.data;
      this.loading = false;
    });
  },
};
</script>

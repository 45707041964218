<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="JAMF Teacher" :isSubMenu="true" @linkEvent="linkEvent" />
    <div class="content content-sub-sidebar-present">
      <div class="col-12">
        <notification message="Klassen skapades!" v-model="notifications.inserted" />
        <notification message="Klassen uppdaterades!" v-model="notifications.updated" />
        <notification message="Klassen togs bort!" v-model="notifications.deleted" />
        <table-paginated
          v-if="mode == 'showClasses'"
          tableId="classesTableV1"
          backendPath="/api/ustart/classes"
          :columnConfig="classesColumnConfig"
          @event="readTableEvent"
        />
        <div v-if="mode == 'upsertClass'">
          <div class="mb-5">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href v-on:click="mode = 'showClasses'">Klasser</a>
                </li>
                <li class="breadcrumb-item active">{{ upsertMode == "insert" ? "Skapa klass" : "Ändra klass" }}</li>
              </ol>
            </nav>
          </div>
          <UpsertClass
            :mode="upsertMode"
            :updateObject="updateClassModel"
            :teacherUsers="teacherUsers"
            :studentUsers="studentUsers"
            :mobileDeviceGroups="groups"
            @upsertEvent="upsertEvent"
          />
        </div>
      </div>
    </div>
    <modal modalId="helpModal" header="Om JAMF Teacher">
      <p>
        JAMF Teacher är en app som kan användas av lärare för att hantera elevers enheter under lektionstid. Lärare kan genom att sätta tidsbegränsade
        restriktioner styra vilka appar och webbplatser som får användas.<br /><br />
        För att kunna använda JAMF Teacher behövs klasser. En klass innehåller lärare och elever där lärarna har möjlighet att hantera enheter för de elever som
        ingår i klassen. Läraren kan byta mellan sina klasser i JAMF Teacher-appen. <br />När en klass skapas väljs lärare, elever och elevenhetsgrupper. Om en
        lärare eller elev väljs så kommer samtliga enheter som är registrerade på den användaren att ingå i klassen. Om en elevenhetsgrupp väljs så kommer
        samtliga enheter som ingår den i enhetsgruppen att ingå i klassen.<br /><br />JAMF Teacher är en centraliserad app som laddas hem via Self service.
      </p>
    </modal>
  </div>
</template>

<script>
const subMenuEvents = {
  ADD_CLASS: "ADD_CLASS",
};

import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import Loading from "../../components/loading/Loading.vue";
import Notification from "../../components/notification/Notification.vue";
import Modal from "../../components/modal/Modal.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";
import UpsertClass from "./components/UpsertClass.vue";

export default {
  name: "Classes",
  components: { TablePaginated, Notification, Loading, Modal, Sidemenu, UpsertClass },
  data() {
    return {
      mode: "showClasses",
      upsertMode: "insert",
      updateClassModel: null,
      users: [],
      groups: [],
      notifications: {
        inserted: false,
        updated: false,
        deleted: false,
      },
    };
  },
  methods: {
    linkEvent(event) {
      if (event == subMenuEvents.ADD_CLASS) {
        this.updateClassModel = null;
        this.mode = "upsertClass";
        this.upsertMode = "insert";
      }
    },
    upsertEvent(event) {
      this.mode = "showClasses";
      if (event == "inserted") {
        this.notifications.inserted = true;
      } else if (event == "updated") {
        this.notifications.updated = true;
      } else if (event == "deleted") {
        this.notifications.deleted = true;
      }
    },
    readTableEvent(event) {
      this.mode = "upsertClass";
      this.upsertMode = "update";
      this.updateClassModel = event.row;
    },
  },
  computed: {
    classesColumnConfig() {
      return columnConfigHandler.getConfig("classes", []);
    },
    teacherUsers() {
      return this.users.filter((user) => user.email_address.includes("@edu.stockholm.se") && !user.email_address.toLowerCase().startsWith("jamf-"));
    },
    studentUsers() {
      return this.users.filter((user) => user.email_address.includes("@elevmail.stockholm.se") || user.email_address.toLowerCase().startsWith("jamf-"));
    },
    sideMenuItems() {
      return [
        { type: "header", label: "Klasser" },
        { type: "link", iconClass: "fa fa-users", label: "Skapa klass", event: subMenuEvents.ADD_CLASS, badge: null, condition: this.mode == "showClasses" },
        { type: "header", label: "Hjälp" },
        { type: "link", iconClass: "fa fa-info-circle", label: "Om JAMF Teacher", modal: "helpModal", badge: null },
      ];
    },
  },
  mounted() {
    this.$http.get("/api/ustart/users").then((result) => {
      this.users = result.data;
    });
    this.$http.get("/api/ustart/groups").then((result) => {
      this.groups = result.data.filter((group) => group.platform == "iOS");
    });
  },
};
</script>

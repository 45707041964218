<template>
  <div class="content">
    <div class="col-12">
      <table-paginated
        tableId="informationClassificationPurchasedApplicationsTableV4"
        backendPath="/api/ustart/informationclassification/purchasedapplications"
        :columnConfig="informationClassificationPurchasedApplicationsConfig"
        @event="tableEvent"
      />
    </div>
  </div>
</template>

<script>
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";

export default {
  name: "PurchasedApplications",
  components: { TablePaginated },
  data() {
    return {};
  },
  methods: {
    tableEvent(data) {
      this.$router.push({ path: "InformationClassifications", query: { name: data.row.name, itunesId: data.row.adam_id } });
    },
  },
  computed: {
    informationClassificationPurchasedApplicationsConfig() {
      return columnConfigHandler.getConfig("informationClassificationPurchasedApplications", []);
    },
  },
  mounted() {},
};
</script>

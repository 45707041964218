module.exports = [
  {
    type: "string",
    attribute: "name",
    label: "Namn",
  },
  {
    type: "string",
    attribute: "adam_id",
    label: "ItunesId",
  },
  {
    type: "informationClassificationStatus",
    attribute: "informationClassificationStatus",
    label: "Klassning",
  },
  {
    type: "string",
    attribute: "product_type",
    label: "Plattform",
  },
  {
    type: "expandableList",
    attribute: "schools",
    label: "Skolor",
  },
  {
    type: "string",
    attribute: "schoolCount",
    label: "Skolor antal",
  },
  {
    type: "string",
    attribute: "license_count",
    label: "Licenser",
  },
  {
    type: "string",
    attribute: "used_license_count",
    label: "Använda licenser",
  },
  { type: "event", label: "Klassa", attribute: "adam_id", linkLabel: "Klassa", displayCondition: "showClassifyLink" },
];

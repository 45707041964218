module.exports = [
  {
    type: "string",
    attribute: "displayName",
    label: "DisplayName",
  },
  {
    type: "string",
    attribute: "userPrincipalName",
    label: "UserPrincipalName",
  },
  {
    type: "string",
    attribute: "sAMAccountName",
    label: "SamAccountName",
  },
  {
    type: "string",
    attribute: "employeeType",
    label: "EmployeeType",
  },
  {
    type: "string",
    attribute: "sthlmVerksamhetsId",
    label: "SthlmVerksamhetsId",
  },
  {
    type: "string",
    attribute: "sthlmFakturaRef",
    label: "SthlmFakturaRef",
    visible: false,
  },
  {
    type: "string",
    attribute: "logonCount",
    label: "LogonCount",
    visible: false,
  },
];

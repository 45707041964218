<template>
  <div>
    <table class="table" style="table-layout: auto !important">
      <tbody>
        <tr>
          <td colspan="2">
            <b>{{ header }}</b>
          </td>
        </tr>
        <tr>
          <td colspan="2"><input class="form-control" type="text" v-model="filter" /></td>
        </tr>
        <tr
          v-for="(row, index) in filteredRows"
          v-bind:key="index"
          v-bind:class="[bgGreen(row[rowIdAttribute]) ? 'green-bg' : '', bgRed(row[rowIdAttribute]) ? 'red-bg' : '']"
        >
          <td style="width: 10px; !important;">
            <input class="form-check-input" type="checkbox" v-bind:value="row[rowIdAttribute]" v-model="selectedRowIds" />
          </td>
          <td>{{ row[rowDisplayAttribute] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "SelectTable",
  components: {},
  props: {
    header: {
      type: String,
      default: "",
    },
    rows: {
      type: Array,
      default: () => [],
    },
    preSelectedRowIds: {
      type: Array,
      default: () => [],
    },
    rowDisplayAttribute: {
      type: String,
      default: "",
    },
    rowIdAttribute: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      filter: "",
      selectedRowIds: [],
    };
  },
  methods: {
    bgGreen(id) {
      if (this.selectedRowIds.includes(id) && !this.preSelectedRowIds.includes(id)) {
        return true;
      } else {
        return false;
      }
    },
    bgRed(id) {
      if (!this.selectedRowIds.includes(id) && this.preSelectedRowIds.includes(id)) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    filteredRows() {
      return this.rows.filter((row) => row[this.rowDisplayAttribute].toLowerCase().includes(this.filter.toLowerCase()));
    },
  },
  watch: {
    selectedRowIds() {
      this.$emit("input", this.selectedRowIds);
    },
  },
  mounted() {
    this.preSelectedRowIds.forEach((id) => {
      this.selectedRowIds.push(id);
    });
  },
};
</script>

<style scoped>
.green-bg {
  background-color: #e2ffe2;
}
.red-bg {
  background-color: #ffd3d3;
}
</style>

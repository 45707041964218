module.exports = [
  {
    type: "string",
    attribute: "Type",
    label: "Typ",
  },
  {
    type: "string",
    attribute: "DisplayMessage",
    label: "Ändring",
  },
  {
    type: "html",
    attribute: "OldValue",
    label: "Tidigare värde",
    visible: false,
  },
  {
    type: "html",
    attribute: "NewValue",
    label: "Nytt värde",
    visible: false,
  },
  {
    type: "string",
    attribute: "Created",
    label: "Ändrad",
  },
  {
    type: "string",
    attribute: "CreatedBy",
    label: "Ändrad av",
    visible: false,
  },
];

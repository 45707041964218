<template>
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="page-header">
      <h1>Kända problem</h1>
    </div>
    <loading v-if="loading" />
    <div v-show="!loading">
      <div class="row">
        <div class="col-md-7 col-sm-12 col-xs-12">
          <div v-for="(knownIssueItem, index) in knownIssueItems" v-bind:key="index">
            <h3>{{ knownIssueItem.Header }}</h3>
            <p>{{ knownIssueItem.Text }}</p>
            <p><b>Påverkan</b><br />{{ knownIssueItem.Impact }}</p>
            <p><b>Åtgärd</b><br />{{ knownIssueItem.Action }}</p>
            <br /><br />
          </div>
        </div>

        <div class="col-md-4 col-sm-12 col-xs-12 pull-right">
          <div class="well">
            <h3>Kända problem</h3>
            <p>
              Här listas de kända problem som finns i JAMF och Ustart3. Ett känt problem innebär att ett problem har kunnat identifieras som ett återkommande
              problem och att en lösning jobbas på.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading/Loading.vue";

export default {
  name: "KnownIssues",
  components: { Loading },
  data() {
    return {
      loading: true,
      knownIssueItems: [],
    };
  },
  methods: {},
  mounted() {
    this.$http.get("/api/ustart/information/knownIssues").then((result) => {
      this.knownIssueItems = result.data;
      this.loading = false;
    });
  },
};
</script>

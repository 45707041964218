<template>
  <div class="box" :class="[pointerCursorClass]" v-on:click="onClick()">
    <h4 v-if="valueResultIsLoading">{{ valueResultIsLoadingString }}</h4>
    <h4 v-if="!valueResultIsLoading">{{ valueResult.value }}</h4>
    <p>{{ config.text }}</p>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "DashboardItem",
  props: {
    id: {
      type: Number,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valueResult: null,
      valueResultIsLoading: true,
      valueResultIsLoadingString: ".",
    };
  },
  methods: {
    updateValueResultIsLoadingString() {
      if (this.valueResultIsLoading) {
        if (this.valueResultIsLoadingString == "...") {
          this.valueResultIsLoadingString = ".";
        } else {
          this.valueResultIsLoadingString += ".";
        }
        var thisVueInstance = this;
        setTimeout(function() {
          thisVueInstance.updateValueResultIsLoadingString();
        }, 250);
      }
    },
    onClick() {
      if (this.clickable) {
        if (this.config.onClick.type == "route") {
          this.$router.push({ path: this.config.onClick.route, query: this.config.onClick.queryParams });
        }
      }
    },
  },
  computed: {
    clickable() {
      return !_.isUndefined(this.config.onClick);
    },
    pointerCursorClass() {
      return {
        pointer: this.clickable,
      };
    },
  },
  mounted() {
    this.updateValueResultIsLoadingString();
    this.$http.get(`/api/ustart/dashboard/${this.id}/item/${this.config.id}/value`).then((result) => {
      this.valueResult = result.data;
      this.valueResultIsLoading = false;
    });
  },
};
</script>

<style scoped>
.box {
  text-align: center;
  background-color: #f1f1f1;
  color: #333333;
  padding-top: 15px;
  padding-bottom: 5px;
  border-radius: 4px;
}
</style>

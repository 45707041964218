var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('loading',{staticClass:"content"}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"content"},[_c('tab-bar',{attrs:{"items":_vm.tabItems},model:{value:(_vm.activeTabItem),callback:function ($$v) {_vm.activeTabItem=$$v},expression:"activeTabItem"}}),_c('br'),_c('table-paginated',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabItem == 'iOS'),expression:"activeTabItem == 'iOS'"}],attrs:{"tableData":_vm.configurationPackages.filter((item) => item.Platform == 'iOS'),"tableId":"restrictionsPackagesTableiOSV2","columnConfig":[
        {
          type: 'string',
          attribute: 'Name',
          label: 'Restriktion',
        },
        {
          type: 'string',
          attribute: 'Description',
          label: 'Beskrivning',
        },
        {
          type: 'list',
          attribute: 'configurationProfiles',
          listObjectAttribute: 'name',
          label: 'Restriktioner',
        },
      ]}}),_c('table-paginated',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabItem == 'macOS'),expression:"activeTabItem == 'macOS'"}],attrs:{"tableData":_vm.configurationPackages.filter((item) => item.Platform == 'macOS'),"tableId":"restrictionsPackagesTablemacOSV1","columnConfig":[
        {
          type: 'string',
          attribute: 'Name',
          label: 'Restriktion',
        },
        {
          type: 'string',
          attribute: 'Description',
          label: 'Beskrivning',
        },
        {
          type: 'list',
          attribute: 'configurationProfiles',
          listObjectAttribute: 'name',
          label: 'Restriktioner',
        },
      ]}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
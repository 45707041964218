var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('loading',{staticClass:"content"}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"content"},[_c('tab-bar',{attrs:{"items":_vm.tabItems},model:{value:(_vm.activeTabItem),callback:function ($$v) {_vm.activeTabItem=$$v},expression:"activeTabItem"}}),_c('br'),_c('table-paginated',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabItem == 'iOS'),expression:"activeTabItem == 'iOS'"}],attrs:{"tableData":_vm.restrictionProfiles.filter((item) => item.platform == 'iOS'),"tableId":"restrictionsTableiOSV1","columnConfig":[
        {
          type: 'string',
          attribute: 'name',
          label: 'Restriktion',
        },
      ]}}),_c('table-paginated',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabItem == 'macOS'),expression:"activeTabItem == 'macOS'"}],attrs:{"tableData":_vm.restrictionProfiles.filter((item) => item.platform == 'macOS'),"tableId":"restrictionsTablemacOSV1","columnConfig":[
        {
          type: 'string',
          attribute: 'name',
          label: 'Restriktion',
        },
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
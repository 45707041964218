<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" :header="headerText" :hideFilter="true" :isSubMenu="true" @linkEvent="linkEvent" />
    <div class="content content-sub-sidebar-present">
      <div class="mb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" v-on:click="closeComponent()">Enheter</a>
            </li>
            <li v-show="displayContent == 'main'" class="breadcrumb-item active">{{ headerText }}</li>
            <li v-show="displayContent == 'result'" class="breadcrumb-item">{{ headerText }}</li>
            <li v-show="displayContent == 'result'" class="breadcrumb-item active">Resultat</li>
          </ol>
        </nav>
      </div>
      <div v-show="displayContent == 'result'">
        <div class="col-12">
          <div v-show="assignQueue.length != 0">
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="progressBarStyle"></div>
            </div>
            <center>
              <p>{{ progressText }}</p>
            </center>
          </div>
          <table-paginated tableId="assignApplicationsResultTablev3" :tableData="assignResult" :columnConfig="resultColumnConfig" />
        </div>
      </div>
      <div v-show="displayContent == 'main'">
        <div class="col-12">
          <table-paginated
            tableId="assignApplicationsTablev6"
            backendPath="/api/ustart/applications"
            :columnConfig="applicationsAssignColumnConfig"
            :showFilters="true"
            :showSettingsRow="true"
            :showColumnHeaders="true"
            :showPaging="true"
            :enableSelect="true"
            v-model="selectedApplications"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TablePaginated from "../../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../../utils/columnConfig/columnConfigHandler.js";
import Sidemenu from "../../../components/sidemenu/Sidemenu.vue";

const subMenuEvents = {
  ACTION: "ACTION",
};

export default {
  name: "DevicesAssignApplications",
  components: {
    TablePaginated,
    Sidemenu,
  },
  props: {
    selectedDevices: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: "Assign",
    },
  },
  data() {
    return {
      displayContent: "main",
      selectedApplications: [],
      assignQueue: [],
      assignQueueStartLength: 0,
      assignResult: [],
    };
  },
  methods: {
    closeComponent() {
      this.$emit("event", null);
    },
    assignApplications() {
      this.selectedDevices.forEach((selectedDevice) => {
        this.selectedApplications.forEach((selectedApplication) => {
          this.assignQueue.push({
            type: "application",
            device: selectedDevice,
            application: selectedApplication,
          });
        });
      });
      this.displayContent = "result";
      this.assignQueueStartLength = this.assignQueue.length;
      this.runAssignQueue();
    },
    runAssignQueue() {
      if (this.assignQueue.length != 0) {
        this.$http
          .post(
            `/api/ustart/application/${this.assignQueue[0].application.platformCombinedId}/${this.apiActionText}/${this.assignQueue[0].device.platformCombinedId}`,
          )
          .then((result) => {
            this.assignResult.unshift({
              email: this.assignQueue[0].device.email,
              serialNumber: this.assignQueue[0].device.platformCombinedSerialNumber,
              applicationName: this.assignQueue[0].application.platformCombinedName,
              result: result.data.result,
            });
            this.assignQueue.shift();
            this.$nextTick(() => {
              this.runAssignQueue();
            });
          });
      }
    },
    linkEvent(data) {
      if (data == subMenuEvents.ACTION) {
        this.assignApplications();
      }
    },
  },
  computed: {
    anySelectedApplications() {
      return this.selectedApplications.length > 0;
    },

    sideMenuItems() {
      if (this.displayContent == "main") {
        return [
          { type: "header", label: "Markerade appar" },
          {
            type: "link",
            iconClass: "fab fa-app-store",
            label: this.headerText,
            event: subMenuEvents.ACTION,
            badge: null,
            disabled: !this.anySelectedApplications,
          },
        ];
      } else {
        return [];
      }
    },
    headerText() {
      return this.mode == "Assign" ? "Tilldela appar" : "Ta bort appar";
    },
    apiActionText() {
      return this.mode == "Assign" ? "assign" : "remove";
    },
    noEffectText() {
      return this.mode == "Assign" ? "Redan tilldelad" : "Ej tilldelad";
    },
    applicationsAssignColumnConfig() {
      return columnConfigHandler.getConfig("applicationsAssign", []);
    },
    resultColumnConfig() {
      return [
        {
          type: "string",
          attribute: "email",
          label: "Användare",
        },
        {
          type: "string",
          attribute: "serialNumber",
          label: "Serienummer",
        },
        {
          type: "string",
          attribute: "applicationName",
          label: "Applikation",
        },
        {
          type: "string",
          attribute: "result",
          label: "Resultat",
        },
      ];
    },
    progressBarStyle() {
      var width = Math.floor(((this.assignQueueStartLength - this.assignQueue.length) / this.assignQueueStartLength) * 100);
      return {
        width: `${width}%`,
      };
    },
    progressText() {
      var at = this.assignQueueStartLength - this.assignQueue.length + 1;
      var total = this.assignQueueStartLength;
      return `${this.mode == "Assign" ? "Tilldelar" : "Tar bort"} ${at} av ${total}`;
    },
    successResults() {
      return this.assignResult.filter((item) => item.result == "Lyckades").length;
    },
    noEffectResults() {
      return this.assignResult.filter((item) => item.result == this.noEffectText).length;
    },
    wrongPlattformResults() {
      return this.assignResult.filter((item) => item.result == "Felaktig plattform").length;
    },
    failResults() {
      return this.assignResult.filter((item) => item.result == "Misslyckades").length;
    },
  },
};
</script>

<template>
  <div>
    <div v-show="!loading">
      <div class="mb-4 col-lg-6 col-md-12">
        <label for="nameInput" class="form-label">Namn</label>
        <input type="text" class="form-control" id="nameInput" v-model="classModel.name" />
      </div>
      <div class="mb-5 col-lg-6 col-md-12">
        <label for="descriptioInput" class="form-label">Beskrivning</label>
        <textarea class="form-control" id="descriptioInput" rows="2" v-model="classModel.description"></textarea>
      </div>

      <div class="mb-4 row">
        <div class="col-lg-4 col-md-12">
          <select-table
            header="Lärare"
            :rows="teacherUsers"
            :preSelectedRowIds="preSelectedTeacherIds"
            rowDisplayAttribute="full_name"
            rowIdAttribute="id"
            v-model="selectedTeacherIds"
          />
        </div>
        <div class="col-lg-4 col-md-12">
          <select-table
            header="Elever"
            :rows="studentUsers"
            :preSelectedRowIds="preSelectedStudentIds"
            rowDisplayAttribute="full_name"
            rowIdAttribute="id"
            v-model="selectedStudentIds"
          />
        </div>
        <div class="col-lg-4 col-md-12">
          <select-table
            header="Elevenhetsgrupper"
            :rows="mobileDeviceGroups"
            :preSelectedRowIds="preSelectedMobileDeviceGroupIds"
            rowDisplayAttribute="name"
            rowIdAttribute="id"
            v-model="selectedMobileDeviceGroupIds"
          />
        </div>
      </div>

      <div class="mb-4">
        <button type="button" class="btn btn-primary me-2" v-on:click="upsertClass()">{{ upsertButtonLabel }}</button>
        <button v-if="!insertMode" type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteModal">
          Ta bort klass
        </button>
      </div>

      <modal modalId="deleteModal" header="Ta bort klass">
        <p>Bekräfta borttagning av klass.</p>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" v-on:click="deleteClass()">Ta bort klass</button>
      </modal>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/loading/Loading.vue";
import Modal from "../../../components/modal/Modal.vue";
import TabBar from "../../../components/tabBar/TabBar.vue";
import TablePaginated from "../../../components/tables/tablePaginated/TablePaginated.vue";
import SelectTable from "./SelectTable.vue";

export default {
  name: "UpsertClass",
  components: { Loading, Modal, TabBar, TablePaginated, SelectTable },
  props: {
    mode: {
      type: String,
      default: "insert",
    },
    updateObject: {
      type: Object,
      default: null,
    },
    insertObject: {
      type: Object,
      default: null,
    },
    teacherUsers: {
      type: Array,
      default: () => [],
    },
    studentUsers: {
      type: Array,
      default: () => [],
    },
    mobileDeviceGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      classModel: {
        id: "",
        name: "",
        description: "",
      },
      selectedTeacherIds: [],
      selectedStudentIds: [],
      selectedMobileDeviceGroupIds: [],
    };
  },
  methods: {
    upsertClass() {
      this.loading = true;
      this.$http
        .put("/api/ustart/class/upsert", {
          id: this.classModel.id,
          name: this.classModel.name,
          description: this.classModel.description,
          student_ids: this.selectedStudentIds,
          teacher_ids: this.selectedTeacherIds,
          mobile_device_group_ids: this.selectedMobileDeviceGroupIds,
        })
        .then(() => {
          this.loading = false;
          if (this.insertMode) {
            this.$emit("upsertEvent", "inserted");
          } else {
            this.$emit("upsertEvent", "updated");
          }
        });
    },
    deleteClass() {
      this.loading = true;
      this.$http.delete(`/api/ustart/class/${this.classModel.id}`).then(() => {
        this.$emit("upsertEvent", "deleted");
      });
    },
    resetClassModel() {
      this.classModel = {
        id: "",
        name: "",
        description: "",
      };
    },
    setUpdateModel() {
      this.classModel.id = this.updateObject.id;
      this.classModel.name = this.updateObject.name;
      this.classModel.description = this.updateObject.description;
    },
  },
  computed: {
    insertMode() {
      return this.mode === "insert";
    },
    upsertButtonLabel() {
      return this.insertMode ? "Skapa" : "Spara";
    },
    preSelectedTeacherIds() {
      if (this.insertMode) {
        return [];
      } else {
        return this.updateObject.teacher_ids;
      }
    },
    preSelectedStudentIds() {
      if (this.insertMode) {
        return [];
      } else {
        return this.updateObject.student_ids;
      }
    },
    preSelectedMobileDeviceGroupIds() {
      if (this.insertMode) {
        return [];
      } else {
        return this.updateObject.mobile_device_group_ids;
      }
    },
  },
  watch: {
    mode(val) {
      if (val == "insert") {
        // this.resetInformationClassificationModel();
      }
    },
  },
  mounted() {
    if (!this.insertMode) {
      this.setUpdateModel();
    } else if (this.insertMode && this.insertObject != null) {
      //   this.informationClassificationModel.name = this.insertObject.name;
      //   this.informationClassificationModel.itunesId = this.insertObject.itunesId;
      //   this.$router.replace({ query: {} });
    }
  },
};
</script>

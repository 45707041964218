<template>
  <ul class="nav nav-tabs" role="tablist">
    <li v-for="(item, index) in items" v-bind:key="index" class="nav-item pointer" v-on:click="activeItem = item" role="tab">
      <a class="nav-link" :class="[activeItem == item ? 'active' : '']">{{ item }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "TabBar",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeItem: "",
    };
  },
  watch: {
    activeItem() {
      this.$emit("input", this.activeItem);
    },
  },
  beforeMount() {
    if (this.items.length > 0) {
      this.activeItem = this.items[0];
    }
  },
};
</script>

module.exports = [
  {
    type: "string",
    attribute: "name",
    label: "Kommando",
  },
  {
    type: "string",
    attribute: "date_time_completed_epoch",
    label: "Utfördes",
  },
];

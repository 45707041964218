import InformationClassifications from "../../views/informationClassification/InformationClassifications.vue";
import PurchasedApplications from "../../views/informationClassification/PurchasedApplications.vue";
import InformationClassificationsPublic from "../../views/informationClassification/InformationClassificationsPublic.vue";

export default [
  {
    path: "/InformationClassifications",
    name: "InformationClassifications",
    component: InformationClassifications,
  },
  {
    path: "/PurchasedApplications",
    name: "PurchasedApplications",
    component: PurchasedApplications,
  },
  {
    path: "/InformationClassificationsPublic",
    name: "InformationClassificationsPublic",
    component: InformationClassificationsPublic,
  },
];

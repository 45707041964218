import Devices from "../../views/device/Devices.vue";
import DeviceDetails from "../../views/device/DeviceDetails.vue";

export default [
  {
    path: "/Devices",
    name: "Devices",
    component: Devices,
  },
  {
    path: "/DeviceDetails",
    name: "DeviceDetails",
    component: DeviceDetails,
  },
];

<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Mitt konto" :isSubMenu="true" @linkEvent="linkEvent" />
    <div class="content content-sub-sidebar-present">
      <div class="row">
        <div class="col-md-12">
          <notification message="Dina tabellinställningar har återställts." v-model="showResetLocalStorageSuccess" />
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <b>Användarnamn</b>
                </td>
                <td>{{ tokenDecodedSamAccountName }}</td>
              </tr>
              <tr>
                <td>
                  <b>E-postadress</b>
                </td>
                <td>{{ tokenDecodedUserPrincipalName }}</td>
              </tr>
              <tr>
                <td>
                  <b>Verksamheter</b>
                </td>
                <td>{{ tokenDecodedVedaIds.join(", ") }}</td>
              </tr>
              <tr>
                <td>
                  <b>Primärverksamhet</b>
                </td>
                <td>{{ tokenDecodedPrimaryVedaId }}</td>
              </tr>
              <tr>
                <td>
                  <b>Aktiv verksamhet</b>
                </td>
                <td>
                  {{ tokenDecodedActiveDepartmentName }}
                  {{ tokenDecodedActiveDepartmentVedaId }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Roller</b>
                </td>
                <td>{{ tokenDecodedRoles.join(", ") }}</td>
              </tr>
              <tr>
                <td><b>Inloggning giltig till</b></td>
                <td>{{ tokenDecodedExpDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <modal v-if="!noRoles" modalId="changeDepartment" header="Byt verksamhet">
          <loading v-if="loadingEnabledDepartments" />
          <div v-show="!loadingEnabledDepartments" class="mb-3 col-6">
            <label class="form-label" for="chooseOption">Aktiv verksamhet</label>
            <select class="form-select" v-model="activeDepartment">
              <option v-for="(enabledDepartment, index) in enabledDepartments" v-bind:key="index" :value="enabledDepartment">
                {{ enabledDepartment.Name }}
              </option>
            </select>
          </div>
        </modal>

        <modal modalId="resetTablesModal" header="Återställ tabeller">
          <div class="alert alert-warning" role="alert">
            Tabellinställningar för samtliga tabeller kommer att åstersällas.
          </div>
          <a class="btn btn-danger" v-on:click="resetLocalStorage()" data-bs-dismiss="modal" aria-label="Close">Återställ</a>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../../store/index.js";
import Loading from "../../components/loading/Loading.vue";
import Notification from "../../components/notification/Notification.vue";
import Modal from "../../components/modal/Modal.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";

const subMenuEvents = {};

export default {
  name: "MyUser",
  components: { Loading, Notification, Sidemenu, Modal, TablePaginated },
  data() {
    return {
      loadingEnabledDepartments: true,
      showResetLocalStorageSuccess: false,
      enabledDepartments: [],
      activeDepartment: null,
      firstActiveDepartmentChangeDone: false,
    };
  },
  methods: {
    linkEvent(data) {
      if (data == subMenuEvents.RESET_TABLE_SETTINGS) {
        this.resetLocalStorage();
      } else if (data == subMenuEvents.LOG_OUT) {
      }
    },
    resetLocalStorage() {
      var token = localStorage.getItem("token");
      localStorage.clear();
      localStorage.setItem("token", token);
      this.showResetLocalStorageSuccess = true;
      var thisVueInstance = this;
      new Promise((res) => setTimeout(() => (thisVueInstance.showResetLocalStorageSuccess = false), 1500));
    },
  },
  computed: {
    ...mapGetters([
      "tokenDecodedIsGlobalAdmin",
      "tokenDecodedIsUstartAdmin",
      "tokenDecodedSamAccountName",
      "tokenDecodedUserPrincipalName",
      "tokenDecodedRoles",
      "tokenDecodedPrimaryVedaId",
      "tokenDecodedVedaIds",
      "tokenDecodedActiveDepartmentName",
      "tokenDecodedActiveDepartmentVedaId",
      "tokenDecodedExpDate",
    ]),
    noRoles() {
      return !this.tokenDecodedIsGlobalAdmin && !this.tokenDecodedIsUstartAdmin;
    },
    sideMenuItems() {
      return [
        { type: "header", label: "Hantera konto" },
        { type: "link", iconClass: "fas fa-user", label: "Byt verksamhet", modal: "changeDepartment", badge: null, condition: !this.noRoles },
        {
          type: "link",
          iconClass: "fas fa-bomb",
          label: "Återställ tabeller",
          modal: "resetTablesModal",
          badge: null,
          condition: !this.noRoles,
        },
        { type: "link", iconClass: "fas fa-sign-out-alt", label: "Logga ut", path: "logout", badge: null },
      ];
    },
  },
  watch: {
    activeDepartment() {
      if (!this.firstActiveDepartmentChangeDone) {
        this.firstActiveDepartmentChangeDone = true;
      } else {
        this.loadingEnabledDepartments = true;
        this.$http.put(`/api/authentication/setActiveDepartment/${this.activeDepartment.VedaId}`).then((result) => {
          store.dispatch("setToken", result.data);
          this.loadingEnabledDepartments = false;
        });
      }
    },
  },
  mounted() {
    if (!this.noRoles) {
      this.$http.get("/api/authentication/enabledDepartments").then((result) => {
        this.enabledDepartments = result.data;
        this.activeDepartment = this.enabledDepartments.filter((item) => item.VedaId == this.tokenDecodedActiveDepartmentVedaId)[0];
        this.loadingEnabledDepartments = false;
      });
    }
  },
};
</script>

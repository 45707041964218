module.exports = [
  {
    type: "router-link",
    route: "UserDetails",
    param: "upn",
    attribute: "upn",
    label: "E-postadress",
  },
  {
    type: "string",
    attribute: "samAccountName",
    label: "Användarnamn",
  },
  {
    type: "string",
    attribute: "class",
    label: "Klass",
  },
];

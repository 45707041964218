<template>
  <div class="content">
    <div class="col-12">
      <div class="mb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" v-on:click="closeComponent()">Enheter</a>
            </li>
            <li class="breadcrumb-item">Hämta PUK-kod</li>
            <li class="breadcrumb-item active">Resultat</li>
          </ol>
        </nav>
      </div>
      <div v-show="assignQueue.length != 0">
        <div class="progress">
          <div class="progress-bar" role="progressbar" :style="progressBarStyle"></div>
        </div>
        <center>
          <p>{{ progressText }}</p>
        </center>
      </div>
      <table-paginated tableId="checkQueueTablev1" :tableData="assignResult" :columnConfig="resultColumnConfig" />
    </div>
  </div>
</template>

<script>
import TablePaginated from "../../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../../utils/columnConfig/columnConfigHandler.js";
import TabBar from "../../../components/tabBar/TabBar.vue";
import MultiSelectFilter from "../../../components/filter/MultiSelectFilter.vue";

export default {
  name: "DevicesGetPuk",
  components: {
    TablePaginated,
    TabBar,
    MultiSelectFilter,
  },
  props: {
    selectedDevices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      displayContent: "main",
      assignQueue: [],
      assignQueueStartLength: 0,
      assignResult: [],
    };
  },
  methods: {
    closeComponent() {
      this.$emit("event", null);
    },
    checkQueue() {
      this.selectedDevices.forEach((selectedDevice) => {
        this.assignQueue.push({
          type: "queue",
          device: selectedDevice,
        });
      });
      this.assignQueueStartLength = this.assignQueue.length;
      this.runAssignQueue();
    },
    runAssignQueue() {
      if (this.assignQueue.length != 0) {
        this.$http.get(`/api/ustart/device/${this.assignQueue[0].device.platformCombinedId}/puk`).then((result) => {
          this.assignResult.unshift({
            email: this.assignQueue[0].device.email,
            serialNumber: this.assignQueue[0].device.platformCombinedSerialNumber,
            result: result.data.puk,
          });
          this.assignQueue.shift();
          this.$nextTick(() => {
            this.runAssignQueue();
          });
        });
      }
    },
  },
  computed: {
    resultFilterItems() {
      var queueItems = [];
      this.assignResult.forEach((item) => {
        item.result.forEach((item2) => {
          queueItems.push(item2);
        });
      });

      var uniqueQueueItems = [...new Set(queueItems)];
      var result = [];
      uniqueQueueItems.forEach((item) => {
        result.push({ label: item, value: item });
      });
      return result;
    },
    resultColumnConfig() {
      return [
        {
          type: "string",
          attribute: "email",
          label: "Användare",
        },
        {
          type: "string",
          attribute: "serialNumber",
          label: "Serienummer",
        },
        {
          type: "string",
          attribute: "result",
          label: "PUK-kod",
        },
      ];
    },
    progressBarStyle() {
      var width = Math.floor(((this.assignQueueStartLength - this.assignQueue.length) / this.assignQueueStartLength) * 100);
      return {
        width: `${width}%`,
      };
    },
    progressText() {
      var at = this.assignQueueStartLength - this.assignQueue.length + 1;
      var total = this.assignQueueStartLength;
      return `Hämtar ${at} av ${total}`;
    },
  },
  mounted() {
    this.checkQueue();
  },
};
</script>

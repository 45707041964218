const CATEGORIES = {
  DEVICES: "Enheter",
  APPS: "Applikationer",
  CONFIGURATION: "Inställningar",
};

const devicesHelpItems = {
  category: CATEGORIES.DEVICES,
  items: [
    {
      header: "Varför dyker enheter inte upp i Ustart3?",
      text:
        "Om en registrerad enhet inte blir synlig i ustart3 beror det med största sannolikhet på att användaren som registrerade enheten inte har rätt primär verksamhet. För att se en användares primära verksamhet kan man använda rapporten AD-1 Enskilt användarkonto på https://rapport.edu.stockholm.se.",
    },
    {
      header: "Enheten får inte begränsningar eller self service direkt efter registrering",
      text: `Om en enhet inte får begränsningar eller self service direkt efter registrering kan det hjälpa att göra en "Checka in" via enhetsöversikten eller enhetsdetaljer. Tänk på att enheten behöver vara upplåst när det görs.`,
    },
    {
      header: "Varför går det inte att fabriksåterställa flera enheter samtidigt?",
      text:
        "Om det vore möjligt att markera flera enheter samtidigt för fabriksåterställning finns det en risk att en större mängd enheter skulle kunna fabriksåterställas av misstag. Det är främst en försiktighetsåtgärd för att det inte ska vara möjligt att göra ett sådant misstag som kan få stora konsekvenser.",
    },
  ],
};

const appHelpItems = {
  category: CATEGORIES.APPS,
  items: [
    {
      header: "När blir nya applikationer och licenser tillgängliga i JAMF och Ustart3?",
      text:
        "Under den första delen av piloten kommer nya applikationer att synkroniseras manuellt för att processen ska kunna övervakas och utvärderas. Den manuella synkroniseringen kommer att utföras i slutet av varje arbetsdag. När processen anses vara stabil och fungerande kommer den att schemaläggas till att synkronisera 08.00 samt 13.00 varje dag. Anledningen till att applikationer endast kommer synrkoniseras två gånger per dygn beror på att det är resurskrävande process och bör inte göras för ofta. Synkronisering av nya licenser för befintliga applikationer hanteras automatiskt av JAMF, hur ofta det sker beror på hur belastat JAMF är och kan variera.",
    },
    {
      header: "Hur fungerar licenshanteringen i JAMF?",
      text:
        "JAMF reserverar licenser vid tilldelning. Tilldelas en applikation till 15 enheter kommer antalet använda licenser vara 15 oavsett om applikationen har installerats via self service eller ej. När en tilldelning tas bort kommer applikationen att avinstalleras och licensen frisläpps direkt.",
    },
    {
      header: "Vad är centrala applikationer?",
      text:
        "Centrala applikationer är applikationer som alltid finns tillgängliga i self service som du inte behöver köpa in själv. Det är applikationer som används av många verksamheter inom Stockholms stad, exempelvis alla office applikationer.",
    },
    {
      header: "Hur fungerar manuell tilldelning av applikationer i Ustart3?",
      text: `Markera enheter i enhetsöversikten och välj "Tilldela/Ta bort applikationer" i sidomenyn "Hantera markerade enheter". Markera de applikationer som ska tilldelas/tas bort och välj "Tilldela/Ta bort" i sidomenyn. I resultatet visas per enhet och per applikation om tilldelningen/borttagningen lyckades eller misslyckades.`,
    },
    {
      header: "Hur fungerar automatisk tilldelning av applikationer i Ustart3?",
      text: `Gå till applikationsdetaljer för en applikation i applikationsöversikten. Bocka i checkrutan "Hela verksamheten" i sidomenyn "Tilldela automatiskt till". Applikationen kommer nu automatiskt tilldelas till alla befintliga enheter samt nya enheter som registreras.`,
    },
  ],
};

const configurationHelpItems = {
  category: CATEGORIES.CONFIGURATION,
  items: [
    {
      header: "Vad är skillnaden på dynamiska och statiska inställningar?",
      text:
        "Dynamiska inställningar tilldelas automatiskt till enheter baserat på regeler och kan inte tas bort. Statiska inställningar kan tilldelas och tas bort via ustart3.",
    },
  ],
};

export default {
  CATEGORIES: CATEGORIES,
  items: [devicesHelpItems, appHelpItems, configurationHelpItems],
};

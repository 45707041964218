<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" :header="headerText" :hideFilter="true" :isSubMenu="true" @linkEvent="linkEvent" />
    <div class="content content-sub-sidebar-present">
      <div class="mb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" v-on:click="closeComponent()">Enheter</a>
            </li>
            <li v-show="displayContent == 'main'" class="breadcrumb-item active">Återställ</li>
            <li v-show="displayContent == 'result'" class="breadcrumb-item">Återställ</li>
            <li v-show="displayContent == 'result'" class="breadcrumb-item active">Resultat</li>
          </ol>
        </nav>
      </div>
      <div v-show="displayContent == 'result'">
        <div class="col-12">
          <div v-show="assignQueue.length != 0">
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="progressBarStyle"></div>
            </div>
            <center>
              <p>{{ progressText }}</p>
            </center>
          </div>
          <table-paginated tableId="resetDevicesResultTablev1" :tableData="assignResult" :columnConfig="resultColumnConfig" />
        </div>
      </div>
      <div v-show="displayContent == 'main'">
        <div class="col-12">
          <div class="alert alert-warning" role="alert">
            De resurser du väljer nedan kommer att tas bort från enheterna.
          </div>
          <div class="form-check form-check" v-for="(resetDeviceItem, index) in resetDeviceItems" v-bind:key="index">
            <input class="form-check-input" type="checkbox" :id="resetDeviceItem" :value="resetDeviceItem" v-model="selectedResetDeviceItems" />
            <label class="form-check-label" :for="resetDeviceItem">{{ resetDeviceItem }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TablePaginated from "../../../components/tables/tablePaginated/TablePaginated.vue";
import Sidemenu from "../../../components/sidemenu/Sidemenu.vue";

const subMenuEvents = {
  ACTION: "ACTION",
};

export default {
  name: "DevicesReset",
  components: {
    TablePaginated,
    Sidemenu,
  },
  props: {
    selectedDevices: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: "Assign",
    },
  },
  data() {
    return {
      displayContent: "main",
      assignQueue: [],
      assignQueueStartLength: 0,
      assignResult: [],
      resetDeviceItems: ["Appar", "Restriktioner", "Enhetsgrupper", "Alias", "Kommentar"],
      selectedResetDeviceItems: [],
    };
  },
  methods: {
    closeComponent() {
      this.$emit("event", null);
    },
    reset() {
      this.selectedDevices.forEach((selectedDevice) => {
        this.assignQueue.push({
          device: selectedDevice,
        });
      });
      this.displayContent = "result";
      this.assignQueueStartLength = this.assignQueue.length;
      this.runAssignQueue();
    },
    runAssignQueue() {
      if (this.assignQueue.length != 0) {
        this.$http.post(`/api/ustart/device/${this.assignQueue[0].device.platformCombinedId}/reset`, this.selectedResetDeviceItems).then((result) => {
          this.assignResult.unshift({
            email: this.assignQueue[0].device.email,
            serialNumber: this.assignQueue[0].device.platformCombinedSerialNumber,
            result: result.data.result,
          });
          this.assignQueue.shift();
          this.$nextTick(() => {
            this.runAssignQueue();
          });
        });
      }
    },
    linkEvent(data) {
      if (data == subMenuEvents.ACTION) {
        this.reset();
      }
    },
  },
  computed: {
    anySelectedResets() {
      return this.selectedResetDeviceItems.length > 0;
    },
    sideMenuItems() {
      if (this.displayContent == "main") {
        return [
          { type: "header", label: "Återställ" },
          { type: "link", iconClass: "fas fa-bomb", label: "Återställ", event: subMenuEvents.ACTION, badge: null, disabled: !this.anySelectedResets },
        ];
      } else {
        return [];
      }
    },
    resultColumnConfig() {
      return [
        {
          type: "string",
          attribute: "email",
          label: "Användare",
        },
        {
          type: "string",
          attribute: "serialNumber",
          label: "Serienummer",
        },
        {
          type: "string",
          attribute: "result",
          label: "Resultat",
        },
      ];
    },
    progressBarStyle() {
      var width = Math.floor(((this.assignQueueStartLength - this.assignQueue.length) / this.assignQueueStartLength) * 100);
      return {
        width: `${width}%`,
      };
    },
    progressText() {
      var at = this.assignQueueStartLength - this.assignQueue.length + 1;
      var total = this.assignQueueStartLength;
      return `Återställer ${at} av ${total}`;
    },
  },
};
</script>

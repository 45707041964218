<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Appar" :isSubMenu="true" />
    <div class="content content-sub-sidebar-present">
      <query-param-sync v-model="queryParameters" />

      <div class="col-12">
        <!-- <div class="alert alert-warning" role="alert" v-if="informationClassificationOnGoingCount > 0">
          <strong>OBS!</strong> Du har {{ informationClassificationOnGoingCount }} st appar som saknar infoklassning, du kan lägga ett önskemål för snabbare
          hantering på
          <a target="_blanl" href="https://dl.stockholm.se">dl.stockholm.se</a>
        </div> -->

        <table-paginated
          ref="applicationTablePaginated"
          tableId="applicationsTableV5"
          backendPath="/api/ustart/applications"
          :columnConfig="applicationsColumnConfig"
          :parentFilters="filters"
          :overrideInternalFilters="queryParameters"
        />
      </div>
      <modal modalId="importAppsModal" header="Hämta nya appar">
        <div class="alert alert-warning" role="alert">
          <strong>OBS!</strong> Flödet för att hämta nya appar har ändrats för att minska risken för att appar behöver hämtas flera gånger. Detta medför att det
          nu tar ca 45 sekunder per applikation. Hämta gärna ett fåtal appar åt gången.
        </div>
        <loading v-if="isLoadingNonConfiguredApplications" />
        <div v-show="!isLoadingNonConfiguredApplications">
          <div v-show="nonConfiguredApplicationsExists">
            <table class="table mb-3">
              <tbody>
                <tr>
                  <th></th>
                  <th>App</th>
                  <th>Plattform</th>
                  <th>Licenser</th>
                </tr>
                <tr v-for="(nonConfiguredApplication, index) in nonConfiguredApplications" v-bind:key="`nonConfiguredApplicationLiItem` + index">
                  <td>
                    <input
                      v-if="!(nonConfiguredApplication.isCentral || nonConfiguredApplication.isBlocked)"
                      class="form-check-input"
                      type="checkbox"
                      :id="nonConfiguredApplication"
                      :value="nonConfiguredApplication"
                      v-model="selectedNonConfiguredApplication"
                    />
                  </td>
                  <td>
                    {{ nonConfiguredApplication.name }}<br />
                    <span v-if="nonConfiguredApplication.isCentral" style="color: #dc3545"><i>Appen är centraliserad och kan inte importeras</i></span>
                    <span v-if="nonConfiguredApplication.isBlocked" style="color: #dc3545"><i>Appen tillåts inte och kan inte importeras</i></span>
                  </td>
                  <td>{{ nonConfiguredApplication.platform }}</td>
                  <td>{{ nonConfiguredApplication.totalLicenses }}</td>
                </tr>
              </tbody>
            </table>
            <a class="btn btn-primary" v-on:click="configureNonConfiguredApplications()" role="button">Hämta</a>
          </div>
          <div v-show="!nonConfiguredApplicationsExists">Det finns inga ny appar att hämta.</div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import ReuseableFilter from "../../abstractions/reuseableFilter/ReuseableFilter.vue";
import TabBar from "../../components/tabBar/TabBar.vue";
import Loading from "../../components/loading/Loading.vue";
import QueryParamSync from "../../components/queryParamSync/QueryParamSync.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";
import Modal from "../../components/modal/Modal.vue";

export default {
  name: "Applications",
  components: { TablePaginated, ReuseableFilter, TabBar, Loading, QueryParamSync, Sidemenu, Modal },
  data() {
    return {
      tabItems: ["Min verksamhet", "Centrala"],
      activeTabeItem: "Min verksamhet",
      filters: {
        platform: {
          selectedItems: [],
        },
      },
      queryParameters: {},
      isLoadingNonConfiguredApplications: true,
      nonConfiguredApplications: [],
      selectedNonConfiguredApplication: [],
      mounted: false,
    };
  },
  methods: {
    configureNonConfiguredApplications() {
      this.isLoadingNonConfiguredApplications = true;
      if (this.selectedNonConfiguredApplication.length != 0) {
        this.$http.post(`/api/ustart/application/${this.selectedNonConfiguredApplication[0].assetId}/configure`).then((result) => {
          this.selectedNonConfiguredApplication.shift();
          this.$nextTick(() => {
            this.configureNonConfiguredApplications();
          });
        });
      } else {
        location.reload();
      }
    },
  },
  computed: {
    applicationsColumnConfig() {
      return columnConfigHandler.getConfig("applications", []);
    },
    centralApplicationsColumnConfig() {
      return columnConfigHandler.getConfig("centralApplications", []);
    },
    nonConfiguredApplicationsExists() {
      return this.nonConfiguredApplications.length > 0;
    },
    nonConfiguredApplicationsCount() {
      return this.nonConfiguredApplications.length.toString();
    },
    sideMenuItems() {
      return [
        { type: "header", label: "Appar" },
        {
          type: "link",
          iconClass: "fab fa-app-store",
          label: "Synka nya VPP-appar",
          modal: "importAppsModal",
          badge: { class: "badge bg-success", label: this.nonConfiguredApplicationsCount },
        },
      ];
    },
    //TODO: Ugly hack, should not treat $refs as reactive even though it actually is, only works after mount
    informationClassificationOnGoingCount() {
      if (this.mounted) {
        try {
          return this.$refs.applicationTablePaginated.tableDataInternal.filter((item) => item.informationClassificationStatus == "Klassning krävs").length;
        } catch (err) {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.$http.get("/api/ustart/applications/nonConfigured").then((result) => {
      this.nonConfiguredApplications = result.data;
      this.nonConfiguredApplications.forEach((item) => (item.selected = true));
      this.isLoadingNonConfiguredApplications = false;
    });
    this.mounted = true;
  },
};
</script>

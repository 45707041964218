exports.getConfig = function(name) {
  var configItem = configs.filter((config) => config.configName == name);
  if (configItem.length == 1) {
    return configItem[0];
  } else {
    throw `Found ${configItem.length} configs for configName ${name}`;
  }
};

const FILTER_TYPES = {
  MultiSelectFilter: "MultiSelectFilter",
};
exports.FILTER_TYPES = FILTER_TYPES;

class MultiSelectFilter {
  constructor(name, items, itemsBackendPath, disableItemSearch) {
    this.name = name;
    this.items = items;
    this.itemsBackendPath = itemsBackendPath;
    this.disableItemSearch = disableItemSearch;
  }
}

const configs = [
  {
    configName: "accountType",
    type: FILTER_TYPES.MultiSelectFilter,
    props: new MultiSelectFilter(
      "Kontotyp",
      [
        { label: "Anställd", value: "employee" },
        { label: "Elev", value: "studenty" },
        { label: "Opersonlig", value: "nonpersonal" },
      ],
      null,
      false,
    ),
  },
  {
    configName: "applicationPlattform",
    type: FILTER_TYPES.MultiSelectFilter,
    props: new MultiSelectFilter(
      "Plattform",
      [
        { label: "iOS", value: "iOS" },
        { label: "macOS", value: "macOS" },
      ],
      null,
      false,
    ),
  },
  {
    configName: "class",
    type: FILTER_TYPES.MultiSelectFilter,
    props: new MultiSelectFilter("Klass", null, "/api/ustart/mockup/data?data=classesFilter&sleep=4000", false),
  },
  {
    configName: "appClassificationStatuses",
    type: FILTER_TYPES.MultiSelectFilter,
    props: new MultiSelectFilter("Status", null, "/api/ustart/mockup/data?data=appClassificationStatusesFilter", false),
  },
];

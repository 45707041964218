module.exports = [
  {
    type: "string",
    attribute: "email",
    label: "Användare",
  },
  {
    type: "string",
    attribute: "alias",
    label: "Alias",
    visible: false,
  },
  {
    type: "string",
    attribute: "serialNumber",
    label: "Serienummer",
  },
  {
    type: "string",
    attribute: "model",
    label: "Modell",
    visible: false,
  },
  {
    type: "string",
    attribute: "platform",
    label: "Plattform",
    visible: false,
  },
  {
    type: "string",
    attribute: "osVersion",
    label: "Operativsystem",
  },
  {
    type: "string",
    attribute: "isDep",
    label: "DEP",
    visible: false,
  },
  {
    type: "string",
    attribute: "lostMode",
    label: "Lost mode",
    visible: false,
  },
  {
    type: "list",
    attribute: "groups",
    label: "Enhetsgrupper",
    visible: false,
  },
  {
    type: "list",
    attribute: "settings",
    label: "Restriktioner",
    visible: false,
  },
  {
    type: "string",
    attribute: "diskSizeMb",
    label: "Utrymme",
    visible: false,
  },
  {
    type: "string",
    attribute: "diskAvailableMb",
    label: "Ledigt utrymme",
    visible: false,
  },
  {
    type: "string",
    attribute: "batteryLevel",
    label: "Batterinivå",
    visible: false,
  },
  {
    type: "string",
    attribute: "comment",
    label: "Kommentar",
    visible: false,
  },
  {
    type: "string",
    attribute: "lastCheckin",
    label: "Senast ansluten",
  },
  {
    type: "string",
    attribute: "enrolled",
    label: "Registrerad",
    visible: false,
  },
];

<template>
  <div>
    <loading v-if="loading" class="content" />
    <div v-if="!loading" class="content">
      <tab-bar :items="tabItems" v-model="activeTabItem" />
      <br />
      <table-paginated
        v-show="activeTabItem == 'iOS'"
        :tableData="configurationPackages.filter((item) => item.Platform == 'iOS')"
        tableId="restrictionsPackagesTableiOSV2"
        :columnConfig="[
          {
            type: 'string',
            attribute: 'Name',
            label: 'Restriktion',
          },
          {
            type: 'string',
            attribute: 'Description',
            label: 'Beskrivning',
          },
          {
            type: 'list',
            attribute: 'configurationProfiles',
            listObjectAttribute: 'name',
            label: 'Restriktioner',
          },
        ]"
      />
      <table-paginated
        v-show="activeTabItem == 'macOS'"
        :tableData="configurationPackages.filter((item) => item.Platform == 'macOS')"
        tableId="restrictionsPackagesTablemacOSV1"
        :columnConfig="[
          {
            type: 'string',
            attribute: 'Name',
            label: 'Restriktion',
          },
          {
            type: 'string',
            attribute: 'Description',
            label: 'Beskrivning',
          },
          {
            type: 'list',
            attribute: 'configurationProfiles',
            listObjectAttribute: 'name',
            label: 'Restriktioner',
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading/Loading.vue";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import TabBar from "../../components/tabBar/TabBar.vue";

export default {
  name: "RestrictionPackages",
  components: { Loading, TabBar, TablePaginated },
  data() {
    return {
      loading: true,
      configurationPackages: [],
      tabItems: ["iOS", "macOS"],
      activeTabItem: "iOS",
    };
  },
  methods: {},
  computed: {},
  mounted() {
    this.$http.get("/api/ustart/package/configurationPackages").then((result) => {
      this.configurationPackages = result.data;
      this.loading = false;
    });
  },
};
</script>

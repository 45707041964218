<!--
  Description:
  QueryParamSync is a one way binding component that will bind given query parameters to v-model
  Query parameter values will be url decoded and JSON strings will be parsed to objects
  
  Props:
  - value: used for v-model.

  Output:
  url?param1=value1&param2=value2 will give v-model { param1: "value1", param2: "value2" }

  Usage examples:
  <query-param-sync v-model="queryParams" />
-->

<template>
  <div v-if="false" />
</template>

<script>
import _ from "lodash";

export default {
  name: "QueryParamSync",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    var queryParams = _.cloneDeep(this.$route.query);
    for (var attributeName in queryParams) {
      queryParams[attributeName] = decodeURIComponent(queryParams[attributeName]).toString();
      try {
        queryParams[attributeName] = JSON.parse(queryParams[attributeName]).toString();
      } catch (err) {}
    }
    this.$emit("input", queryParams);
  },
};
</script>

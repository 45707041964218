<template>
  <div>
    <div v-show="displayContentMain">
      <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Kontohantering" :isSubMenu="true" @linkEvent="linkEvent" />
      <div class="content content-sub-sidebar-present">
        <div class="row">
          <div class="col-12">
            <table-paginated
              tableId="daisyUserManagementUsersV1"
              backendPath="/api/ustart/daisy/report/users"
              :columnConfig="daisyUserReportColumnConfig"
              :enableSelect="true"
              v-model="selectedUsers"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="displayContentManageRoles">
      <DaisyUserManagementAssignRoles :selectedUsers="selectedUsers" :mode="assignMode" @event="setDisplayMain" />
    </div>
    <div v-if="displayContentSetPassword">
      <DaisyUserManagementSetPassword :selectedUsers="selectedUsers" @event="setDisplayMain" />
    </div>
  </div>
</template>

<script>
const DISPLAY_CONTENT = {
  MAIN: "MAIN",
  MANAGE_ROLES: "MANAGE_ROLES",
  SET_PASSWORD: "SET_PASSWORD",
};

const SUB_MENU_EVENT = {
  ASSIGN_ROLES: "ASSIGN_ROLES",
  REMOVE_ROLES: "REMOVE_ROLES",
  SET_PASSWORD: "SET_PASSWORD",
};

const ASSIGN_MODE = {
  ASSIGN: "ASSIGN",
  REMOVE: "REMOVE",
};
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";
import DaisyUserManagementAssignRoles from "./DaisyUserManagementAssignRoles.vue";
import DaisyUserManagementSetPassword from "./DaisyUserManagementSetPassword.vue";

export default {
  name: "DaisyUserManagement",
  components: { TablePaginated, Sidemenu, DaisyUserManagementAssignRoles, DaisyUserManagementSetPassword },
  data() {
    return {
      selectedUsers: [],
      displayContent: DISPLAY_CONTENT.MAIN,
      assignMode: ASSIGN_MODE.ASSIGN,
    };
  },
  methods: {
    linkEvent(data) {
      if (data == SUB_MENU_EVENT.ASSIGN_ROLES) {
        this.displayContent = DISPLAY_CONTENT.MANAGE_ROLES;
        this.assignMode = ASSIGN_MODE.ASSIGN;
      } else if (data == SUB_MENU_EVENT.REMOVE_ROLES) {
        this.displayContent = DISPLAY_CONTENT.MANAGE_ROLES;
        this.assignMode = ASSIGN_MODE.REMOVE;
      } else if (data == SUB_MENU_EVENT.SET_PASSWORD) {
        this.displayContent = DISPLAY_CONTENT.SET_PASSWORD;
      }
    },
    setDisplayMain(data) {
      this.displayContent = DISPLAY_CONTENT.MAIN;
    },
  },
  computed: {
    daisyUserReportColumnConfig() {
      return columnConfigHandler.getConfig("daisyUserReport", []);
    },
    anySelectedUsers() {
      return this.selectedUsers.length > 0;
    },
    sideMenuItems() {
      return [
        { type: "header", label: "Hantera konton" },
        {
          type: "link",
          iconClass: "fas fa-user-cog",
          label: "Tilldela roller",
          event: SUB_MENU_EVENT.ASSIGN_ROLES,
          badge: null,
          disabled: !this.anySelectedUsers,
        },
        {
          type: "link",
          iconClass: "fas fa-user-cog",
          label: "Ta bort roller",
          event: SUB_MENU_EVENT.REMOVE_ROLES,
          badge: null,
          disabled: !this.anySelectedUsers,
        },
        {
          type: "link",
          iconClass: "fas fa-unlock",
          label: "Återställ lösenord",
          event: SUB_MENU_EVENT.SET_PASSWORD,
          badge: null,
          disabled: !this.anySelectedUsers,
        },
      ];
    },
    displayContentMain() {
      return this.displayContent == DISPLAY_CONTENT.MAIN;
    },
    displayContentManageRoles() {
      return this.displayContent == DISPLAY_CONTENT.MANAGE_ROLES;
    },
    displayContentSetPassword() {
      return this.displayContent == DISPLAY_CONTENT.SET_PASSWORD;
    },
  },
  mounted() {},
};
</script>

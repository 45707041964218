<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <tab-bar :items="tabItems" v-model="activeTabItem" />
        <br />
        <table-paginated
          v-show="activeTabItem == 'Administratörer'"
          tableId="ustartAdminsv2"
          backendPath="/api/ustart/admin/users/ustartAdmins"
          :parentFilters="filters"
          :columnConfig="ustartAdminsColumnConfig"
        />
        <table-paginated
          v-show="activeTabItem == 'Globala administratörer'"
          tableId="ustartGlobalAdminsv2"
          backendPath="/api/ustart/admin/users/ustartGlobalAdmins"
          :parentFilters="filters"
          :columnConfig="ustartAdminsColumnConfig"
        />
        <table-paginated
          v-show="activeTabItem == 'Infoklassning administratörer'"
          tableId="infoClassificationAdminv2"
          backendPath="/api/ustart/admin/users/infoClassificationAdmins"
          :parentFilters="filters"
          :columnConfig="ustartAdminsColumnConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import ReuseableFilter from "../../abstractions/reuseableFilter/ReuseableFilter.vue";
import MultiSelectFilter from "../../components/filter/MultiSelectFilter.vue";
import TabBar from "../../components/tabBar/TabBar.vue";

export default {
  name: "AdminUstartAdmins",
  components: { TablePaginated, ReuseableFilter, MultiSelectFilter, TabBar },
  data() {
    return {
      filters: {
        sthlmVerksamhetsId: {
          selectedItems: [],
        },
      },
      tabItems: ["Administratörer", "Globala administratörer", "Infoklassning administratörer"],
      activeTabItem: "Administratörer",
    };
  },
  methods: {},
  computed: {
    ustartAdminsColumnConfig() {
      return columnConfigHandler.getConfig("ustartAdmins", []);
    },
  },
  mounted() {},
};
</script>

<template>
  <div>
    <query-param-sync v-model="queryParameters" />
    <div v-show="displayMain">
      <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Enheter" :isSubMenu="true" @linkEvent="linkEvent" />
      <div class="content content-sub-sidebar-present">
        <notification message="Enheterna synkroniserades" v-model="notificationCompltedBulkBlankPush" />
        <table-paginated
          v-if="displayDevicesTable"
          tableId="deviceTableV15"
          backendPath="/api/ustart/devices"
          :columnConfig="devicesColumnConfig"
          :enableSelect="true"
          :overrideInternalFilters="queryParameters"
          v-model="selectedDevices"
        />
      </div>
    </div>
    <div v-if="displayAssignApplications">
      <devices-assign-applications :selectedDevices="selectedDevices" :mode="assignApplicationsMode" @event="setDisplayMain" />
    </div>
    <div v-if="displayAssignGroups">
      <devices-assign-groups :selectedDevices="selectedDevices" :mode="assignGroupsMode" @event="setDisplayMainWithReload" />
    </div>
    <div v-if="displayAssignSettings">
      <devices-assign-settings :selectedDevices="selectedDevices" :mode="assignSettingsMode" @event="setDisplayMain" />
    </div>
    <div v-if="displayAssignSettingPackages">
      <devices-assign-setting-packages :selectedDevices="selectedDevices" :mode="assignSettingPackagesMode" @event="setDisplayMain" />
    </div>
    <div v-if="displayCheckQueue">
      <devices-check-queue :selectedDevices="selectedDevices" @event="setDisplayMain" />
    </div>
    <div v-if="displayGetPuk">
      <devices-get-puk :selectedDevices="selectedDevices" @event="setDisplayMain" />
    </div>

    <modal modalId="selectedDevicesHelpModal" header="Markerade enheter">
      <p>
        Markera en eller flera enheter i tabellen över enheter. Välj sedan något av manyvalen under <i>Markerad enheter</i> för att utföra önskad handling för
        de markerade enheterna. I nästa steg kommer du att få välja, om du exempelvis valt att tilldela appar, vilka appar som du vill tilldela till de
        markerade enheterna.
      </p>
    </modal>
  </div>
</template>

<script>
import * as routerHandler from "../../utils/router/routerHandler.js";
import ReuseableFilter from "../../abstractions/reuseableFilter/ReuseableFilter.vue";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import DevicesAssignSettings from "./components/DevicesAssignSettings.vue";
import DevicesAssignApplications from "./components/DevicesAssignApplications.vue";
import DevicesCheckQueue from "./components/DevicesCheckQueue.vue";
import DevicesAssignSettingPackages from "./components/DevicesAssignSettingPackages.vue";
import DevicesAssignGroups from "./components/DevicesAssignGroups.vue";
import DevicesReset from "./components/DevicesReset.vue";
import DevicesGetPuk from "./components/DevicesGetPuk.vue";
import Notification from "../../components/notification/Notification.vue";
import QueryParamSync from "../../components/queryParamSync/QueryParamSync.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";
import Modal from "../../components/modal/Modal.vue";

const subMenuEvents = {
  CHECK_IN: "CHECK_IN",
  ASSIGN_APPS: "ASSIGN_APPS",
  REMOVE_APPS: "REMOVE_APPS",
  ASSIGN_GROUPS: "ASSIGN_GROUPS",
  REMOVE_GROUPS: "REMOVE_GROUPS",
  ASSIGN_SETTINGS: "ASSIGN_SETTINGS",
  REMOVE_SETTINGS: "REMOVE_SETTINGS",
  ASSIGN_SETTING_PACKAGES: "ASSIGN_SETTING_PACKAGES",
  REMOVE_SETTING_PACKAGES: "REMOVE_SETTING_PACKAGES",
  RESET: "RESET",
  CHECK_QUEUE: "CHECK_QUEUE",
  GET_PUK: "GET_PUK",
};

export default {
  name: "Devices",
  components: {
    ReuseableFilter,
    TablePaginated,
    DevicesAssignSettings,
    DevicesAssignApplications,
    DevicesCheckQueue,
    DevicesAssignGroups,
    DevicesAssignSettingPackages,
    DevicesReset,
    Notification,
    QueryParamSync,
    Sidemenu,
    Modal,
    DevicesGetPuk,
  },
  data() {
    return {
      displayDevicesTable: true,
      displayContent: "main",
      filters: {},
      devices: [],
      selectedDevices: [],
      assignApplicationsMode: "Assign",
      assignGroupsMode: "Assign",
      assignSettingsMode: "Assign",
      assignSettingPackagesMode: "Assign",
      loadingBulkBlankPush: false,
      notificationCompltedBulkBlankPush: false,
      queryParameters: {},
    };
  },
  methods: {
    setDisplayMain(data) {
      this.displayContent = "main";
    },
    setDisplayMainWithReload(data) {
      this.displayContent = "main";
      this.displayDevicesTable = false;
      this.$nextTick(() => {
        this.displayDevicesTable = true;
      });
    },
    sendBulkBlankPush() {
      if (this.selectedDevices.length > 0) {
        this.loadingBulkBlankPush = true;
        this.$http
          .post(`/api/ustart/devices/sendBulkBlankPush`, {
            platformCombinedIds: this.selectedDevices.map((item) => item.platformCombinedId),
          })
          .then((result) => {
            this.notificationCompltedBulkBlankPush = true;
            this.loadingBulkBlankPush = false;
          });
      }
    },
    linkEvent(data) {
      if (data == subMenuEvents.CHECK_IN) {
        this.sendBulkBlankPush();
      } else if (data == subMenuEvents.ASSIGN_APPS) {
        this.displayContent = "assignApplications";
        this.assignApplicationsMode = "Assign";
      } else if (data == subMenuEvents.REMOVE_APPS) {
        this.displayContent = "assignApplications";
        this.assignApplicationsMode = "Remove";
      } else if (data == subMenuEvents.ASSIGN_GROUPS) {
        this.displayContent = "assignGroups";
        this.assignGroupsMode = "Assign";
      } else if (data == subMenuEvents.REMOVE_GROUPS) {
        this.displayContent = "assignGroups";
        this.assignGroupsMode = "Remove";
      } else if (data == subMenuEvents.ASSIGN_SETTINGS) {
        this.displayContent = "assignSettings";
        this.assignSettingsMode = "Assign";
      } else if (data == subMenuEvents.REMOVE_SETTINGS) {
        this.displayContent = "assignSettings";
        this.assignSettingsMode = "Remove";
      } else if (data == subMenuEvents.ASSIGN_SETTING_PACKAGES) {
        this.displayContent = "assignSettingPackages";
        this.assignSettingPackagesMode = "Assign";
      } else if (data == subMenuEvents.REMOVE_SETTING_PACKAGES) {
        this.displayContent = "assignSettingPackages";
        this.assignSettingPackagesMode = "Remove";
      } else if (data == subMenuEvents.CHECK_QUEUE) {
        this.displayContent = "checkQueue";
      } else if (data == subMenuEvents.RESET) {
        this.displayContent = "reset";
      } else if (data == subMenuEvents.GET_PUK) {
        this.displayContent = "getPuk";
      }
    },
  },
  computed: {
    anySelectedDevices() {
      return this.selectedDevices.length > 0;
    },
    sideMenuItems() {
      return [
        { type: "header", label: "Markerade enheter", helpModal: "selectedDevicesHelpModal" },
        {
          type: "link",
          iconClass: "fa fa-tachometer-alt",
          label: "Synkronisera",
          event: subMenuEvents.CHECK_IN,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fab fa-app-store",
          label: "Tilldela appar",
          event: subMenuEvents.ASSIGN_APPS,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fab fa-app-store",
          label: "Ta bort appar",
          event: subMenuEvents.REMOVE_APPS,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fa fa-users",
          label: "Tilldela enhetsgrupper",
          event: subMenuEvents.ASSIGN_GROUPS,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fa fa-users",
          label: "Ta bort enhetsgrupper",
          event: subMenuEvents.REMOVE_GROUPS,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fas fa-cog",
          label: "Tilldela restriktioner",
          event: subMenuEvents.ASSIGN_SETTINGS,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fas fa-cog",
          label: "Ta bort restriktioner",
          event: subMenuEvents.REMOVE_SETTINGS,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fas fa-cog",
          label: "Tilldela restriktionsnivåer",
          event: subMenuEvents.ASSIGN_SETTING_PACKAGES,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fas fa-cog",
          label: "Ta bort restriktionsnivåer",
          event: subMenuEvents.REMOVE_SETTING_PACKAGES,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fas fa-bomb",
          label: "Återställ",
          event: subMenuEvents.RESET,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fas fa-paper-plane",
          label: "Kontrollera kö",
          event: subMenuEvents.CHECK_QUEUE,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
        {
          type: "link",
          iconClass: "fas fa-info-circle",
          label: "Hämta PUK-kod",
          event: subMenuEvents.GET_PUK,
          badge: null,
          disabled: !this.anySelectedDevices,
        },
      ];
    },
    devicesColumnConfig() {
      return columnConfigHandler.getConfig("devices", []);
    },
    displayMain() {
      return this.displayContent == "main";
    },
    displayAssignSettings() {
      return this.displayContent == "assignSettings";
    },
    displayAssignSettingPackages() {
      return this.displayContent == "assignSettingPackages";
    },
    displayAssignApplications() {
      return this.displayContent == "assignApplications";
    },
    displayCheckQueue() {
      return this.displayContent == "checkQueue";
    },
    displayAssignGroups() {
      return this.displayContent == "assignGroups";
    },
    displayReset() {
      return this.displayContent == "reset";
    },
    displayGetPuk() {
      return this.displayContent == "getPuk";
    },
  },
  mounted() {},
};
</script>

export const getColumnConfig = (tableId) => (localStorage.getItem(tableId) == null ? null : JSON.parse(localStorage.getItem(tableId)));
export const setColumnConfig = (tableId, columnConfig) => localStorage.setItem(tableId, JSON.stringify(columnConfig));

export const getRowsPerPage = (tableId) =>
  localStorage.getItem(tableId + "rowsPerPage") == null ? null : parseInt(localStorage.getItem(tableId + "rowsPerPage"));
export const setRowsPerPage = (tableId, rowsPerPage) => localStorage.setItem(tableId + "rowsPerPage", rowsPerPage.toString());

export const getTableFontSize = (tableId) =>
  localStorage.getItem(tableId + "tableFontSize") == null ? null : parseInt(localStorage.getItem(tableId + "tableFontSize"));
export const setTableFontSize = (tableId, rowsPerPage) => localStorage.setItem(tableId + "tableFontSize", rowsPerPage.toString());

<template>
  <div>
    <template v-if="configItem.type == configTypes.MultiSelectFilter">
      <multi-select-filter v-bind="configItem.props" v-model="modelBinding" />
    </template>
  </div>
</template>

<script>
import * as reuseableFilterConfig from "./reuseableFilterConfig.js";
import MultiSelectFilter from "../../components/filter/MultiSelectFilter.vue";

export default {
  name: "ReuseableFilter",
  components: { MultiSelectFilter },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modelBinding: [],
    };
  },
  watch: {
    modelBinding() {
      this.$emit("input", this.modelBinding);
    },
  },
  computed: {
    configTypes() {
      return reuseableFilterConfig.FILTER_TYPES;
    },
    configItem() {
      return reuseableFilterConfig.getConfig(this.name);
    },
  },
};
</script>

<template>
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="page-header">
      <h1>Hjälp</h1>
    </div>
    <div class="row">
      <div class="col-md-7 col-sm-12 col-xs-12">
        <tab-bar :items="categories" v-model="activeTabItem" />
        <br />
        <expandable-header v-for="(item, index) in activeCategoryItems" v-bind:key="index" :header="item.header" :text="item.text" />
      </div>

      <div class="col-md-4 col-sm-12 col-xs-12 pull-right">
        <div class="well">
          <h3>Så funkar det</h3>
          <p>Här hittar du information kan vara bra för dig som är administratör i Ustart3.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabBar from "../../components/tabBar/TabBar.vue";
import ExpandableHeader from "../../components/expandableHeader/ExpandableHeader.vue";
import helpConfig from "./helpConfig.js";

export default {
  name: "Help",
  components: { TabBar, ExpandableHeader },
  data() {
    return {
      activeTabItem: "",
    };
  },
  computed: {
    categories() {
      return Object.values(helpConfig.CATEGORIES);
    },
    activeCategoryItems() {
      try {
        return helpConfig.items.filter((item) => item.category == this.activeTabItem)[0].items;
      } catch (err) {
        return [];
      }
    },
  },
};
</script>

<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <table-paginated
          tableId="myDevicesTableV2"
          backendPath="/api/ustart/devices/myDevices"
          :columnConfig="myDevicesColumnConfig"
          :showFilters="false"
          :enableSelect="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";

export default {
  name: "MyDevices",
  components: { TablePaginated },
  data() {
    return {};
  },
  methods: {},
  computed: {
    myDevicesColumnConfig() {
      return columnConfigHandler.getConfig("myDevices", []);
    },
  },
  watch: {},
  mounted() {},
};
</script>

<template>
  <div class="content">
    <div class="row">
      <div class="col-md-10 col-sm-12 col-xs-12">
        <table-paginated tableId="adminLogsTablev2" :backendPath="logsBackendPath" :columnConfig="adminLogsColumnConfig" />
      </div>
      <div class="col-md-2 col-sm-12 col-xs-12" role="complementary">
        <div class="card">
          <div class="card-header">
            Tidsperiod
          </div>

          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radio1" id="radio2" value="2" v-model="filters.datePeriodDays" />
                <label class="form-check-label" for="radio2">
                  2 dagar tillbaka
                </label>
              </div>
            </li>
            <li class="list-group-item">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radio7" id="radio7" value="7" v-model="filters.datePeriodDays" />
                <label class="form-check-label" for="radio7">
                  7 dagar tillbaka
                </label>
              </div>
            </li>
            <li class="list-group-item">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radio30" id="radio30" value="30" v-model="filters.datePeriodDays" />
                <label class="form-check-label" for="radio30">
                  30 dagar tillbaka
                </label>
              </div>
            </li>
            <li class="list-group-item">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radio60" id="radio60" value="60" v-model="filters.datePeriodDays" />
                <label class="form-check-label" for="radio60">
                  60 dagar tillbaka
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";

export default {
  name: "AdminLogs",
  components: { TablePaginated },
  data() {
    return {
      chartData: [],
      filters: {
        datePeriodDays: 2,
      },
    };
  },
  methods: {},
  computed: {
    startDate() {
      return dayjs()
        .subtract(this.filters.datePeriodDays - 1, "day")
        .format("YYYY-MM-DD");
    },
    endDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    logsBackendPath() {
      return `/api/ustart/admin/logs?startDate=${this.startDate}&endDate=${this.endDate}`;
    },
    adminLogsColumnConfig() {
      return columnConfigHandler.getConfig("adminLogs", []);
    },
  },
  mounted() {},
};
</script>

<style></style>

var axios = require("axios");

export const redirectToAuthorize = () => {
  let nonce = Math.floor(Math.random() * 1000000000);
  let url = new URL(process.env.VUE_APP_IDPORTAL_OIDC_ENDPOINT_AUTHORIZE);
  url.searchParams.set("client_id", process.env.VUE_APP_IDPORTAL_OIDC_CLIENTID);
  url.searchParams.set("redirect_uri", process.env.VUE_APP_IDPORTAL_OIDC_RETURN_URI);
  url.searchParams.set("response_type", "id_token token");
  url.searchParams.set("scope", "openid");
  url.searchParams.set("nonce", nonce);
  //searchParams encodes space with + instead of %20, id-portal requires %20 encoding, howerver + seems to work on the 2nd try
  window.location = url.href.replace("id_token+token", "id_token%20token");
};

export const handleAuthenticateCallback = async (fullPath) => {
  let urlparser = new URL(fullPath, "http://dummydomainforparsing.local");
  urlparser.search = urlparser.hash.slice(1);
  let accessToken = urlparser.searchParams.get("access_token");
  let authenticateResult = await axios.get(`${process.env.VUE_APP_BACKEND}/api/authentication/authenticate`, {
    params: { accessToken },
  });
  let token = authenticateResult.data;
  return token;
};

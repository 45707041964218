<template>
  <div class="d-flex justify-content-center" style="margin-top: 15px; margin-bottom: 15px;">
    <clip-loader :loading="true" color="#333" size="35px" />
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "Loading",
  components: { ClipLoader },
};
</script>

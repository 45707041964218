<template>
  <div>
    <tab-bar class="mb-3" v-show="!insertMode" :items="tabItems" v-model="activeTabeItem" />

    <loading v-show="activeTabeItem == 'Klassning'" v-if="loading" />
    <div v-show="!loading && activeTabeItem == 'Klassning'">
      <notification message="Klassningen skapades!" v-model="notifications.inserted" />
      <notification message="Klassningen uppdaterades!" v-model="notifications.updated" />
      <div class="mb-4 col-lg-6 col-md-12">
        <label for="nameInput" class="form-label">Namn</label>
        <input type="text" class="form-control" id="nameInput" v-model="informationClassificationModel.name" />
      </div>
      <div class="mb-4 col-lg-6 col-md-12">
        <label for="itunesIdInput" class="form-label">ItunesId</label>
        <input type="text" class="form-control" id="itunesIdInput" v-model="informationClassificationModel.itunesId" />
      </div>
      <div class="mb-4">
        <label class="form-label">Plattform</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="iosRadio" id="iosRadio" value="iOS" v-model="informationClassificationModel.platform" />
          <label class="form-check-label" for="iosRadio">
            iOS
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="macosRadio" id="macosRadio" value="macOS" v-model="informationClassificationModel.platform" />
          <label class="form-check-label" for="macosRadio">
            macOS
          </label>
        </div>
      </div>
      <div class="mb-4">
        <label class="form-label">Klassning</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="godkandRadio" id="godkandRadio" value="Godkänd" v-model="informationClassificationModel.status" />
          <label class="form-check-label" for="godkandRadio">
            <span class="badge bg-success">Godkänd</span>
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="godkandForbehallRadio"
            id="godkandForbehallRadio"
            value="Godkänd med förbehåll"
            v-model="informationClassificationModel.status"
          />
          <label class="form-check-label" for="godkandForbehallRadio">
            <span class="badge bg-warning">Godkänd med förbehåll</span>
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="klassningPagarRadio"
            id="klassningPagarRadio"
            value="Klassning pågår"
            v-model="informationClassificationModel.status"
          />
          <label class="form-check-label" for="klassningPagarRadio">
            <span class="badge bg-classificationOngoing text-dark">Klassning pågår</span>
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="ejGodkandRadio"
            id="ejGodkandRadio"
            value="Ej godkänd"
            v-model="informationClassificationModel.status"
          />
          <label class="form-check-label" for="ejGodkandRadio">
            <span class="badge bg-danger">Ej godkänd</span>
          </label>
        </div>
      </div>

      <div class="mb-4 col-12">
        <label class="form-label">Kategori</label>
        <br />
        <div class="form-check form-check-inline" v-for="(categoryItem, index) in categoryItems" v-bind:key="index">
          <input class="form-check-input" type="checkbox" :id="categoryItem" :value="categoryItem" v-model="informationClassificationModel.categories" />
          <label class="form-check-label" :for="categoryItem">{{ categoryItem }}</label>
        </div>
      </div>

      <div class="mb-4">
        <label for="internalNoteInput" class="form-label"
          >Intern kommentar (<a href data-bs-toggle="modal" data-bs-target="#internalNoteHtmlModal" role="button">Förhandsgranska</a>)</label
        >

        <div class="mb-1 p-2" style="display: flex;">
          <i class="fas fa-link me-3" role="button" v-on:click="insertHtml('link', 'internalNote')"></i>
          <i class="fas fa-image me-3" role="button" v-on:click="insertHtml('image', 'internalNote')"></i>
          <i class="fas fa-list-ul me-3" role="button" v-on:click="insertHtml('list', 'internalNote')"></i>
          <i class="fas fa-bold me-3" role="button" v-on:click="insertHtml('bold', 'internalNote')"></i>
          <i class="fas fa-italic me-3" role="button" v-on:click="insertHtml('italic', 'internalNote')"></i>
          <i class="fas fa-hand-point-down me-3" role="button" v-on:click="insertHtml('br', 'internalNote')"></i>
        </div>
        <textarea ref="internalNote" class="form-control" id="internalNoteInput" rows="6" v-model="informationClassificationModel.internalNote"></textarea>
      </div>
      <div class="mb-3">
        <label for="externalNoteInput" class="form-label"
          >Extern kommentar (<a href data-bs-toggle="modal" data-bs-target="#externalNoteHtmlModal" role="button">Förhandsgranska</a>)</label
        >

        <div class="mb-1 p-2" style="display: flex;">
          <i class="fas fa-link me-3" role="button" v-on:click="insertHtml('link', 'externalNote')"></i>
          <i class="fas fa-image me-3" role="button" v-on:click="insertHtml('image', 'externalNote')"></i>
          <i class="fas fa-list-ul me-3" role="button" v-on:click="insertHtml('list', 'externalNote')"></i>
          <i class="fas fa-bold me-3" role="button" v-on:click="insertHtml('bold', 'externalNote')"></i>
          <i class="fas fa-italic me-3" role="button" v-on:click="insertHtml('italic', 'externalNote')"></i>
          <i class="fas fa-hand-point-down me-3" role="button" v-on:click="insertHtml('br', 'externalNote')"></i>
        </div>
        <textarea ref="externalNote" class="form-control" id="externalNoteInput" rows="6" v-model="informationClassificationModel.externalNote"></textarea>
      </div>
      <div class="mb-4">
        <button type="button" class="btn btn-primary me-2" v-on:click="upsertInformationClassification()">{{ upsertButtonLabel }}</button>
        <button v-if="!insertMode" type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteModal">
          Ta bort
        </button>
      </div>

      <modal modalId="internalNoteHtmlModal" header="Internt kommentar förhandsgranskning">
        <div v-html="informationClassificationModel.internalNote"></div>
      </modal>

      <modal modalId="externalNoteHtmlModal" header="Extern kommentar förhandsgranskning">
        <div v-html="informationClassificationModel.externalNote"></div>
      </modal>

      <modal modalId="deleteModal" header="Ta bort klassning">
        <p>Bekräfta borttagning av klassning.</p>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" v-on:click="deleteInformationClassification()">Ta bort</button>
      </modal>
    </div>

    <div v-if="activeTabeItem == 'Ändringar'">
      <table-paginated
        tableId="informationClassificationAuditLogsTableV2"
        :backendPath="`/api/ustart/informationclassification/${informationClassificationModel.id}/auditLogs`"
        :columnConfig="informationClassificationAuditLogColumnConfig"
      />
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/loading/Loading.vue";
import Notification from "../../../components/notification/Notification.vue";
import Modal from "../../../components/modal/Modal.vue";
import TabBar from "../../../components/tabBar/TabBar.vue";
import TablePaginated from "../../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../../utils/columnConfig/columnConfigHandler.js";

export default {
  name: "upsertInformationClassification",
  components: { Notification, Loading, Modal, TabBar, TablePaginated },
  props: {
    mode: {
      type: String,
      default: "insert",
    },
    updateObject: {
      type: Object,
      default: null,
    },
    insertObject: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      tabItems: ["Klassning", "Ändringar"],
      activeTabeItem: "Klassning",
      notifications: {
        inserted: false,
        updated: false,
      },
      informationClassificationModel: {
        name: "",
        itunesId: "",
        platform: "iOS",
        status: "Godkänd",
        categories: [],
        internalNote: "",
        externalNote: "",
      },
      categoryItems: [
        "Bild",
        "Biologi",
        "Distansundervisning",
        "Engelska",
        "Fysik",
        "Förberedelseklass",
        "Geografi",
        "Hem- och konsumentkunskap",
        "Historia",
        "Idrott och hälsa",
        "Kemi",
        "Kompensatoriska verktyg",
        "Matematik",
        "Moderna språk",
        "Musik",
        "Programmering",
        "Religionskunskap",
        "Samhällskunskap",
        "Slöjd",
        "Studieteknik",
        "Svenska",
        "Svenska som andra språk",
        "Särskola",
        "Teknik",
      ],
    };
  },
  methods: {
    insertHtml(type, target) {
      var html = "";
      if (type == "link") {
        html = '<a href="https://url" target="_blank">länk</a>';
      } else if (type == "image") {
        html = '<img src="https://url" style="height: 40px; width: auto;" />';
      } else if (type == "list") {
        html = "\n<ul>\n<li>item1</li>\n<li>item2</li>\n</ul>\n";
      } else if (type == "bold") {
        html = "<b></b>";
      } else if (type == "italic") {
        html = "<i></i>";
      } else if (type == "br") {
        html = "<br />";
      }

      if (target == "internalNote") {
        var position = this.$refs.internalNote.selectionStart;
        this.informationClassificationModel.internalNote = [
          this.informationClassificationModel.internalNote.slice(0, position),
          html,
          this.informationClassificationModel.internalNote.slice(position),
        ].join("");
      } else if (target == "externalNote") {
        var position = this.$refs.externalNote.selectionStart;
        this.informationClassificationModel.externalNote = [
          this.informationClassificationModel.externalNote.slice(0, position),
          html,
          this.informationClassificationModel.externalNote.slice(position),
        ].join("");
      }
    },
    upsertInformationClassification() {
      this.loading = true;
      this.$http.put("/api/ustart/informationClassification/upsert", this.informationClassificationModel).then(() => {
        this.loading = false;
        if (this.insertMode) {
          this.resetInformationClassificationModel();
          this.notifications.inserted = true;
        } else {
          this.notifications.updated = true;
        }
      });
    },
    deleteInformationClassification() {
      this.loading = true;
      this.$http.delete(`/api/ustart/informationClassification/delete/${this.informationClassificationModel.id}`).then(() => {
        this.false = true;
        this.$emit("informationClassificationDeletedEvent", this.informationClassificationModel);
      });
    },
    resetInformationClassificationModel() {
      this.informationClassificationModel = {
        name: "",
        itunesId: "",
        platform: "iOS",
        status: "Godkänd",
        categories: [],
        internalNote: "",
        externalNote: "",
      };

      this.activeTabeItem = "Klassning";
    },
    setUpdateInformationClassificationModelData() {
      this.informationClassificationModel.id = this.updateObject.Id;
      this.informationClassificationModel.name = this.updateObject.Name;
      this.informationClassificationModel.itunesId = this.updateObject.ItunesId;
      this.informationClassificationModel.platform = this.updateObject.Platform;
      this.informationClassificationModel.status = this.updateObject.Status;
      this.informationClassificationModel.categories = this.updateObject.Categories;
      this.informationClassificationModel.id = this.updateObject.Id;
      this.informationClassificationModel.id = this.updateObject.Id;
      this.informationClassificationModel.internalNote = this.updateObject.InternalNote;
      this.informationClassificationModel.externalNote = this.updateObject.ExternalNote;
    },
  },
  computed: {
    informationClassificationAuditLogColumnConfig() {
      return columnConfigHandler.getConfig("informationClassificationAuditLog", []);
    },
    insertMode() {
      return this.mode === "insert";
    },
    upsertButtonLabel() {
      return this.insertMode ? "Skapa" : "Spara";
    },
  },
  watch: {
    mode(val) {
      if (val == "insert") {
        this.resetInformationClassificationModel();
      }
    },
  },
  mounted() {
    if (!this.insertMode) {
      this.setUpdateInformationClassificationModelData();
    } else if (this.insertMode && this.insertObject != null) {
      this.informationClassificationModel.name = this.insertObject.name;
      this.informationClassificationModel.itunesId = this.insertObject.itunesId;
      this.$router.replace({ query: {} });
    }
  },
};
</script>

<style>
.no-radius-top {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>

module.exports = [
  {
    type: "string",
    attribute: "sAMAccountName",
    label: "Användarnamn",
  },
  {
    type: "string",
    attribute: "userPrincipalName",
    label: "E-postadress",
  },
  {
    type: "string",
    attribute: "employeeType",
    label: "Kontotyp",
    visible: false,
  },
  {
    type: "string",
    attribute: "sthlmVerksamhetsId",
    label: "Primärt vedaId",
  },
  {
    type: "string",
    attribute: "department",
    label: "Primär verksamhet",
  },
];

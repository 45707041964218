<template>
  <div class="content">
    <div class="col-12">
      <div v-if="tokenDecodedIsUstartAdmin" class="mb-5">
        <div class="mb-5">
          <h4>Dashboard</h4>
        </div>
        <Dashboard :id="1" />
        <hr />
      </div>
      <div class="mb-5">
        <h4>Nytt i mdmportal</h4>
      </div>
      <div v-if="!loadingChangelogItem">
        <template v-for="(changelogItem, index) in changelogItems">
          <div class="mb-4" v-bind:key="index">
            <h6>Version {{ changelogItem.version }}</h6>
            {{ changelogItem.date }}
            <br /><br />
            <ul class="ul-no-style">
              <li v-for="(changelogItemItem, index) in changelogItem.items" v-bind:key="'changelogItemItem' + index">
                {{ changelogItemItem.text }}
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading/Loading.vue";
import Dashboard from "../../components/dashboard/Dashboard.vue";
import { mapGetters } from "vuex";
export default {
  name: "Start",
  components: { Loading, Dashboard },
  data() {
    return {
      loadingChangelogItem: true,
      changelogItems: null,
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["authenticated", "tokenDecodedIsGlobalAdmin", "tokenDecodedIsUstartAdmin"]),
  },
  mounted() {
    this.$http.get("/api/ustart/information/latestChangelog").then((result) => {
      this.changelogItems = result.data;
      this.loadingChangelogItem = false;
    });
  },
};
</script>

export const baseMenuItems = [
  { type: "header", label: "Konto" },
  { type: "link", iconClass: "fas fa-user", label: "Mitt konto", path: "MyUser", badge: null },
  { type: "link", iconClass: "fas fa-chalkboard-teacher", label: "Mina enheter", path: "MyDevices", badge: null },
  { type: "link", iconClass: "fas fa-sign-out-alt", label: "Logga ut", path: "Logout", badge: null },
];

export const adminMenuItems = (jamfTeacherEnabled) => [
  { type: "header", label: "Jamf" },
  { type: "link", iconClass: "fa fa-tachometer-alt", label: "Start", path: "Start", badge: null },
  { type: "link", iconClass: "fa fa-tablet-alt", label: "Enheter", path: "Devices", badge: null },
  { type: "link", iconClass: "fa fa-users", label: "Enhetsgrupper", path: "Groups", badge: null },
  ...(jamfTeacherEnabled
    ? [{ type: "link", iconClass: "fa fa-users", label: "JAMF Teacher", path: "Classes", badge: { class: "badge bg-warning text-dark", label: "Nytt" } }]
    : []),
  { type: "link", iconClass: "fab fa-app-store", label: "Appar", path: "Applications", badge: null },
  {
    type: "dropdown",
    iconClass: "fas fa-cog",
    label: "Restriktioner",
    // badge: { class: "badge bg-warning text-dark", label: "Nytt" },
    badge: null,
    expanded: false,
    links: [
      // { type: "dropdownLink", label: "Kända problem", path: "", badge: null },
      { type: "dropdownLink", label: "Restriktioner", path: "Restrictions", badge: null },
      { type: "dropdownLink", label: "Nivåer", path: "RestrictionPackages", badge: null },
      // { type: "dropdownLink", label: "Hjälp", path: "", badge: null },
    ],
  },
  {
    type: "link",
    iconClass: "fas fa-info-circle",
    label: "Infoklassning",
    path: "InformationClassificationsPublic",
    badge: null,
  },
  {
    type: "dropdown",
    iconClass: "fa fa-question-circle",
    label: "Information",
    badge: null,
    expanded: false,
    links: [
      // { type: "dropdownLink", label: "Kända problem", path: "", badge: null },
      { type: "dropdownLink", label: "Ändringshistorik", path: "Changelog", badge: null },
      // { type: "dropdownLink", label: "Hjälp", path: "", badge: null },
    ],
  },
];

export const infoClassificationAdminMenuItems = [
  { type: "header", label: "Infoklassning" },
  { type: "link", iconClass: "fas fa-info-circle", label: "Klassningar", path: "InformationClassifications", badge: null },
  { type: "link", iconClass: "fab fa-app-store", label: "Inköpta appar", path: "PurchasedApplications", badge: null },
];

export const globalAdminMenuItems = [
  { type: "header", label: "Admin" },
  { type: "link", iconClass: "fa fa-users", label: "Administratörer", path: "AdminUstartAdmins", badge: null },
  { type: "link", iconClass: "fa fa-tablet-alt", label: "Alla enheter", path: "AdminDevices", badge: null },
  { type: "link", iconClass: "fab fa-app-store", label: "Alla appar", path: "AdminVppApplications", badge: null },
  { type: "link", iconClass: "fas fa-building", label: "Alla verksamheter", path: "AdminVedaDepartments", badge: null },
  { type: "link", iconClass: "fas fa-toilet-paper", label: "Loggar", path: "AdminLogs", badge: null },
];

export const daisyMenuItems = [
  { type: "header", label: "Daisy" },
  { type: "link", iconClass: "fa fa-tachometer-alt", label: "Start", path: "Start", badge: null },
  {
    type: "dropdown",
    iconClass: "fa fa-users",
    label: "Kontohantering",
    badge: null,
    expanded: false,
    links: [
      { type: "dropdownLink", label: "Kontohantering", path: "DaisyUserManagement", badge: null },
      { type: "dropdownLink", label: "Roller för konto", path: "DaisyUserRoles", badge: null },
    ],
  },
  {
    type: "dropdown",
    iconClass: "fas fa-sticky-note",
    label: "Rapporter",
    badge: null,
    expanded: false,
    links: [
      { type: "dropdownLink", label: "Konton", path: "DaisyUserReport", badge: null },
      { type: "dropdownLink", label: "Enheter", path: "DaisyAssetReport", badge: null },
    ],
  },
];

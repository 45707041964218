module.exports = [
  {
    type: "string",
    attribute: "Id",
    label: "Id",
    visible: false,
  },
  {
    type: "string",
    attribute: "Pid",
    label: "Pid",
    visible: false,
  },
  {
    type: "string",
    attribute: "RequestId",
    label: "RequestId",
    visible: false,
  },
  {
    type: "string",
    attribute: "Level",
    label: "Level",
  },
  {
    type: "string",
    attribute: "Type",
    label: "Type",
  },
  {
    type: "string",
    attribute: "Path",
    label: "Path",
    visible: false,
  },
  {
    type: "string",
    attribute: "DisplayMessage",
    label: "DisplayMessage",
  },
  {
    type: "string",
    attribute: "user",
    label: "user",
  },
  {
    type: "string",
    attribute: "Data1",
    label: "Data1",
    visible: false,
  },
  {
    type: "string",
    attribute: "Data2",
    label: "Data2",
    visible: false,
  },
  {
    type: "string",
    attribute: "Data3",
    label: "Data3",
    visible: false,
  },
  {
    type: "json",
    attribute: "DecodedToken",
    label: "DecodedToken",
    visible: false,
  },
  {
    type: "string",
    attribute: "Created",
    label: "Created",
  },
];

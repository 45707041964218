import Vue from "vue";
import VueRouter from "vue-router";

import * as oidcHandler from "../utils/oidc/oidcHandler.js";

Vue.use(VueRouter);
import store from "../store/index.js";
import userRoutes from "./user/index.js";
import etcRoutes from "./etc/index.js";
import deviceRoutes from "./device/index.js";
import authRoutes from "./auth/index.js";
import restrictionRoutes from "./restriction/index.js";
import adminRoutes from "./admin/index.js";
import applicationRoutes from "./application/index.js";
import feedbackRoutes from "./feedback/index.js";
import informationRoutes from "./information/index.js";
import groupRoutes from "./group/index.js";
import startRoutes from "./start/index.js";
import informationClassificationRoutes from "./informationClassification/index.js";
import classRoutes from "./class/index.js";
import daisyRoutes from "./daisy/index.js";

const routes = [
  { name: "Default", path: "/", redirect: "/Start" },
  ...authRoutes,
  ...userRoutes,
  ...etcRoutes,
  ...deviceRoutes,
  ...restrictionRoutes,
  ...adminRoutes,
  ...applicationRoutes,
  ...feedbackRoutes,
  ...informationRoutes,
  ...groupRoutes,
  ...startRoutes,
  ...informationClassificationRoutes,
  ...classRoutes,
  ...daisyRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  //not authenticated
  if (!store.getters.authenticated) {
    if (to.name === "Unauthorized") {
      next();
    } else if (to.name === "Auth_return") {
      try {
        let token = await oidcHandler.handleAuthenticateCallback(to.fullPath);
        store.dispatch("setToken", token);
        router.push({ path: "/" });
      } catch (err) {
        next({
          path: "/Unauthorized",
        });
      }
    } else {
      oidcHandler.redirectToAuthorize();
    }
  }
  //authenticated
  if (to.name === "Logout") {
    store.dispatch("logout");
  } else {
    next();
  }
});

export default router;

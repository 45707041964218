<template>
  <div>
    <loading v-if="loading" />
    <table class="table" v-if="!loading">
      <tbody>
        <tr v-for="row in rows" v-bind:key="row.key">
          <td>
            <b>{{ row.key }}</b>
          </td>
          <td>
            {{ row.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Loading from "../../loading/Loading.vue";

export default {
  name: "TableKeyValue",
  components: { Loading },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    backendPath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: true,
      rows: [],
    };
  },
  watch: {
    tableData() {
      this.rows = this.tableData;
    },
    backendPath() {
      this.loading = true;
      this.$http.get(this.backendPath).then((result) => {
        this.rows = result.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    if (this.backendPath) {
      this.$http.get(this.backendPath).then((result) => {
        this.rows = result.data;
        this.loading = false;
      });
    } else {
      this.rows = this.tableData;
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>

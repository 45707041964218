<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Enhetsgrupper" :isSubMenu="true" @linkEvent="linkEvent" />
    <div class="content content-sub-sidebar-present">
      <div class="col-12">
        <table-paginated
          v-if="displayGroupsTable"
          tableId="groupsTablev3"
          backendPath="/api/ustart/groups"
          :columnConfig="groupsColumnConfig"
          @event="readTableEvent"
        />

        <modal modalId="createGroupModal" header="Skapa enhetsgrupp">
          <notification message="Enhetsgruppen skapades" v-model="notifications.completedCreateGroup" />
          <loading v-if="loadingNewGroup" />
          <div v-show="!loadingNewGroup">
            <div class="mb-3">
              <label class="form-label">Namn</label>
              <input type="text" class="form-control" :class="[newGroupNameIsInvalid ? 'is-invalid' : '']" v-model="newGroup.name" />
              <div v-show="newGroupNameIsInvalid" class="invalid-feedback">
                "-" ogiltigt tecken.
              </div>
            </div>
            <div class="mb-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="iosRadio" id="iosRadio" value="iOS" v-model="newGroup.platform" />
                <label class="form-check-label" for="iosRadio">
                  iOS
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="macosRadio" id="macosRadio" value="macOS" v-model="newGroup.platform" />
                <label class="form-check-label" for="macosRadio">
                  macOS
                </label>
              </div>
            </div>
            <button class="btn btn-primary" v-on:click="createGroup()" role="button" :disabled="newGroupNameIsInvalid">Skapa</button>
          </div>
        </modal>
      </div>

      <modal modalId="editGroupModal" header="Redigera enhetsgrupp">
        <notification message="Enhetsgruppen redigerades" v-model="notifications.completedEditGroup" />
        <loading v-if="loadingEditGroup" />
        <div v-show="!loadingEditGroup">
          <div class="mb-3">
            <label class="form-label">Namn</label>
            <input
              type="text"
              class="form-control"
              :class="[editGroupNameIsInvalid ? 'is-invalid' : '']"
              placeholder="Namn"
              v-model="editGroup.name"
              style="max-width: 300px;"
            />
            <div v-show="editGroupNameIsInvalid" class="invalid-feedback">
              "-" ogiltigt tecken.
            </div>
          </div>
          <button class="btn btn-primary" :disabled="editGroupNameIsInvalid" v-on:click="updateGroup()">Spara</button>
        </div>
      </modal>

      <modal modalId="deleteGroupModal" header="Ta bort enhetsgrupp">
        <notification message="Gruppen togs bort" v-model="notifications.completedDeleteGroup" />
        <loading v-if="loadingDeleteGroup" />
        <div v-show="!loadingDeleteGroup && !groupIsDeletedButModalIsNoteClosed">
          <div class="mb-3">
            <label class="form-label">Namn</label>
            <input type="text" class="form-control" placeholder="Namn" v-model="deleteGroup.name" style="max-width: 300px;" disabled />
          </div>
          <a class="btn btn-danger" v-on:click="removeGroup()">Ta bort</a>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import Loading from "../../components/loading/Loading.vue";
import Notification from "../../components/notification/Notification.vue";
import Modal from "../../components/modal/Modal.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";

export default {
  name: "Groups",
  components: { TablePaginated, Notification, Loading, Modal, Sidemenu },
  data() {
    return {
      displayGroupsTable: true,
      displayEditModal: false,
      displayDeleteModal: false,
      loadingNewGroup: false,
      loadingEditGroup: false,
      loadingDeleteGroup: false,
      notifications: {
        completedCreateGroup: false,
        completedEditGroup: false,
        completedDeleteGroup: false,
      },
      newGroup: {
        name: "",
        platform: "iOS",
      },
      editGroup: {
        platformCombinedId: "",
        name: "",
      },
      deleteGroup: {
        platformCombinedId: "",
        name: "",
      },
      groupIsDeletedButModalIsNoteClosed: false,
      sideMenuItems: [
        { type: "header", label: "Enhetsgrupper" },
        { type: "link", iconClass: "fa fa-users", label: "Skapa enhetsgrupp", modal: "createGroupModal", badge: null },
      ],
    };
  },
  methods: {
    readTableEvent(event) {
      if (event.eventName == "editGroup") {
        this.editGroup.name = event.row.name;
        this.editGroup.platformCombinedId = event.row.platformCombinedId;
      } else if (event.eventName == "deleteGroup") {
        this.deleteGroup.name = event.row.name;
        this.deleteGroup.platformCombinedId = event.row.platformCombinedId;
        this.groupIsDeletedButModalIsNoteClosed = false;
      }
    },
    createGroup() {
      this.loadingNewGroup = true;
      this.$http.post("/api/ustart/group", this.newGroup).then((result) => {
        this.loadingNewGroup = false;
        this.newGroup.name = "";
        this.displayGroupsTable = false;
        this.notifications.completedCreateGroup = true;
        //re-render table
        this.$nextTick(() => {
          this.displayGroupsTable = true;
        });
      });
    },
    updateGroup() {
      this.loadingEditGroup = true;
      this.$http.put("/api/ustart/group", this.editGroup).then((result) => {
        this.loadingEditGroup = false;
        this.displayGroupsTable = false;
        this.notifications.completedEditGroup = true;
        //re-render table
        this.$nextTick(() => {
          this.displayGroupsTable = true;
        });
      });
    },
    removeGroup() {
      this.loadingDeleteGroup = true;
      this.$http.delete("/api/ustart/group/" + this.deleteGroup.platformCombinedId).then((result) => {
        this.loadingDeleteGroup = false;
        this.displayGroupsTable = false;
        this.notifications.completedDeleteGroup = true;
        this.groupIsDeletedButModalIsNoteClosed = true;
        //re-render table
        this.$nextTick(() => {
          this.displayGroupsTable = true;
        });
      });
    },
  },
  computed: {
    groupsColumnConfig() {
      return columnConfigHandler.getConfig("groups", []);
    },
    newGroupNameIsInvalid() {
      return this.newGroup.name.includes("-");
    },
    editGroupNameIsInvalid() {
      return this.editGroup.name.includes("-");
    },
  },
  mounted() {},
};
</script>

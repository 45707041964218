<template>
  <div>
    <Sidemenu :menuItems="sideMenuItems" theme="light-theme" header="Enhetsdetaljer" :isSubMenu="true" @linkEvent="linkEvent" />
    <div class="content content-sub-sidebar-present">
      <loading v-if="loading.fullPage" />
      <div class="col-12" v-if="!loading.fullPage">
        <div class="mb-5">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href v-on:click="goToDevices()">Enheter</a>
              </li>
              <li class="breadcrumb-item active">Enhetsdetaljer</li>
            </ol>
          </nav>
        </div>
        <notification message="Enheten synkroniserades!" v-model="notifications.completedCheckinDevice" />
        <notification message="Lösenkoden togs bort från enheten!" v-model="notifications.completedRemovePasscode" />
        <notification message="Enheten återställdes!" v-model="notifications.completedResetDevice" />
        <notification message="WiFi-certifikat förnyat!" v-model="notifications.reissueWifiCertificate" />
        <notification message="Enheten har låsts!" v-model="notifications.lockMac" />

        <tab-bar :items="tabItems" v-model="activeTabeItem" />
        <br />
        <table-key-value
          v-if="showDeviceDetails"
          ref="deviceDetailsTableKeyValue"
          v-show="activeTabeItem == 'Detaljer'"
          :backendPath="`/api/ustart/device/${this.$route.query.param}/details/keyValue`"
        />

        <table-paginated
          v-if="isMobileDevice"
          v-show="activeTabeItem == 'Historik'"
          tableId="deviceHistoryManagementCommandsCompletedv2"
          :backendPath="`/api/ustart/device/${this.$route.query.param}/history/managementCommands/completed`"
          :columnConfig="deviceHistoryManagementCommandsCompletedColumnConfig"
        />

        <table-paginated
          v-if="isMobileDevice"
          v-show="activeTabeItem == 'Kö'"
          tableId="deviceHistoryManagementCommandsPendingv2"
          :backendPath="`/api/ustart/device/${this.$route.query.param}/history/managementCommands/pending`"
          :columnConfig="deviceHistoryManagementCommandsPendingColumnConfig"
        />

        <div class="alert alert-warning" role="alert" v-show="activeTabeItem == 'Tilldelade appar'">
          <strong>OBS!</strong> Nedan visas enbart tilldelade applikationer från verksamheten, centralt tilldelade applikationer visas ej.
        </div>

        <table-paginated
          v-show="activeTabeItem == 'Tilldelade appar'"
          tableId="deviceAssignedApplications"
          :backendPath="`/api/ustart/device/${this.$route.query.param}/assignedApplications`"
          :columnConfig="nameOnlyColumnConfig"
        />

        <table-paginated
          v-show="activeTabeItem == 'Installerade appar'"
          tableId="deviceInstalledApplications"
          :backendPath="`/api/ustart/device/${this.$route.query.param}/installedApplications`"
          :columnConfig="deviceInstalledApplicationsColumnConfig"
        />
        <table-paginated
          v-if="isMobileDevice"
          v-show="activeTabeItem == 'Restriktioner'"
          tableId="deviceConfigurationProfiles"
          :backendPath="`/api/ustart/device/${this.$route.query.param}/configurationProfiles`"
          :columnConfig="nameOnlyColumnConfig"
        />

        <modal modalId="aliasModal" header="Redigera alias">
          <saveable-textarea
            label="Alias"
            :getPath="`/api/ustart/device/${this.$route.query.param}/alias`"
            :putPath="`/api/ustart/device/${this.$route.query.param}/alias`"
            putBodyAttributeName="alias"
            :textAreaRows="1"
            successMessage="Alias sparades!"
            @saved="detailsChanged"
          />
        </modal>

        <modal modalId="deviceNameModal" header="Redigera enhetsnamn">
          <saveable-textarea
            label="Enhetsnamn"
            :getPath="`/api/ustart/device/${this.$route.query.param}/deviceName`"
            :putPath="`/api/ustart/device/${this.$route.query.param}/deviceName`"
            putBodyAttributeName="name"
            :textAreaRows="1"
            successMessage="Enhetsnamnet sparades!"
            @saved="detailsChanged"
          />
        </modal>

        <modal modalId="commentModal" header="Redigera kommentar">
          <saveable-textarea
            label="Kommentar"
            :getPath="`/api/ustart/device/${this.$route.query.param}/comment`"
            :putPath="`/api/ustart/device/${this.$route.query.param}/comment`"
            putBodyAttributeName="comment"
            successMessage="Kommentaren sparades!"
            @saved="detailsChanged"
          />
        </modal>

        <modal modalId="wipeModal" header="Fabriksåterställ">
          <div class="alert alert-danger" role="alert">
            VARNING! Enheten kommer att fabriksåterställas och allt innehåll kommer att raderas!<br /><br />Vid fabriksåterställning av macOS-enhet skapas en
            pin-kod som behöver anges efter att enheten har återställts. Pin-koden sätts till <b>111111</b>.
          </div>
          <a class="btn btn-danger" v-on:click="wipeDevice()" data-bs-dismiss="modal" aria-label="Close">Fabriksåterställ</a>
        </modal>

        <modal modalId="deleteModal" header="Radera">
          <div class="alert alert-warning" role="alert">
            Om du har fabriksåterställt en enhet direkt i enheten eller via DFU kan du radera enheten från mdmportal.
          </div>
          <a class="btn btn-danger" v-on:click="deleteDevice()" data-bs-dismiss="modal" aria-label="Close">Radera</a>
        </modal>

        <modal modalId="enableLostModeModal" header="Aktivera lost mode">
          <p>
            Enheten kommer att låsas och går inte att använda. Ett telefonnummer och meddelande kommer att visas om någon försöker använda enheten. Lost mode
            går att ta bort i efterhand.
          </p>
          <div class="alert alert-warning" role="alert">
            För att lost mode ska kunna aktiveras måste enheten ha uppkoppling mot internet. Om enheten exempelvis är avstängd kommer kommandont för lost mode
            att läggas i kö, detta är synligt genom fliken "kö" här, det är först när enheten får uppkoppling mot internet som enheten kommer att sätts i lost
            mode.
          </div>
          <div class="mb-3">
            <label class="form-label">Telefonnummer</label>
            <input type="text" class="form-control" v-model="enableLostModeObject.phone" />
          </div>
          <div class="mb-3">
            <div class="form-check form-check">
              <input class="form-check-input" type="checkbox" id="enableLostModeSound" :value="false" v-model="enableLostModeObject.withSound" />
              <label class="form-check-label" for="enableLostModeSound"
                >Spela ljud (<small
                  >En hög signal kommer att spelas upp på enheten tills att lost mode slås av eller att volym-ner knappen på enheten trycks ner</small
                >)</label
              >
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Meddelande</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="enableLostModeObject.message"></textarea>
          </div>
          <a class="btn btn-danger" v-on:click="enableLostMode()" data-bs-dismiss="modal" aria-label="Close">Aktivera lost mode</a>
        </modal>

        <modal modalId="disableLostModeModal" header="Ta bort lost mode">
          <p>Lost mode kommer att tas bort och enheten kan användas igen.</p>
          <a class="btn btn-danger" v-on:click="disableLostMode()" data-bs-dismiss="modal" aria-label="Close">Ta bort lost mode</a>
        </modal>

        <modal modalId="resetModal" header="Återställ enhet">
          <div class="alert alert-warning" role="alert">De resurser du väljer nedan kommer att tas bort från enheten.</div>
          <label class="form-label">Återställ</label>
          <br />
          <div class="form-check form-check" v-for="(resetDeviceItem, index) in resetDeviceItems" v-bind:key="index">
            <input class="form-check-input" type="checkbox" :id="resetDeviceItem" :value="resetDeviceItem" v-model="selectedResetDeviceItems" />
            <label class="form-check-label" :for="resetDeviceItem">{{ resetDeviceItem }}</label>
          </div>
          <a class="btn btn-danger mt-3" v-on:click="resetDevice()" data-bs-dismiss="modal" aria-label="Close">Återställ</a>
        </modal>

        <modal modalId="reissueWifiCertificateModal" header="Förnya WiFi-certifikat">
          <div class="alert alert-warning" role="alert">
            Om en enhet inte kan ansluta till StockholmInternt kan ett nytt WiFi-certifikat utfärdas för att lösa problemet. Säkertställ följande inför att
            WiFi-certifikat förnyas:

            <ul class="mt-3 mb-3">
              <li>
                Enheten har inga kommandon i kö. Om kommandon finns i kön, synkronisera och låsa upp enheten och låt kommandona gå igenom. Vissa kommandon som
                exempelvis <b>OSUpdateStatus</b> kan ligga kvar i kön.
              </li>
              <li>Inga installationer eller uppgraderingar av appar pågår.</li>
              <li>Enheten är ansluten till StockholmEnroll eller annat WiFi med internet.</li>
              <li>Enheten är upplåst.</li>
            </ul>
            Vänta ett per minuter efter att WiFi-certifikatet har förnyats och försök sedan att ansluta till StockholmInternt igen.<br /><br />
            Det tar ca 15-30sek att förnya WiFi-certifikatet.
          </div>
          <br />

          <a class="btn btn-success mt-3" v-on:click="reissueWifi()" data-bs-dismiss="modal" aria-label="Close">Förnya Wifi-certifikat</a>
        </modal>

        <modal modalId="getPukModal" header="Hämta PUK-kod">
          <loading v-if="loading.puk" />
          <div v-if="puk != null" class="alert alert-success mb-3" role="alert"><b>PUK-kod: </b>{{ puk.puk }}</div>
          <a class="btn btn-success" v-on:click="getPuk()" aria-label="Close">Hämta</a>
        </modal>

        <modal modalId="lockMacModal" header="Lås enhet">
          <div class="alert alert-warning" role="alert">Enheten kommer att låsas med en engångskod.</div>
          <div class="mb-3">
            <label class="form-label">Kod</label>
            <input type="text" class="form-control" v-model="lockMacPasscode" />
            <div class="form-text">6 siffror</div>
          </div>
          <button class="btn btn-success" v-on:click="lockMac()" data-bs-dismiss="modal" aria-label="Close" :disabled="lockMacPasscode.length < 6">
            Lås enhet
          </button>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as routerHandler from "../../utils/router/routerHandler.js";
import TabBar from "../../components/tabBar/TabBar.vue";
import TableKeyValue from "../../components/tables/tableKeyValue/TableKeyValue.vue";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import Notification from "../../components/notification/Notification.vue";
import Modal from "../../components/modal/Modal.vue";
import Loading from "../../components/loading/Loading.vue";
import SaveableTextarea from "../../components/saveableTextarea/SaveableTextarea.vue";
import Sidemenu from "../../components/sidemenu/Sidemenu.vue";

const subMenuEvents = {
  CHECK_IN: "CHECK_IN",
  REMOVE_PIN: "REMOVE_PIN",
};

export default {
  name: "DeviceDetails",
  components: {
    mapGetters,
    TabBar,
    TableKeyValue,
    TablePaginated,
    Notification,
    Modal,
    Loading,
    SaveableTextarea,
    Sidemenu,
  },
  data() {
    return {
      mounted: false,
      showDeviceDetails: true,
      //By default only show tab items for macOS, push additional tabs in mounted() if device is mobileDevice
      tabItems: ["Detaljer", "Tilldelade appar", "Installerade appar"],
      activeTabeItem: "Detaljer",
      resetDeviceItems: ["Appar", "Restriktioner", "Enhetsgrupper", "Alias", "Kommentar"],
      selectedResetDeviceItems: [],
      loading: {
        fullPage: false,
        checkinDevice: false,
        removePasscode: false,
        wipeDevice: false,
        puk: false,
      },
      notifications: {
        completedCheckinDevice: false,
        completedRemovePasscode: false,
        completedResetDevice: false,
        reissueWifiCertificate: false,
        lockMac: false,
      },
      enableLostModeObject: {
        phone: "",
        message: "",
        withSound: false,
      },
      lostModeCoordinates: null,
      puk: null,
      lockMacPasscode: "",
    };
  },
  methods: {
    goToDevices() {
      routerHandler.goToPath(this, "/Devices");
    },
    checkInDevice() {
      this.$http.post(`/api/ustart/device/${this.$route.query.param}/sendBlankPush`).then((result) => {
        this.notifications.completedCheckinDevice = true;
      });
    },
    removePasscodeFromDevice() {
      this.$http.post(`/api/ustart/device/${this.$route.query.param}/sendRemovePasscode`).then((result) => {
        this.notifications.completedRemovePasscode = true;
      });
    },
    wipeDevice() {
      this.loading.fullPage = true;
      this.$http.post(`/api/ustart/device/${this.$route.query.param}/sendWipeDevice`).then((result) => {
        this.goToDevices();
      });
    },
    deleteDevice() {
      this.loading.fullPage = true;
      this.$http.delete(`/api/ustart/device/${this.$route.query.param}/delete`).then((result) => {
        this.goToDevices();
      });
    },
    linkEvent(data) {
      if (data == subMenuEvents.CHECK_IN) {
        this.checkInDevice();
      } else if (data == subMenuEvents.REMOVE_PIN) {
        this.removePasscodeFromDevice();
      }
    },
    getLostModeCoordinates() {
      this.$http.get(`/api/ustart/device/${this.$route.query.param}/lostModeCoordinates`).then((result) => {
        this.lostModeCoordinates = result.data[0];
      });
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    enableLostMode() {
      this.loading.fullPage = true;
      this.$http.put(`/api/ustart/device/${this.$route.query.param}/enableLostMode`, this.enableLostModeObject).then((result) => {
        this.sleep(5000).then(() => {
          this.$router.go(this.$router.currentRoute);
        });
      });
    },
    disableLostMode() {
      this.loading.fullPage = true;
      this.$http.put(`/api/ustart/device/${this.$route.query.param}/disableLostMode`).then((result) => {
        this.sleep(5000).then(() => {
          this.$router.go(this.$router.currentRoute);
        });
      });
    },
    detailsChanged(data) {
      this.showDeviceDetails = false;
      this.$nextTick(() => {
        this.showDeviceDetails = true;
      });
    },
    resetDevice() {
      this.loading.fullPage = true;
      this.$http.post(`/api/ustart/device/${this.$route.query.param}/reset`, this.selectedResetDeviceItems).then((result) => {
        this.notifications.completedResetDevice = true;
        this.loading.fullPage = false;
      });
    },
    reissueWifi() {
      this.loading.fullPage = true;
      this.$http.post(`/api/ustart/device/${this.$route.query.param}/reissueWifiCertificate`).then((result) => {
        this.notifications.reissueWifiCertificate = true;
        this.loading.fullPage = false;
      });
    },
    getPuk() {
      this.loading.puk = true;
      this.$http.get(`/api/ustart/device/${this.$route.query.param}/puk`).then((result) => {
        this.loading.puk = false;
        this.puk = result.data;
      });
    },
    lockMac() {
      this.loading.fullPage = true;
      this.$http.post(`/api/ustart/device/${this.$route.query.param}/lockMac/${this.lockMacPasscode}`).then((result) => {
        this.notifications.lockMac = true;
        this.loading.fullPage = false;
        this.lockMacPasscode = "";
      });
    },
  },
  computed: {
    ...mapGetters(["tokenDecodedIsGlobalAdmin"]),
    isMobileDevice() {
      return this.$route.query.param.includes("iOS");
    },
    deviceHistoryManagementCommandsCompletedColumnConfig() {
      return columnConfigHandler.getConfig("deviceHistoryManagementCommandsCompleted", []);
    },
    deviceHistoryManagementCommandsPendingColumnConfig() {
      return columnConfigHandler.getConfig("deviceHistoryManagementCommandsPending", []);
    },
    nameOnlyColumnConfig() {
      return [
        {
          type: "string",
          attribute: "name",
          label: "Namn",
        },
      ];
    },
    deviceInstalledApplicationsColumnConfig() {
      return columnConfigHandler.getConfig("deviceInstalledApplications", []);
    },
    deviceIsStudentOrNonpersonal() {
      if (this.mounted) {
        try {
          var val = this.$refs.deviceDetailsTableKeyValue.rows.filter((item) => item.key == "Användare")[0].value;
          return val.includes("jamf-") || val.includes("elevmail");
        } catch (err) {
          return false;
        }
      } else {
        return false;
      }
    },
    deviceIsDep() {
      if (this.mounted) {
        try {
          var val = this.$refs.deviceDetailsTableKeyValue.rows.filter((item) => item.key == "DEP")[0].value;
          return val == "Sant";
        } catch (err) {
          return false;
        }
      } else {
        return false;
      }
    },
    lostModeEnabled() {
      if (this.mounted) {
        try {
          return this.$refs.deviceDetailsTableKeyValue.rows.filter((item) => item.key == "Lost mode")[0].value == "Sant";
        } catch (err) {
          return false;
        }
      } else {
        return false;
      }
    },
    shouldGetLostModeCoordinate() {
      return this.lostModeEnabled && (this.tokenDecodedIsGlobalAdmin || this.deviceIsStudentOrNonpersonal);
    },
    lostModeGoogleMapsUrl() {
      if (this.lostModeEnabled && this.lostModeCoordinates != null) {
        return `http://www.google.se/maps/place/${this.lostModeCoordinates.lost_location_latitude},${this.lostModeCoordinates.lost_location_longitude}/@${this.lostModeCoordinates.lost_location_latitude},${this.lostModeCoordinates.lost_location_longitude},15z`;
      } else {
        return "";
      }
    },
    sideMenuItems() {
      return [
        { type: "header", label: "Hantera enhet" },
        { type: "link", iconClass: "fa fa-tachometer-alt", label: "Synkronisera", event: subMenuEvents.CHECK_IN, badge: null },
        { type: "link", iconClass: "fas fa-cog", label: "Redigera enhetsnamn", modal: "deviceNameModal", badge: null },
        { type: "link", iconClass: "fas fa-cog", label: "Redigera alias", modal: "aliasModal", badge: null },
        { type: "link", iconClass: "fas fa-cog", label: "Redigera kommentar", modal: "commentModal", badge: null },
        {
          type: "link",
          iconClass: "fab fa-avianex",
          label: "Aktivera lost mode",
          modal: "enableLostModeModal",
          badge: null,
          condition: !this.lostModeEnabled && this.isMobileDevice && this.deviceIsDep,
        },
        {
          type: "link",
          iconClass: "fab fa-avianex",
          label: "Ta bort lost mode",
          modal: "disableLostModeModal",
          badge: null,
          condition: this.lostModeEnabled && this.isMobileDevice,
        },
        {
          type: "link",
          iconClass: "fas fa-map-marked",
          label: "Lokalisera enhet",
          externalUrl: this.lostModeGoogleMapsUrl,
          badge: null,
          condition: this.lostModeEnabled && (this.tokenDecodedIsGlobalAdmin || this.deviceIsStudentOrNonpersonal) && this.isMobileDevice,
        },
        { type: "link", iconClass: "fas fa-wifi", label: "Förnya WiFi-certifikat", modal: "reissueWifiCertificateModal", badge: null },
        { type: "link", iconClass: "fas fa-info-circle", label: "Hämta PUK-kod", modal: "getPukModal", badge: null },
        { type: "link", iconClass: "fas fa-bomb", label: "Ta bort lösenkod", event: subMenuEvents.REMOVE_PIN, badge: null, condition: this.isMobileDevice },
        { type: "link", iconClass: "fas fa-bomb", label: "Lås", modal: "lockMacModal", badge: null, condition: !this.isMobileDevice },
        { type: "link", iconClass: "fas fa-bomb", label: "Återställ", modal: "resetModal", badge: null },
        { type: "link", iconClass: "fas fa-bomb", label: "Fabriksåterställ", modal: "wipeModal", badge: null },
        { type: "link", iconClass: "fas fa-trash", label: "Radera", modal: "deleteModal", badge: null },
      ];
    },
  },
  watch: {
    shouldGetLostModeCoordinate: function (newVal, oldVal) {
      if (newVal == true) {
        this.getLostModeCoordinates();
      }
    },
  },
  mounted() {
    this.mounted = true;
    if (this.isMobileDevice) {
      this.tabItems.push("Restriktioner");
      this.tabItems.push("Kö");
      this.tabItems.push("Historik");
    }
  },
};
</script>

<template>
  <div>
    <loading v-if="dashboardConfigIsLoading"></loading>
    <div class="row" v-if="!dashboardConfigIsLoading">
      <div v-for="(dashboardConfigItem, index) in dashboardConfig" v-bind:key="index" class="col-xl-2 col-lg-4 col-sm-6 col-xs-12 box-container">
        <dashboard-item :id="id" :config="dashboardConfigItem" />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardItem from "./DashboardItem.vue";
import Loading from "../loading/Loading.vue";

export default {
  name: "Dashboard",
  components: { DashboardItem, Loading },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dashboardConfig: null,
      dashboardConfigIsLoading: true,
    };
  },
  methods: {},
  computed: {},
  mounted() {
    this.$http.get(`/api/ustart/dashboard/${this.id}/items`).then((result) => {
      this.dashboardConfig = result.data;
      this.dashboardConfigIsLoading = false;
    });
  },
};
</script>

<style scoped>
.box-container {
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 15px;
  padding-bottom: 15px;
  margin: 0px;
}
</style>

import Restrictions from "../../views/restriction/Restrictions.vue";
import RestrictionPackages from "../../views/restriction/RestrictionPackages.vue";

export default [
  {
    path: "/RestrictionPackages",
    name: "RestrictionPackages",
    component: RestrictionPackages,
  },
  {
    path: "/Restrictions",
    name: "Restrictions",
    component: Restrictions,
  },
];

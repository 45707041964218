module.exports = [
  {
    type: "string",
    attribute: "platformCombinedName",
    label: "Namn",
  },
  {
    type: "string",
    attribute: "platform",
    label: "Plattform",
  },
];

module.exports = [
  {
    type: "string",
    attribute: "name",
    label: "Kommando",
  },
  {
    type: "string",
    attribute: "date_time_issued_epoch",
    label: "Försökte utföras",
  },
];

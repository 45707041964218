module.exports = [
  {
    type: "string",
    attribute: "name",
    label: "Namn",
  },
  {
    type: "string",
    attribute: "shortVersion",
    label: "Version",
  },
];

<template>
  <transition name="fade">
    <div class="alert alert-success" role="alert" v-show="value">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "Notification",
  props: {
    message: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  watch: {
    value() {
      var thisVueInstance = this;
      if (this.value) {
        new Promise((res) => setTimeout(() => thisVueInstance.$emit("input", false), 2000));
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.7s;
}
.fade-enter /* .fade-leave-active below version 2.1.8 */ {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div id="app" v-show="authenticated || atUnauthorized">
    <!-- <Topmenu /> -->
    <Sidemenu @shouldReloadCurrentView="shouldReloadCurrentView" :menuItems="menuConfig" theme="dark-theme" header="mdmportal" headerPath="/Start" />
    <router-view v-if="displayView" :key="$route.path" />
  </div>
</template>

<script>
import Topmenu from "./components/topmenu/Topmenu.vue";
import Sidemenu from "./components/sidemenu/Sidemenu.vue";
import * as menuConfigs from "./configs/menuConfig.js";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: { Sidemenu, Topmenu },
  data() {
    return {
      displayView: true,
    };
  },
  methods: {
    shouldReloadCurrentView() {
      this.displayView = false;
      this.$nextTick(() => {
        this.displayView = true;
      });
    },
  },
  computed: {
    ...mapGetters([
      "authenticated",
      "tokenDecodedIsGlobalAdmin",
      "tokenDecodedIsInfoClassificationAdmin",
      "tokenDecodedIsUstartAdmin",
      "tokenDecodedIsDaisy",
      "tokenDecodedJamfTeacherEnabled",
    ]),
    menuConfig() {
      var result = _.cloneDeep(menuConfigs.baseMenuItems);
      if (this.authenticated) {
        if (this.tokenDecodedIsUstartAdmin) {
          var result = result.concat(_.cloneDeep(menuConfigs.adminMenuItems(this.tokenDecodedJamfTeacherEnabled)));
        }
        if (this.tokenDecodedIsInfoClassificationAdmin) {
          result = result.concat(_.cloneDeep(menuConfigs.infoClassificationAdminMenuItems));
        }
        if (this.tokenDecodedIsGlobalAdmin) {
          result = result.concat(_.cloneDeep(menuConfigs.globalAdminMenuItems));
        }
        if (this.tokenDecodedIsDaisy) {
          result = result.concat(_.cloneDeep(menuConfigs.daisyMenuItems));
        }
      }
      return result;
    },
    currentRouteName() {
      return this.$route.name;
    },
    atUnauthorized() {
      return this.currentRouteName == "Unauthorized";
    },
  },
};
</script>

<style lang="scss">
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./assets/css/layout.scss";
</style>

module.exports = [
  {
    type: "event",
    eventName: "updateClass",
    linkLabelAttribute: "name",
    attribute: "name",
    label: "Klass",
  },
  {
    type: "string",
    attribute: "description",
    label: "Beskrivning",
  },
  {
    type: "list",
    attribute: "teacher_displayValues",
    label: "Lärare",
  },
  {
    type: "list",
    attribute: "student_displayValues",
    label: "Elever",
  },
  {
    type: "list",
    attribute: "mobile_device_group_displayValues",
    label: "Elevgrupper",
  },
];

<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <table-paginated
          tableId="adminVppApplicationsv7"
          backendPath="/api/ustart/admin/applications"
          :columnConfig="adminApplicationsColumnConfig"
          :parentFilters="filters"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";
import ReuseableFilter from "../../abstractions/reuseableFilter/ReuseableFilter.vue";
import MultiSelectFilter from "../../components/filter/MultiSelectFilter.vue";

export default {
  name: "AdminVppApplications",
  components: { TablePaginated, ReuseableFilter, MultiSelectFilter },
  data() {
    return {
      filters: {
        vpp_locations_location_name: {
          selectedItems: [],
        },
        vpp_assets_product_type: {
          selectedItems: [],
        },
        hasBeenSetupDisplayName: {
          selectedItems: [],
        },
      },
    };
  },
  methods: {},
  computed: {
    adminApplicationsColumnConfig() {
      return columnConfigHandler.getConfig("adminApplications", []);
    },
  },
  mounted() {},
};
</script>

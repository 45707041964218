module.exports = [
  {
    type: "router-link",
    route: "MyDeviceDetails",
    param: "platformCombinedId",
    attribute: "serialNumber",
    label: "Serienummer",
  },
  {
    type: "string",
    attribute: "model",
    label: "Modell",
  },
  {
    type: "string",
    attribute: "platform",
    label: "Plattform",
  },
  {
    type: "string",
    attribute: "osVersion",
    label: "Operativsystem",
  },
  {
    type: "string",
    attribute: "isDep",
    label: "DEP",
    visible: false,
  },
  {
    type: "string",
    attribute: "lostMode",
    label: "Lost mode",
    visible: false,
  },
  {
    type: "string",
    attribute: "diskSizeMb",
    label: "Utrymme",
    visible: false,
  },
  {
    type: "string",
    attribute: "diskAvailableMb",
    label: "Ledigt utrymme",
    visible: false,
  },
  {
    type: "string",
    attribute: "batteryLevel",
    label: "Batterinivå",
    visible: false,
  },
  {
    type: "string",
    attribute: "lastCheckin",
    label: "Senast ansluten",
    visible: false,
  },
  {
    type: "string",
    attribute: "enrolled",
    label: "Registrerad",
  },
];

<template>
  <div>
    <notification :message="successMessage" v-model="saveSuccess" />
    <loading v-if="loading" />
    <div v-show="!loading">
      <div class="mb-3">
        <label>{{ label }}</label>
        <textarea name="textarea" class="form-control" :rows="textAreaRows" v-model="text"></textarea>
      </div>
      <button type="button" class="btn btn-primary" v-on:click="save()">Spara</button>
    </div>
  </div>
</template>

<script>
import Loading from "../loading/Loading.vue";
import Notification from "../notification/Notification.vue";

export default {
  name: "SaveableTextarea",
  components: { Loading, Notification },
  props: {
    label: {
      type: String,
      default: "",
    },
    getPath: {
      type: String,
      default: "",
    },
    putPath: {
      type: String,
      default: "",
    },
    putBodyAttributeName: {
      type: String,
      default: "",
    },
    textAreaRows: {
      type: Number,
      default: 4,
    },
    successMessage: {
      type: String,
      default: "Allt gick bra!",
    },
  },
  data() {
    return {
      text: "",
      saveSuccess: false,
      loading: true,
    };
  },
  methods: {
    save() {
      this.loading = true;
      var body = {};
      body[this.putBodyAttributeName] = this.text;
      this.$http.put(this.putPath, body).then((result) => {
        this.$http.get(this.getPath).then((result2) => {
          this.text = result2.data;
          this.loading = false;
          this.saveSuccess = true;
          this.$emit("saved", { putBodyAttributeName: this.putBodyAttributeName, text: this.text });
        });
      });
    },
  },
  mounted() {
    this.$http.get(this.getPath).then((result) => {
      this.text = result.data;
      this.loading = false;
    });
  },
};
</script>

<style></style>

<template>
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="page-header">
      <h1>Under utveckling</h1>
    </div>
    <div class="row">
      <div class="col-md-7 col-sm-12 col-xs-12">
        <div>
          <h3>Förbättrad applikationsdistribution</h3>
          <p>
            Målet med applikationsdistribution i JAMF och Ustart3 är att det ska vara flexibelt och enkelt. Det ska bli enklare att konfigurera vilka
            applikationer som ska distribueras till vilka enheter och det ska kunna genomföras med relativt få klick.
          </p>
          <p>
            Vi har valt att behålla den manuella tilldelning som fanns och finns i ustart1 och ustart2 samtidigt som vi introducerar automatisk tilldelning och
            applikationsgrupper. Nedan beskrivs de olika arbetssätten.
          </p>
          <p>
            <b>Manuell tilldelning</b><br />
            Manuell tilldelning innebär att man i enhetsöversikten markerar ett visst antal enheter för att sedan makera ett visst antal applikationer som ska
            tilldelas eller tas bort. För att förbättra och förenkla manuell tilldelning har vi förbättrat funktionaliteten kring att söka, filtrera och markera
            i tabellerna. Vi har skapat en vy som visar progress och resultat i realtid när en tilldelning processas. Där kan man exempelvis se att 15 av 68
            tilldelningar har utförts hittils och resultatet för vilka som lyckats respektive misslyckats.
            <br /><br />
            <b>Automatisk tilldelning</b><br />
            Automatisk tilldelning innebär att en applikation tilldelas automatiskt till befintliga enheter samt nya enheter vid registrering. Som ett första
            steg har vi i nuvarande version av ustart3 tillgängliggjort automatisk tilldelning till hela verksamheten. Det konfigureras genom att gå till
            applikationsdetaljer för en applikation och bocka i checkrutan <i>Hela verksamheten</i> under <i>Tilldela automatiskt till</i>. Applikationen kommer
            automatisk att tilldelas till alla befintliga enheter samt nya när de registreras. <br /><br />
            Vi kommer att utöka valen för automatisk tilldelning med val som alla elever, all personal och alla opersonliga. Vi kommer även att undersöka
            möjligheterna till att kunna ha automatisk tilldelning till grupper och klasser.
            <br /><br />
            <b>Applikationsgrupper</b><br />
            Med applikationsgrupper vill vi göra det möjligt att skapa grupper med applikationer. Som ett första steg kommer vi implementera applikationsgrupper
            med manuell tilldelning. Vi undersöker även möjligheterna för automatisk tilldelning för applikationsgrupper.
          </p>
        </div>
        <br />
        <br />
        <div></div>
      </div>

      <div class="col-md-4 col-sm-12 col-xs-12 pull-right">
        <div class="well">
          <h3>Under utveckling</h3>
          <p>
            Här listas de funktioner och områden som är under utveckling och vidareutveckling.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading/Loading.vue";

export default {
  name: "UnderDevelopment",
  components: { Loading },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

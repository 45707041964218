<!--
  Description:
  MultiSelectFilter takes a plain list of filter items and lets the user select multiple values.
  The user can apply a search filter to filter the filter items within the component.
  
  Props:
  - name: String | Required # Name of the filter.
  - items: Array | Required # Array of filter items, array items should be of type { label: '', value: '' }.
  - disableItemSearch: Boolean | Optional # Disable the search-item section.
  - value: used for v-model.

  Output:
  Use v-model binding to get selected filter items in parent component, only the value attribute of a selected items will be returned in the v-model list output.

  Usage examples:
  <multi-select-filter name="Testfilter" :items="[{ label: 'labelA', value: 'valueA' }, { label: 'labelB', value: 'valueB' }]" v-model="selectedFilterItems" />
  <multi-select-filter :name="filters.accountTypeFilter.name" :disableItemSearch="true" :items="filters.accountTypeFilter.items" v-model="selectedFilterItems" />
-->

<template>
  <div>
    <div class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title">
          <a class="collapsed btn-block h4-size" role="button" data-toggle="collapse" :href="'#MultiSelectFilterBody' + instanceId">
            {{ name }}
          </a>
        </h3>
      </div>
      <div :id="'MultiSelectFilterBody' + instanceId" class="panel-collapse collapse">
        <div class="panel-body">
          <form role="form">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12" v-if="!disableItemSearch">
                <div class="form-group">
                  <input type="search" class="form-control u-icon-right icon-search pull-right" v-bind:placeholder="'Sök ' + name" v-model="itemFilter" />
                </div>
              </div>
            </div>
            <hr v-if="!disableItemSearch" />
            <loading v-if="isLoadingBackendItems" />
            <div v-show="!isLoadingBackendItems" class="row" style="overflow-y: scroll; max-height: 260px">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <ul class="list--bare m-a-0 p-a-0">
                  <li v-for="(item, index) in filteredItems" v-bind:key="name + 'MultiSelectFilterItem' + instanceId + index">
                    <div class="checkbox">
                      <input
                        type="checkbox"
                        :id="name + 'MultiSelectFilterCheckbox' + instanceId + index"
                        :value="item.value"
                        class="visuallyhidden"
                        v-model="selectedItems"
                      />
                      <label :for="name + 'MultiSelectFilterCheckbox' + instanceId + index" class="field-option field-option--checkbox">{{ item.label }}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as utilsHandler from "../../utils/utilsHandler.js";
import Loading from "../loading/Loading.vue";

export default {
  name: "MultiSectionFilter",
  components: { Loading },
  props: {
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: false,
    },
    itemsBackendPath: {
      type: String,
      default: null,
    },
    disableItemSearch: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoadingBackendItems: false,
      itemsInternal: [],
      instanceId: "",
      itemFilter: "",
      selectedItems: [],
    };
  },
  methods: {
    init() {
      if (this.itemsBackendPath != null) {
        this.isLoadingBackendItems = true;
        this.$http.get(this.itemsBackendPath).then((result) => {
          this.itemsInternal = result.data;
          this.isLoadingBackendItems = false;
        });
      } else {
        this.itemsInternal = this.items;
      }
    },
  },
  computed: {
    filteredItems() {
      if (this.itemFilter == "") {
        return this.itemsInternal;
      } else {
        return this.itemsInternal.filter((item) => item.label.toLowerCase().includes(this.itemFilter.toLowerCase()));
      }
    },
  },
  watch: {
    //set v-model binding when on selectedItems change
    selectedItems() {
      this.$emit("input", this.selectedItems);
    },
    items() {
      this.init();
    },
  },
  beforeMount() {
    //set instanceId before HTML render
    this.instanceId = utilsHandler.generateUuidv4();
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped></style>

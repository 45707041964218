module.exports = [
  {
    type: "string",
    attribute: "vpp_assets_name",
    label: "Applikation",
  },
  {
    type: "string",
    attribute: "vpp_locations_location_name",
    label: "Konto",
  },
  { type: "string", attribute: "vpp_assets_product_type", label: "Plattform" },
  {
    type: "string",
    attribute: "vpp_assets_adam_id",
    label: "Itunes-id",
    visible: false,
  },
  {
    type: "string",
    attribute: "vpp_assets_license_count",
    label: "Licenser",
  },
  {
    type: "string",
    attribute: "vpp_assets_used_license_count",
    label: "Använda licenser",
    visible: false,
  },
  {
    type: "string",
    attribute: "hasBeenSetupDisplayName",
    label: "Konfigurerad",
  },
  {
    type: "string",
    attribute: "invalidInstances",
    label: "Felaktiga instanser",
  },
  {
    type: "string",
    attribute: "instances",
    label: "Felaktiga instanser längd",
  },
];

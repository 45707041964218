<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <table-paginated tableId="daisyAssetReportV1" backendPath="/api/ustart/daisy/report/assets" :columnConfig="daisyAssetReportColumnConfig" />
      </div>
    </div>
  </div>
</template>

<script>
import * as columnConfigHandler from "../../utils/columnConfig/columnConfigHandler.js";
import TablePaginated from "../../components/tables/tablePaginated/TablePaginated.vue";

export default {
  name: "DaisyAssetReport",
  components: { TablePaginated },
  data() {
    return {};
  },
  methods: {},
  computed: {
    daisyAssetReportColumnConfig() {
      return columnConfigHandler.getConfig("daisyAssetReport", []);
    },
  },
  mounted() {},
};
</script>

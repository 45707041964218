<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ header }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Stäng</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: { header: { type: String, default: "" }, modalId: { type: String, required: true } },
  methods: {},
  data() {
    return {};
  },
};
</script>

import AdminVppApplications from "../../views/admin/AdminVppApplications.vue";
import AdminUstartAdmins from "../../views/admin/AdminUstartAdmins.vue";

import AdminLogs from "../../views/admin/AdminLogs.vue";
import AdminVedaDepartments from "../../views/admin/AdminVedaDepartments.vue";
import AdminDevices from "../../views/admin/AdminDevices.vue";

export default [
  {
    path: "/AdminVppApplications",
    name: "AdminVppApplications",
    component: AdminVppApplications,
  },
  {
    path: "/AdminUstartAdmins",
    name: "AdminUstartAdmins",
    component: AdminUstartAdmins,
  },

  {
    path: "/AdminLogs",
    name: "AdminLogs",
    component: AdminLogs,
  },
  {
    path: "/AdminVedaDepartments",
    name: "AdminVedaDepartments",
    component: AdminVedaDepartments,
  },
  {
    path: "/AdminDevices",
    name: "AdminDevices",
    component: AdminDevices,
  },
];

import Error from "../../views/etc/Error.vue";
import Unauthorized from "../../views/etc/Unauthorized.vue";

export default [
  {
    path: "/Error",
    name: "Error",
    component: Error,
  },
  {
    path: "/Unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },
];

import Applications from "../../views/application/Applications.vue";
import ApplicationDetails from "../../views/application/ApplicationDetails.vue";

export default [
  {
    path: "/Applications",
    name: "Applications",
    component: Applications,
  },
  {
    path: "/ApplicationDetails",
    name: "ApplicationDetails",
    component: ApplicationDetails,
  },
];

module.exports = [
  {
    type: "string",
    attribute: "name",
    label: "Namn",
  },
  {
    type: "string",
    attribute: "platform",
    label: "Plattform",
  },
  {
    type: "list",
    attribute: "assignedApplications",
    label: "Tilldelade appar",
  },
  {
    type: "eventAndModal",
    eventName: "editGroup",
    linkLabel: "Redigera",
    attribute: "platformCombinedId",
    modal: "editGroupModal",
    label: "Redigera",
  },
  {
    type: "eventAndModal",
    eventName: "deleteGroup",
    linkLabel: "Ta bort",
    attribute: "platformCombinedId",
    modal: "deleteGroupModal",
    label: "Ta bort",
  },
];

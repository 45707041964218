<template>
  <div class="content">
    <div class="alert alert-danger" role="alert">
      <strong>Obehörig</strong> <br /><br />
      Fel-id: {{ errorId }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Unauthorized",
  computed: {
    errorId() {
      return this.$route.query.requestId;
    },
  },
};
</script>

<template>
  <div style="width:100%; height: 50px; background-color: #1d1d1d; color: #FFF; border-bottom: 1px solid #3a3f48; padding: 5px;">
    <center>
      <div class="input-group input-group-sm" style="width: 150px;">
        <span class="input-group-text" id="inputGroup-sizing-sm">Small</span>
        <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
      </div>
    </center>
  </div>
</template>

<script>
export default {
  name: "Topmenu",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

import Vue from "vue";
import Vuex from "vuex";
import * as jwt from "jsonwebtoken";
import * as dayjsHandler from "../utils/dayjs/dayjsHandler.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || "",
  },
  mutations: {
    setToken(state, payload) {
      localStorage.setItem("token", payload);
      state.token = payload;
    },
    removeToken(state) {
      localStorage.removeItem("token");
      state.token = "";
    },
    logout(state) {
      localStorage.removeItem("token");
      state.token = "";
      window.location.href = process.env.VUE_APP_IDPORTAL_SIGNOFF_URI;
    },
  },
  actions: {
    setToken(context, payload) {
      context.commit("setToken", payload);
    },
    removeToken(context) {
      context.commit("removeToken");
    },
    logout(context) {
      context.commit("logout");
    },
  },
  getters: {
    authenticated: (state) => {
      return state.token !== "";
    },
    // authorized: (state) => {
    //   return state.token !== "" && jwt.decode(state.token).roles.length > 0;
    // },
    token: (state) => {
      return state.token;
    },
    tokenDecoded: (state) => {
      return jwt.decode(state.token);
    },
    tokenDecodedSamAccountName: (state) => {
      return jwt.decode(state.token).samAccountName;
    },
    tokenDecodedUserPrincipalName: (state) => {
      return jwt.decode(state.token).userPrincipalName;
    },
    tokenDecodedVedaIds: (state) => {
      return jwt.decode(state.token).vedaIds;
    },
    tokenDecodedPrimaryVedaId: (state) => {
      return jwt.decode(state.token).primaryVedaId;
    },
    tokenDecodedRoles: (state) => {
      return jwt.decode(state.token).roles;
    },
    tokenDecodedIsUstartAdmin: (state) => {
      return jwt.decode(state.token).roles.some((item) => item == "Ustartadmin");
    },
    tokenDecodedIsInfoClassificationAdmin: (state) => {
      return jwt.decode(state.token).roles.some((item) => item == "InformationClassificationAdmin");
    },
    tokenDecodedIsGlobalAdmin: (state) => {
      return jwt.decode(state.token).roles.some((item) => item == "Globaladmin");
    },
    tokenDecodedIsDaisy: (state) => {
      return jwt.decode(state.token).roles.some((item) => item == "Daisy");
    },
    tokenDecodedActiveDepartmentName: (state) => {
      return jwt.decode(state.token).activeDepartment.name;
    },
    tokenDecodedActiveDepartmentVedaId: (state) => {
      return jwt.decode(state.token).activeDepartment.vedaId;
    },
    tokenDecodedExpDate: (state) => {
      return dayjsHandler.formatString(new Date(parseInt(jwt.decode(state.token).exp) * 1000).toISOString());
    },
    tokenDecodedJamfTeacherEnabled: (state) => {
      return jwt.decode(state.token).activeDepartment.jamfTeacherEnabled;
    },
    token: (state) => {
      return state.token;
    },
  },
});
